import type { DadataSuggestion } from "@Types/api";

import { Repository } from "@Utils/api/repository";

type DadataPayload = {
    query: string;
    locations: [{ country_iso_code: "RU"; fias_id?: string }];
    from_bound: {
        value: "city" | "street" | "house";
    };
    to_bound: {
        value: "settlement" | "house";
    };
};

type DadataResponse = {
    suggestions: DadataSuggestion[];
};

class DadataRepository extends Repository {
    getSuggestion = async (
        payload: DadataPayload
    ): Promise<DadataSuggestion[]> => {
        try {
            const result: DadataResponse = await this.client.request.post(
                "dadata/suggest/address",
                {
                    ...payload,
                }
            );
            console.log(result);

            return result.suggestions || [];
        } catch (err) {
            console.log("err", err);
            return [];
        }
    };

    getCity = async (query: string) => {
        return await this.getSuggestion({
            query,
            locations: [{ country_iso_code: "RU" }],
            from_bound: {
                value: "city",
            },
            to_bound: {
                value: "settlement",
            },
        });
    };

    getAddress = async (
        query: string,
        bound: "street" | "house",
        fias_id?: string
    ) => {
        return await this.getSuggestion({
            query,
            locations: [{ country_iso_code: "RU", fias_id }],
            from_bound: {
                value: bound,
            },
            to_bound: {
                value: "house",
            },
        });
    };
}

export default DadataRepository;
