import cns from "classnames";
import React from "react";

import SvgIcon, { IconsList } from "@Components/ui//SvgIcon";
import Link, { LinkProps } from "@Components/ui/Link";

import styles from "./index.module.scss";

export type ButtonIconProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
    LinkProps & { icon: IconsList };

const ButtonIcon = React.forwardRef<HTMLButtonElement, ButtonIconProps>(
    (
        {
            className,
            href,
            blank,
            simple,
            type = "button",
            icon,
            onClick,
            ...props
        },
        ref
    ) => {
        const classNames = cns(className, styles.component);
        const iconElement = <SvgIcon icon={icon} />;

        if (href) {
            return (
                <Link
                    ref={ref}
                    className={classNames}
                    href={href}
                    blank={blank}
                    simple={simple}
                >
                    {iconElement}
                </Link>
            );
        }

        return (
            <button
                ref={ref}
                className={classNames}
                type={type}
                onClick={onClick}
                {...props}
            >
                {iconElement}
            </button>
        );
    }
);

export default ButtonIcon;
