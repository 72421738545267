import { useEffect } from "react";

let dataScrollLocks: Set<string>;

const useScrollLock = (name: string) => {
    useEffect(() => {
        if (typeof dataScrollLocks === "undefined") {
            dataScrollLocks = new Set();
        }

        return () => {
            unlock();
        };
    }, []);

    const lock = () => {
        if (typeof name === "string") {
            dataScrollLocks.add(name);
        }

        document.documentElement.classList.add("isScrollLocked");
    };

    const unlock = () => {
        if (typeof name === "string") {
            dataScrollLocks.delete(name);
        }

        if (!dataScrollLocks.size) {
            document.documentElement.classList.remove("isScrollLocked");
        }
    };

    return {
        lock,
        unlock,
    };
};

export default useScrollLock;
