type IconProps = {
    className?: string;
};

const Icon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.47215 2C6.77306 1.99995 6.34723 1.99991 5.9799 2.05809C3.96113 2.37783 2.37784 3.96113 2.05809 5.9799C1.99991 6.34723 1.99995 6.77306 2 7.47215L2 12.5278C1.99995 13.2269 1.99991 13.6528 2.05809 14.0201C2.37783 16.0389 3.96113 17.6222 5.9799 17.9419C6.34723 18.0001 6.77307 18.0001 7.47217 18L8.35898 18C9.42038 18 10.4015 17.6488 11.1901 17.0564C11.4618 16.8523 11.5167 16.4666 11.3126 16.1948C11.1084 15.9231 10.7227 15.8682 10.4509 16.0724C9.86817 16.5101 9.14472 16.7692 8.35898 16.7692H7.53847C6.75269 16.7692 6.43145 16.7673 6.17244 16.7263C4.6803 16.49 3.51004 15.3197 3.27371 13.8276C3.23269 13.5686 3.23078 13.2473 3.23078 12.4615L3.23078 7.53846C3.23078 6.75269 3.23269 6.43145 3.27371 6.17244C3.51004 4.6803 4.6803 3.51004 6.17244 3.27371C6.43145 3.23268 6.75269 3.23078 7.53847 3.23078H8.35898C9.14472 3.23078 9.86817 3.48991 10.4509 3.92764C10.7227 4.13176 11.1084 4.07694 11.3126 3.80519C11.5167 3.53344 11.4619 3.14768 11.1901 2.94356C10.4015 2.35124 9.42038 2.00001 8.35898 2.00001L7.47215 2Z"
            fill="currentColor"
        />
        <path
            d="M14.4849 6.23575C14.2186 6.02459 13.8315 6.06931 13.6204 6.33562C13.4092 6.60193 13.4539 6.989 13.7202 7.20015L15.1668 8.3471C15.7444 8.80513 16.1399 9.11987 16.4086 9.38462L5.89744 9.38462C5.55757 9.38462 5.28206 9.66013 5.28206 10C5.28206 10.3399 5.55757 10.6154 5.89744 10.6154L16.4086 10.6154C16.1399 10.8801 15.7444 11.1949 15.1668 11.6529L13.7202 12.7998C13.4539 13.011 13.4092 13.3981 13.6204 13.6644C13.8315 13.9307 14.2186 13.9754 14.4849 13.7643L15.9608 12.594C16.5126 12.1565 16.9679 11.7955 17.2923 11.4724C17.6252 11.1408 17.9011 10.7728 17.976 10.3025C17.992 10.2023 18 10.1012 18 10C18 9.89876 17.992 9.79769 17.976 9.6975C17.9011 9.22721 17.6252 8.85918 17.2923 8.5276C16.9679 8.20449 16.5126 7.84349 15.9608 7.40601L14.4849 6.23575Z"
            fill="currentColor"
        />
    </svg>
);

export default Icon;
