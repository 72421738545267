import { useQuery } from "@tanstack/react-query";
import cns from "classnames";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import pages from "@Settings/pages";
import Popup from "@Components/Popup";
import Link from "@Components/ui/Link";
import TextIcon from "@Components/ui/TextIcon";
import { useDataContext } from "@Hooks/api/client";
import ProductProvider, { useProductContext } from "@Pages/product/context";
import { getExtra } from "@Redux/popup";
import { ProductFull } from "@Types/api";

import Content from "./Content";
import Gallery from "./Gallery";
import styles from "./index.module.scss";

export type PopupProductProps = Record<string, never>;

type extraProps = {
    slug: string;
    offerId?: string;
} | null;

type ProductProps = {
    product: ProductFull;
    slug?: string;
};

export const popupId = "product";

const Product: React.FC<ProductProps> = ({ product, slug }) => {
    const { offerId, colorId } = useProductContext();

    const [prevColorId, setPrevColorId] = useState<number | null>(colorId);
    const [linkOfferId, setLinkOfferId] = useState<number | null>(offerId);

    useEffect(() => {
        if (prevColorId === colorId) {
            return;
        }

        setPrevColorId(colorId);
        setLinkOfferId(offerId);
    }, [offerId]);

    if (!product) {
        return null;
    }

    return (
        <>
            <div className={styles.component__body}>
                <Gallery className={styles.component__gallery} />
                <Content className={styles.component__content} />
            </div>

            <Link
                className={styles.component__link}
                href={pages.product.link(slug ?? "", linkOfferId)}
            >
                <TextIcon
                    textClassName="link"
                    iconClassName={styles.component__linkIcon}
                    icon="angle-right-sm"
                    isRight
                >
                    Больше информации о товаре
                </TextIcon>
            </Link>
        </>
    );
};

const PopupProduct: React.FC<PopupProductProps> = () => {
    const extra: extraProps = useSelector(getExtra(popupId));
    const { client } = useDataContext();

    const { data: product, isLoading } = useQuery({
        queryKey: ["popupProduct", extra?.slug],
        queryFn: () =>
            client.catalog.getProduct(extra?.slug ?? "", {
                OFFER_ID: extra?.offerId,
            }),
        enabled: !!extra?.slug,
    });

    return (
        <Popup
            className={cns(styles.component, "forDesktop")}
            classNameContainer={cns(styles.component__container, { isLoading })}
            id={popupId}
        >
            {isLoading && (
                <div className={styles.component__loader}>
                    <div className="loader"></div>
                </div>
            )}

            {product && (
                <ProductProvider product={product}>
                    <Product product={product} slug={extra?.slug} />
                </ProductProvider>
            )}

            {!isLoading && !product && (
                <div
                    className={cns(
                        styles.component__error,
                        "text text_p1 text_medium text_center"
                    )}
                >
                    Товар не найден
                </div>
            )}
        </Popup>
    );
};

export default PopupProduct;
