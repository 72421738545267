import _ from "lodash";

import { BooleanSymbol } from "@Types/api";

export * from "./common";

export const delay = (ms: number) =>
    new Promise(resolve => setTimeout(resolve, ms));

export const toArray = <T>(value: T | T[]): T[] => {
    return _.isArray(value) ? value : [value];
};

export const isEven = (i: number) => i % 2 === 0;

export const toBooleanSymbol = (value: boolean): BooleanSymbol =>
    value ? "Y" : "N";
