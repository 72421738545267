import cns from "classnames";
import { useSelector } from "react-redux";

import Popup from "@Components/Popup";
import Image from "@Components/ui/Image";
import { close, getExtra, open } from "@Redux/popup";

import imageLogo from "./images/logo.jpg";
import styles from "./index.module.scss";

export type PopupFormResultProps = Record<string, never>;

const POPUP_ID = "formResult";

type ActionParams = {
    title: string;
    text: string;
    actions?: React.ReactNode;
};

export const openFormResultPopup = (extra: ActionParams) =>
    open({ id: POPUP_ID, extra });

export const closeFormResultPopup = () => close(POPUP_ID);

const PopupFormResult: React.FC<PopupFormResultProps> = ({}) => {
    const extra = useSelector(getExtra<ActionParams>(POPUP_ID));

    return (
        <Popup
            className={styles.component}
            classNameContainer={styles.component__container}
            classNameButtonClose={styles.component__buttonClose}
            id={POPUP_ID}
        >
            <div className={styles.component__image}>
                <Image
                    src={imageLogo}
                    alt=""
                    fill
                    size="30vw"
                    sizeTablet="96vw"
                />
            </div>

            <div className={cns(styles.component__content, "text text_center")}>
                {extra?.title && (
                    <h4
                        className={cns(
                            styles.component__title,
                            "text text_h4 text_uppercase"
                        )}
                    >
                        {extra.title}
                    </h4>
                )}

                {extra?.text && (
                    <p className={cns(styles.component__text, "text text_h5")}>
                        {extra.text}
                    </p>
                )}

                {extra?.actions && (
                    <div className={cns(styles.component__actions)}>
                        {extra.actions}
                    </div>
                )}
            </div>
        </Popup>
    );
};

export default PopupFormResult;
