import { ToastContainer } from "react-toastify";

import SvgIcon from "@Components/ui/SvgIcon";

import "react-toastify/dist/ReactToastify.css";

import cns from "classnames";

import iconWarning from "./images/icon-warning";
import styles from "./index.module.scss";

export type ToasitfyProps = {};

const CloseButton = ({ theme, closeToast }: any) => {
    return (
        <button
            className={cns(
                "Toastify__close-button",
                `Toastify__close-button--${theme}`
            )}
            type="button"
            onClick={closeToast}
        >
            <SvgIcon icon="close" />
        </button>
    );
};

const Icon = ({ type }: any) => {
    return (
        <div
            className={`Toastify__icon-type Toastify__icon-type--${type}`}
            dangerouslySetInnerHTML={{ __html: iconWarning() }}
        ></div>
    );
};

const Toasitfy: React.FC<ToasitfyProps> = () => {
    return (
        <ToastContainer
            className={styles.component}
            closeButton={CloseButton}
            icon={Icon}
        />
    );
};

export default Toasitfy;
