type IconProps = {
    className?: string;
};

const Icon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.83271 19.2751C4.1647 20.8791 6.64221 20.8791 11.5983 20.8791H12.3998C17.3559 20.8791 19.8345 20.8791 21.1665 19.2751M2.83271 19.2751C1.50071 17.6701 1.95803 15.2347 2.87156 10.3619C3.52091 6.89868 3.84503 5.16597 5.07823 4.14256M21.1665 19.2751C22.4985 17.6701 22.0412 15.2347 21.1277 10.3619C20.4783 6.89868 20.1531 5.16597 18.9199 4.14256M18.9199 4.14256C17.6878 3.11914 15.924 3.11914 12.3998 3.11914H11.5983C8.07411 3.11914 6.31144 3.11914 5.07823 4.14256"
            fill="transparent"
            stroke="currentColor"
            strokeWidth="1.5"
        />
        <path
            d="M8.85938 7.55859C9.0887 8.20853 9.51398 8.77134 10.0766 9.16943C10.6392 9.56753 11.3115 9.78131 12.0007 9.78131C12.6899 9.78131 13.3621 9.56753 13.9247 9.16943C14.4873 8.77134 14.9126 8.20853 15.142 7.55859"
            fill="transparent"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
        />
    </svg>
);

export default Icon;
