import cns from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { UserError } from "@Api/api-request";
import { apiClient } from "@Api/client";
import Popup from "@Components/Popup";
import { AsyncButton } from "@Components/ui/Button";
import Input from "@Components/ui/Input";
import SvgIcon from "@Components/ui/SvgIcon";
import { useAuth } from "@Hooks/user";
import { close, getExtra, open } from "@Redux/popup";
import { AppThunk } from "@Redux/store";

import styles from "./index.module.scss";

export type PopupAuthProps = Record<string, never>;

export type AuthProps = {
    phone: string;
    onSave: () => void;
};

type ActionParams = {
    onSave: () => void;
    phone: string;
};

// todo: combine with header auth?

const popupId = "auth";

const ERROR_MESSAGES = {
    required: "Обязательное поле",
    phone: "Некорректный формат телефона",
    code: "Неверный код, попробуйте еще раз",
    formSend: "Ошибка отправки формы",
};

const CODE_LENGTH = 4;
const CODE_PREFIX = "code-";

const TIMER_SEC = 60;

export const openAuthPopup =
    (extra: ActionParams): AppThunk =>
    async dispatch => {
        try {
            await apiClient.user.login({ phone: extra.phone });
            dispatch(open({ id: popupId, extra }));
        } catch (err) {
            if (err instanceof UserError && err.code === "send_sms_error") {
                void extra.onSave();
                return;
            }

            if (err instanceof Error || err instanceof UserError) {
                toast(err?.message || "Неверный формат телефона", {
                    position: "bottom-right",
                });
            }
        }
    };

export const closeAuthPopup = () => close(popupId);

const createCodeArray = () =>
    Array(CODE_LENGTH)
        .fill("")
        .map((_, index) => [`${CODE_PREFIX}${index}`, ""]);

const createCodeObject = () => Object.fromEntries(createCodeArray());

const Auth: React.FC<AuthProps> = ({ phone, onSave }) => {
    const dispatch = useDispatch();
    const { login, sendLoginConfirmation, isLoading } = useAuth();

    const [inputCodeValue, setInputCodeValue] = useState<{
        [key: string]: string;
    }>(createCodeObject());

    const [errorCode, setErrorCode] = useState<string | null>(null);

    const [code, setCode] = useState<string | null>(null);
    const [timer, setTimer] = useState<number>(0);
    const [isTooltip, setIsTooltip] = useState<boolean>(false);

    const refInputCode = useRef<{ [key: string]: HTMLInputElement | null }>(
        createCodeObject()
    );

    const codeArray = useMemo(createCodeArray, []);

    useEffect(() => {
        if (phone) {
            setTimer(TIMER_SEC);

            Object.values(refInputCode.current)[0]?.focus();
        }
    }, [phone]);

    useEffect(() => {
        setCode(Object.values(inputCodeValue).join(""));
    }, [inputCodeValue]);

    useEffect(() => {
        if (timer <= 0) {
            return;
        }

        const interval = setInterval(() => setTimer(prev => prev - 1), 1000);

        return () => clearInterval(interval);
    }, [timer]);

    const handleChangeInputCode = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        id?: string
    ) => {
        setInputCodeValue(prev => ({
            ...prev,
            [e.target.name]: e.target.value.replace(/[^0-9]/g, "").charAt(0),
        }));

        if (e.target.value && id && !refInputCode.current[id]?.value) {
            refInputCode.current[id]?.focus();
        }
    };

    const handleKeydownInputCode = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
        id?: string
    ) => {
        if (e.key === "Backspace" && !e.currentTarget.value && id) {
            refInputCode.current[id]?.focus();
        }
    };

    const handleClickButtonResend = async () => {
        if (!phone) {
            return;
        }

        setTimer(TIMER_SEC);

        // TODO: test auth flow
        try {
            await login(phone);
        } catch (err) {
            if (err instanceof Error) {
                // todo: handle server timer
                console.log(err.message);
            }
        }
    };

    const handlePasteInputCode = (
        e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const cbData = e.clipboardData.getData("text");

        if (!cbData || isNaN(+cbData)) {
            return;
        }

        const cbArray = cbData
            .replace(/[^0-9]/g, "")
            .split("")
            .splice(0, CODE_LENGTH);

        setInputCodeValue(prev =>
            Object.fromEntries(
                Object.keys(prev).map((key, index) => [
                    key,
                    cbArray[index] ?? "",
                ])
            )
        );

        refInputCode.current[`${CODE_PREFIX}${cbArray.length - 1}`]?.focus();
    };

    const handleClickButtonNot = () => setIsTooltip(prev => !prev);
    const handleClickTooltipButtonClose = () => setIsTooltip(false);

    const handleSubmitLogIn = async () => {
        if (!code?.length) {
            setErrorCode(ERROR_MESSAGES.required);

            return;
        }

        if (code?.length !== CODE_LENGTH) {
            setErrorCode(ERROR_MESSAGES.code);

            return;
        }

        // TODO: test auth flow
        try {
            setErrorCode(null);
            await sendLoginConfirmation(code, phone);
            dispatch(closeAuthPopup());
            setTimeout(() => {
                onSave();
            }, 500);
        } catch (err) {
            if (err instanceof Error) {
                setErrorCode(err.message);
                setInputCodeValue(createCodeObject());
                Object.values(refInputCode.current)[0]?.focus();
            }
        }
    };

    useEffect(() => {
        if (code?.length === CODE_LENGTH && !isLoading) {
            void handleSubmitLogIn();
        }
    }, [code]);

    return (
        <div>
            {phone && (
                <div className={styles.component__step}>
                    <h3
                        className={cns(
                            styles.component__title,
                            "text text_h5 text_uppercase"
                        )}
                    >
                        Введите код из смс
                    </h3>

                    <p className={cns(styles.component__text, "text text_p4")}>
                        Код отправлен в СМС на номер: <br />
                        {phone}
                    </p>

                    <div
                        className={cns(
                            styles.component__caption,
                            "text text_uppercase"
                        )}
                    >
                        Код из смс
                    </div>

                    <div className={styles.component__inputCode}>
                        {codeArray.map(([codeId], index) => (
                            <Input
                                key={codeId}
                                ref={el => (refInputCode.current[codeId] = el)}
                                className={styles.component__input}
                                name={codeId}
                                maxLength={1}
                                autoComplete="off"
                                value={inputCodeValue[codeId]}
                                onChange={e =>
                                    handleChangeInputCode(
                                        e,
                                        codeArray[index + 1]
                                            ? codeArray[index + 1][0]
                                            : undefined
                                    )
                                }
                                onKeyDown={e =>
                                    handleKeydownInputCode(
                                        e,
                                        codeArray[index - 1]
                                            ? codeArray[index - 1][0]
                                            : undefined
                                    )
                                }
                                onPaste={handlePasteInputCode}
                                disabled={isLoading}
                            />
                        ))}

                        {errorCode && (
                            <div className={styles.component__inputCodeError}>
                                {errorCode}
                            </div>
                        )}
                    </div>

                    <AsyncButton
                        className={styles.component__button}
                        size="md"
                        onClick={handleSubmitLogIn}
                        disabled={isLoading}
                    >
                        Оплатить
                    </AsyncButton>

                    {timer > 0 ? (
                        <p
                            className={cns(
                                styles.component__text,
                                styles.component__text_resend,
                                "text text_p4 text_center"
                            )}
                        >
                            Отправим код повторно через {timer} секунд
                        </p>
                    ) : (
                        <button
                            className={cns(
                                styles.component__buttonResend,
                                "text text_center link"
                            )}
                            type="button"
                            onClick={handleClickButtonResend}
                        >
                            Отправить код повторно
                        </button>
                    )}

                    <div className={styles.component__not}>
                        <button
                            className={cns(styles.component__buttonNot, "link")}
                            type="button"
                            onClick={handleClickButtonNot}
                        >
                            Не приходит СМС
                        </button>

                        {isTooltip && (
                            <div
                                className={cns(
                                    styles.component__tooltip,
                                    "text text_p4"
                                )}
                            >
                                <button
                                    className={
                                        styles.component__tooltipButtonClose
                                    }
                                    type="button"
                                    aria-label="Закрыть подсказку"
                                    onClick={handleClickTooltipButtonClose}
                                >
                                    <SvgIcon icon="close-thin" />
                                </button>
                                Возможные причины: <br />
                                <ol>
                                    <li>
                                        Вы ошиблись с номером, пожалуйста,
                                        перепроверьте его;
                                    </li>
                                    <li>
                                        Проблемы у сотового оператора.
                                        Обратитесь в службу поддержки.
                                    </li>
                                </ol>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

const PopupAuth: React.FC<PopupAuthProps> = () => {
    const extra = useSelector(getExtra<ActionParams>(popupId));

    return (
        <Popup
            className={cns(styles.component)}
            classNameContainer={styles.component__container}
            id={popupId}
        >
            {!!extra && <Auth phone={extra.phone} onSave={extra.onSave} />}
        </Popup>
    );
};

export default PopupAuth;
