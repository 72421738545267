import Image, { ImageProps } from "@Components/ui/Image";
import Link, { LinkProps } from "@Components/ui/Link";
import { ContentSocialsItem } from "@Types/api";
import { normalizePhone } from "@Utils";

export type LinkContactsProps = LinkProps & {
    contact: ContentSocialsItem;
    withIcon?: boolean;
    iconProps?: ImageProps;
};

const LinkContacts: React.FC<LinkContactsProps> = ({
    contact,
    withIcon,
    iconProps,
    ...props
}) => {
    const image = (
        <Image
            src={contact.icon}
            alt={contact.name}
            width={31}
            height={31}
            {...iconProps}
        />
    );

    if (withIcon && contact.type === "phone") {
        return (
            <a href={`tel:${normalizePhone(contact.value)}`} {...props}>
                {image}
            </a>
        );
    }

    if (withIcon && contact.type === "email") {
        return (
            <a href={`mailto:${contact.value}`} {...props}>
                {image}
            </a>
        );
    }

    return (
        <Link
            href={contact.value}
            phone={contact.type === "phone" ? contact.value : undefined}
            email={contact.type === "email" ? contact.value : undefined}
            blank={contact.type === "link"}
            simple
            {...props}
        >
            {withIcon ? image : contact.name}
        </Link>
    );
};

export default LinkContacts;
