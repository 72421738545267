import cns from "classnames";
import { useDispatch } from "react-redux";

import { popupId } from "@Components/PopupSplit";
import Link from "@Components/ui/Link";
import TextIcon from "@Components/ui/TextIcon";
import { useUser } from "@Hooks/user";
import { useProductContext } from "@Pages/product/context";
import { open } from "@Redux/popup";
import { formatCurrency } from "@Utils/helpers";

import styles from "./index.module.scss";

export type InfoProps = {
    className?: string;
    forCard?: boolean;
};

const Info: React.FC<InfoProps> = ({ className, forCard }) => {
    const { product, labels, price } = useProductContext();
    const dispatch = useDispatch();
    const { user } = useUser();

    const handleSplitClick = (price: number) =>
        dispatch(open({ id: popupId, extra: { price } }));

    return (
        <div
            className={cns(className, styles.component, {
                [styles.component_card]: forCard,
            })}
        >
            {user?.data?.isAdmin && product.editLink && (
                <Link
                    className={cns(styles.component__linkEdit, "text text_p4")}
                    href={product.editLink}
                    simple
                    blank
                >
                    <TextIcon icon="pencil" isRight>
                        Редактировать товар
                    </TextIcon>
                </Link>
            )}

            {(labels?.length || product.article) && (
                <div className={styles.component__top}>
                    {!!labels?.length && (
                        <div
                            className={cns(
                                styles.component__labels,
                                "text text_uppercase",
                                {
                                    text_p3: !forCard,
                                    text_p4: forCard,
                                }
                            )}
                        >
                            {labels.map((label, labelIndex) => (
                                <div
                                    key={labelIndex}
                                    className={styles.component__label}
                                >
                                    {label}
                                </div>
                            ))}
                        </div>
                    )}

                    {product.article && (
                        <div
                            className={cns(
                                styles.component__code,
                                "text text_p5"
                            )}
                        >
                            Артикул {product.article}
                        </div>
                    )}
                </div>
            )}
            <div
                className={cns(
                    styles.component__name,
                    "text text_h4 text_uppercase"
                )}
            >
                {product.name}
            </div>
            <div
                className={cns(styles.component__price, "text text_uppercase", {
                    text_h3: !forCard,
                    text_h4: forCard,
                })}
            >
                {formatCurrency(price?.current as number)}

                {price?.old && (
                    <div className={styles.component__priceOld}>
                        {formatCurrency(price.old)}
                    </div>
                )}
            </div>
            {/* {product.split && (
                <button
                    className={styles.component__split}
                    type="button"
                    onClick={() => handleSplitClick(price?.current ?? 0)}
                >
                    <TextIcon icon="angle-right-sm" isRight>
                        {formatCurrency((price?.current ?? 0) / 4)} x 4 платежа
                    </TextIcon>
                </button>
            )} */}
        </div>
    );
};

export default Info;
