import type { BodyForCreateOrder, CreatedApiOrder, Result } from "@Types/api";

import * as mappers from "@Api/mappers";
import { Repository } from "@Utils/api/repository";

class CheckoutRepository extends Repository {
    createOrder = async (body: BodyForCreateOrder) => {
        const result: Result<CreatedApiOrder> = await this.client.request.post(
            "/order",
            body
        );

        return mappers.mapCreatedOrder(result.data);
    };
}

export default CheckoutRepository;
