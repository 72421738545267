type IconProps = {
    className?: string;
};

const Icon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.1252 3.3335C11.1252 2.98832 10.8453 2.7085 10.5002 2.7085C10.155 2.7085 9.87516 2.98832 9.87516 3.3335L9.87516 9.37516H3.8335C3.48832 9.37516 3.2085 9.65498 3.2085 10.0002C3.2085 10.3453 3.48832 10.6252 3.8335 10.6252H9.87516V16.6668C9.87516 17.012 10.155 17.2918 10.5002 17.2918C10.8453 17.2918 11.1252 17.012 11.1252 16.6668V10.6252H17.1668C17.512 10.6252 17.7918 10.3453 17.7918 10.0002C17.7918 9.65498 17.512 9.37516 17.1668 9.37516H11.1252L11.1252 3.3335Z"
            fill="currentColor"
        />
    </svg>
);

export default Icon;
