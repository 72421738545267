import type { DefaultRootState } from "react-redux";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

type PayloadCreate = string;

type PayloadOpen = {
    id: string;
    extra?: object;
};

type PayloadClose = string;

const initialState: { [key: string]: true | object | null } = {};

const slice = createSlice({
    name: "popup",
    initialState,
    reducers: {
        create(state, action: PayloadAction<PayloadCreate>) {
            if (typeof state[action?.payload] === "undefined") {
                state[action.payload] = null;
            }
        },
        open(state, action: PayloadAction<PayloadOpen>) {
            if (typeof state[action?.payload?.id] !== "undefined") {
                state[action.payload.id] = action.payload.extra ?? true;
            }
        },
        close(state, action: PayloadAction<PayloadClose>) {
            if (typeof state[action?.payload] !== "undefined") {
                state[action.payload] = null;
            }
        },
    },
});

export const getIsOpen = (id: string) => (state: DefaultRootState) =>
    !!state.popup[id];

export const getExtra =
    <T = unknown>(id: string) =>
    (state: DefaultRootState) =>
        _.isObject(state.popup[id]) ? (state.popup[id] as T) : null;

const { actions, reducer } = slice;

export const { create, open, close } = actions;

export default reducer;
