import { useRouter } from "next/router";
import { useEffect } from "react";
import { isIOS } from "react-device-detect";

const useRouterPopstateTransition = () => {
    const router = useRouter();

    useEffect(() => {
        if (!isIOS) {
            return;
        }

        const $elementToHide = document.getElementById("__next");

        const hide = () => {
            if ($elementToHide) {
                $elementToHide.style.opacity = "0";
                $elementToHide.style.pointerEvents = "none";
            }
        };

        const show = () => {
            if ($elementToHide) {
                $elementToHide.style.opacity = "";
                $elementToHide.style.pointerEvents = "";
            }
        };

        let toSafe: ReturnType<typeof setTimeout> | undefined;

        const handlePopState = () => {
            clearTimeout(toSafe);

            hide();

            toSafe = setTimeout(show, 5000);
        };

        const handleRouteChangeComplete = () => {
            clearTimeout(toSafe);

            show();
        };

        window.addEventListener("popstate", handlePopState);

        router.events.on("routeChangeComplete", handleRouteChangeComplete);
        router.events.on("routeChangeError", handleRouteChangeComplete);

        return () => {
            clearTimeout(toSafe);

            window.removeEventListener("popstate", handlePopState);

            router.events.off("routeChangeComplete", handleRouteChangeComplete);
            router.events.off("routeChangeError", handleRouteChangeComplete);
        };
    }, []);
};

export default useRouterPopstateTransition;
