import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDebounce } from "react-use";

import Popup from "@Components/Popup";
import { useDataContext } from "@Hooks/api/client";
import { close, getIsOpen, open } from "@Redux/popup";
import { SearchItem } from "@Types/api";

import Field from "./Field";
import Result from "./Result";
import styles from "./index.module.scss";

export type SearchProps = {};

export const popupId = "search";

export const isSearchOpen = () => useSelector(getIsOpen(popupId));
export const openSearch = () => open({ id: popupId });
export const closeSearch = () => close(popupId);

const QUERY_MIN_LENGTH = 3;
const QUERY_DELAY = 300;

const Content: React.FC = () => {
    const [query, setQuery] = useState<string>("");
    const [queryMessage, setQueryMessage] = useState<string | null>(null);
    const [items, setItems] = useState<SearchItem[] | null>(null);
    const [count, setCount] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { client } = useDataContext();
    const queryClient = useQueryClient();
    const {
        data: queryData,
        refetch: queryRefetch,
        fetchStatus: queryFS,
    } = useQuery({
        queryKey: ["search", query],
        queryFn: () => client.catalog.search({ s: query }),
        enabled: false,
    });

    useDebounce(
        () => {
            if (query.length < QUERY_MIN_LENGTH) {
                queryClient.cancelQueries({ queryKey: ["search", query] });

                setQueryMessage(null);
                setItems(null);
                setCount(null);

                return;
            }

            queryRefetch();
        },
        QUERY_DELAY,
        [query]
    );

    useEffect(() => {
        if (queryData) {
            setQueryMessage(query);
            setItems(queryData.items);
            setCount(queryData.count);
        }
    }, [queryData]);

    useEffect(() => {
        if (queryFS === "fetching") {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [queryFS]);

    return (
        <>
            <Field
                className={styles.component__field}
                value={query}
                setValue={setQuery}
            />

            {items !== null && count !== null && (
                <Result
                    className={styles.component__result}
                    query={queryMessage}
                    items={items}
                    count={count}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};

const Search: React.FC<SearchProps> = () => {
    return (
        <Popup
            className={styles.component}
            classNameContainer={styles.component__container}
            classNameButtonClose="isHidden"
            id={popupId}
        >
            <Content />
        </Popup>
    );
};

export default Search;
