import cns from "classnames";
import _ from "lodash";
import { NextRouter, useRouter } from "next/router";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useDispatch } from "react-redux";
import { useKeyPressEvent } from "react-use";

import pages from "@Settings/pages";
import Container from "@Components/Container";
import { closeSearch, isSearchOpen, openSearch } from "@Components/Search";
import Link from "@Components/ui/Link";
import SvgIcon from "@Components/ui/SvgIcon";
import { useEvents } from "@Events";
import {
    useCommonDataSettings,
    useGetCommonDataMenuTop,
} from "@Hooks/api/common";
import { useGetStoreCategories } from "@Hooks/api/store";
import { useCart } from "@Hooks/cart";
import useScrollLock from "@Hooks/scroll-lock";
import useScrollPosition from "@Hooks/scroll-position";
import { useFavouriteProducts, useUser } from "@Hooks/user";
import useWindowSize from "@Hooks/window-size";

import Auth from "./Auth";
import BurgerMenu from "./BurgerMenu";
import BurgerMenuMobile from "./BurgerMenuMobile";
import Cart from "./Cart";
import Dropdown from "./Dropdown";
import styles from "./index.module.scss";

const getDropdownId = (id: string | number) => `dropdown-${id}`;

const isHome = (router: NextRouter) => router.route === pages.main.link;

type CounterProps = {
    value: React.ReactNode;
};

let navLinkTimeout: ReturnType<typeof setTimeout> | undefined;
let isScrollEventsEnabled = true;
let canBeLight: boolean = false;

const Counter: React.FC<CounterProps> = ({ value }) => (
    <span
        className={cns(
            styles.component__controlsCounter,
            "text text_medium text_center"
        )}
    >
        {value}
    </span>
);

const FavouritesLink: React.FC<{ className?: string }> = ({ className }) => {
    const { productsQuantity } = useFavouriteProducts();

    return (
        <Link
            className={className}
            href={pages.favorites.link}
            aria-label={pages.favorites.name}
        >
            <SvgIcon icon="heart" />
            {productsQuantity > 0 && <Counter value={productsQuantity} />}
        </Link>
    );
};

const CartLink = React.forwardRef<HTMLLinkElement, { className?: string }>(
    ({ className }, ref) => {
        const { productsQuantity } = useCart();

        return (
            <Link
                ref={ref}
                className={className}
                href={pages.cart.link}
                aria-label={pages.cart.name}
            >
                <SvgIcon icon="cart" />
                {productsQuantity > 0 && <Counter value={productsQuantity} />}
            </Link>
        );
    }
);

const ProfileLink: React.FC<{
    isActive: boolean;
    onAuthClick: () => void;
    className?: string;
}> = ({ isActive, onAuthClick, className }) => {
    const router = useRouter();
    const { isLoggedIn } = useUser();

    const handleClick = () => {
        if (!isLoggedIn) {
            onAuthClick();
            return;
        }

        void router.push(pages.profile.link);
    };

    return (
        <button
            className={cns(className, {
                isActive,
            })}
            type="button"
            aria-label="Личный кабинет"
            onClick={handleClick}
        >
            <SvgIcon icon="user" />
        </button>
    );
};

const Header: React.FC = () => {
    const dataSettings = useCommonDataSettings();
    const dataMenuTop = useGetCommonDataMenuTop();
    const storeCategories = useGetStoreCategories();

    const router = useRouter();

    const [isHidden, setIsHidden] = useState(false);
    const [isLight, setIsLight] = useState(isHome(router));
    const [isDropdown, setIsDropdown] = useState(false);
    const [dropdowns, setDropdowns] = useState<any>({});
    const [isBurger, setIsBurger] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isCart, setIsCart] = useState(false);

    const linksWithDropdown = useMemo(
        () =>
            [
                ...dataMenuTop.filter(
                    item => item.sublinks || item.categorySlug
                ),
            ].map(item => ({
                ...item,
                sublinks: item.categorySlug
                    ? storeCategories.getMenuItems(
                          item.categorySlug,
                          false,
                          true,
                          1,
                          0
                      )
                    : item.sublinks,
            })),
        [dataMenuTop]
    );

    const componentRef = useRef<HTMLElement>(null);

    const lockScroll = useScrollLock("header");
    const windowSize = useWindowSize();
    const dispatch = useDispatch();
    const isSearchOpenState = isSearchOpen();

    const setDropdownsState = useCallback(
        (id?: string | number) => {
            const dropdownsObject: any = {};

            linksWithDropdown.forEach(link => {
                if (link.sublinks?.length || link.categorySlug) {
                    dropdownsObject[getDropdownId(link.id)] = id === link.id;
                }
            });

            setDropdowns(dropdownsObject);
        },
        [dataMenuTop]
    );

    const resetAll = () => {
        setIsDropdown(false);
        setIsBurger(false);
        setIsSearch(false);
        setIsAuth(false);
        setIsCart(false);
    };

    useEvents(
        events => {
            events.on("cart.item.added", ctx => {
                const isOnCartPage = router.pathname === pages.cart.link;
                const isFromOneClick = ctx?.from === "one-click";

                if (isFromOneClick || isOnCartPage) {
                    return;
                }

                setIsCart(true);
            });
        },
        [router]
    );

    useScrollPosition(
        ({ currPos, directionY }) => {
            if (!isScrollEventsEnabled) {
                return;
            }

            if (isHidden && directionY > 0) {
                setIsHidden(false);
            } else if (!isHidden && directionY < 0) {
                if (
                    !isDropdown &&
                    !isBurger &&
                    !isSearch &&
                    !isAuth &&
                    !isCart
                ) {
                    setIsHidden(true);
                }
            }

            if (!canBeLight) {
                return;
            }

            if (isLight && currPos.y !== 0) {
                setIsLight(false);
            } else if (!isLight && currPos.y === 0) {
                setIsLight(true);
            }
        },
        [isHidden, isLight, isDropdown, isBurger, isSearch, isAuth, isCart]
    );

    useEffect(() => {
        if (componentRef.current) {
            document.documentElement.style.setProperty(
                "--header-height",
                `${componentRef.current.clientHeight}px`
            );
        }
    }, [windowSize]);

    useEffect(() => {
        isScrollEventsEnabled = false;
        canBeLight = isHome(router);

        setIsHidden(false);
        setIsLight(canBeLight && pageYOffset <= 0);

        const to = setTimeout(() => {
            isScrollEventsEnabled = true;
        }, 500);

        return () => {
            clearTimeout(to);
        };
    }, [router.pathname]);

    useEffect(() => {
        resetAll();
    }, [router]);

    useEffect(() => {
        if (!windowSize.isDesktop) {
            return;
        }

        setDropdownsState();
    }, [windowSize.isDesktop, dataMenuTop]);

    useEffect(() => {
        if (isDropdown || isBurger || isSearch || isAuth || isCart) {
            lockScroll.lock();
        } else {
            lockScroll.unlock();
        }
    }, [isDropdown, isBurger, isSearch, isAuth, isCart]);

    useEffect(() => {
        if (isSearch || isAuth || isCart) {
            setIsLight(false);
            setIsDropdown(false);
            setIsBurger(false);
        } else {
            setIsLight(isHome(router) && pageYOffset <= 0);
        }
    }, [isSearch, isAuth, isCart]);

    useEffect(() => {
        document.documentElement.classList.toggle("headerIsHidden", isHidden);
    }, [isHidden]);

    useEffect(() => {
        if (isSearch) {
            dispatch(openSearch());
        } else {
            dispatch(closeSearch());
        }
    }, [isSearch]);

    useEffect(() => {
        setIsSearch(isSearchOpenState);
    }, [isSearchOpenState]);

    useKeyPressEvent("Escape", resetAll);

    const handleNavLinkMouseEnter = (id: string | number) => {
        clearTimeout(navLinkTimeout);

        navLinkTimeout = setTimeout(() => {
            setDropdownsState(id);
            setIsDropdown(true);
        }, 300);
    };

    const handleNavLinkMouseLeave = () => {
        clearTimeout(navLinkTimeout);

        setIsDropdown(false);
    };

    const handleClickBurger = () => setIsBurger(!isBurger);
    const handleClickSearch = () => setIsSearch(!isSearch);
    const handleClickAuth = () => setIsAuth(true);

    return (
        <>
            <header
                ref={componentRef}
                className={cns(styles.component, {
                    [styles.component_hidden]: isHidden,
                    [styles.component_light]: isLight,
                    [styles.component_noClick]: isSearch,
                })}
            >
                {!_.isEmpty(dataSettings.HEADER_TEXT) && (
                    <div
                        className={cns(
                            styles.component__top,
                            "text text_p5 text_center"
                        )}
                    >
                        <Container>{dataSettings.HEADER_TEXT}</Container>
                    </div>
                )}

                <div className={styles.component__main}>
                    <Container className={styles.component__mainContainer}>
                        <button
                            className={cns(
                                styles.component__burger,
                                "notForDesktop"
                            )}
                            type="button"
                            aria-label="Открыть меню"
                            onClick={handleClickBurger}
                        >
                            <SvgIcon icon="burger" />
                        </button>

                        <Link
                            className={styles.component__logo}
                            href={pages.main.link}
                            aria-label="Логотип Stylish"
                        ></Link>

                        {!!dataMenuTop.length && (
                            <nav
                                className={cns(
                                    styles.component__nav,
                                    "text text_h6 text_uppercase forDesktop"
                                )}
                            >
                                {dataMenuTop.map(item => {
                                    const itemCategory = useMemo(
                                        () =>
                                            item.categorySlug &&
                                            storeCategories.getBySlug(
                                                item.categorySlug
                                            ),
                                        [item.categorySlug]
                                    );

                                    const linkProps = {
                                        key: item.code,
                                        className: cns(
                                            styles.component__navLink,
                                            {
                                                isActive:
                                                    isDropdown &&
                                                    dropdowns[
                                                        getDropdownId(item.id)
                                                    ],
                                            }
                                        ),
                                        onMouseEnter:
                                            windowSize.isDesktop &&
                                            (item.sublinks?.length ||
                                                item.categorySlug)
                                                ? () =>
                                                      handleNavLinkMouseEnter(
                                                          item.id
                                                      )
                                                : undefined,
                                        onMouseLeave:
                                            windowSize.isDesktop &&
                                            (item.sublinks?.length ||
                                                item.categorySlug)
                                                ? handleNavLinkMouseLeave
                                                : undefined,
                                    };

                                    const link = useMemo(
                                        () =>
                                            itemCategory
                                                ? pages.category.link(
                                                      itemCategory.PATH
                                                  )
                                                : item.link,
                                        [itemCategory, item.link]
                                    );

                                    return link ? (
                                        <Link href={link} {...linkProps}>
                                            {item.text}
                                        </Link>
                                    ) : (
                                        <div {...linkProps}>{item.text}</div>
                                    );
                                })}
                            </nav>
                        )}

                        <div className={styles.component__controls}>
                            <button
                                className={cns(
                                    styles.component__controlsButton,
                                    {
                                        isActive: isSearch,
                                    }
                                )}
                                type="button"
                                aria-label="Поиск"
                                onClick={handleClickSearch}
                            >
                                <SvgIcon icon="search" />
                            </button>

                            <ProfileLink
                                className={styles.component__controlsButton}
                                isActive={isAuth}
                                onAuthClick={handleClickAuth}
                            />

                            <FavouritesLink
                                className={styles.component__controlsButton}
                            />

                            <CartLink
                                className={cns(
                                    styles.component__controlsButton,
                                    {
                                        isActive: isCart,
                                    }
                                )}
                            />

                            <button
                                className={cns(
                                    styles.component__controlsButton,
                                    "forDesktop"
                                )}
                                type="button"
                                aria-label="Открыть меню"
                                onClick={handleClickBurger}
                            >
                                <SvgIcon icon="burger" />
                            </button>
                        </div>
                    </Container>
                </div>

                {isDropdown && (
                    <div
                        className={cns(
                            styles.component__dropdowns,
                            "forDesktop"
                        )}
                        onMouseEnter={() => setIsDropdown(true)}
                        onMouseLeave={() => setIsDropdown(false)}
                    >
                        <Container>
                            {linksWithDropdown.map(item => {
                                return item.sublinks?.length &&
                                    dropdowns[getDropdownId(item.id)] ? (
                                    <Dropdown
                                        key={item.code}
                                        title={item.text}
                                        links={item.sublinks}
                                        colsCount={item.categorySlug ? 2 : 3}
                                        category={
                                            item.categorySlug
                                                ? storeCategories.getBySlug(
                                                      item.categorySlug
                                                  )
                                                : undefined
                                        }
                                    />
                                ) : null;
                            })}
                        </Container>
                    </div>
                )}

                {isBurger &&
                    (windowSize.isDesktop ? (
                        <BurgerMenu onClose={() => setIsBurger(false)} />
                    ) : (
                        <BurgerMenuMobile
                            onClose={() => setIsBurger(false)}
                            onAuthClick={handleClickAuth}
                        />
                    ))}

                <Auth
                    className={cns({ isHidden: !isAuth })}
                    onClose={() => setIsAuth(false)}
                />
            </header>

            {isCart && (
                <div
                    className={cns(styles.component__cartContainer, {
                        [styles._header_hidden]: isHidden,
                    })}
                >
                    <Cart isOpen={isCart} onClose={() => setIsCart(false)} />
                </div>
            )}
        </>
    );
};

export default Header;
