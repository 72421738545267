type IconProps = {
    className?: string;
};

const Icon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.4205 4.50392C12.9383 4.45717 12.6 4.83613 12.6 5.22002V15.9264C12.6 16.2432 12.8686 16.5 13.2 16.5C13.5314 16.5 13.8 16.2432 13.8 15.9264L13.8 5.88034C13.8114 5.88882 13.823 5.8976 13.8348 5.90668C14.0929 6.10483 14.3997 6.39953 14.8556 6.83918L15.9738 7.91775C16.2071 8.14274 16.587 8.14436 16.8224 7.92137C17.0577 7.69839 17.0594 7.33523 16.8262 7.11025L15.6835 6.00815C15.2584 5.59811 14.9038 5.25612 14.5862 5.01229C14.2531 4.75654 13.903 4.56201 13.4764 4.51003C13.4578 4.50777 13.4392 4.50573 13.4205 4.50392Z"
            fill="currentColor"
        />
        <path
            d="M7.4 5.22003C7.4 4.90326 7.13137 4.64647 6.8 4.64647C6.46863 4.64647 6.2 4.90326 6.2 5.22003L6.2 15.113C6.18863 15.1045 6.17703 15.0957 6.16519 15.0867C5.90709 14.8885 5.60026 14.5938 5.14443 14.1542L4.02615 13.0756C3.79289 12.8506 3.41299 12.849 3.17763 13.072C2.94228 13.295 2.94058 13.6581 3.17385 13.8831L4.31651 14.9852C4.74161 15.3952 5.09618 15.7372 5.41379 15.9811C5.74693 16.2368 6.09705 16.4313 6.5236 16.4833C7.04409 16.5467 7.4 16.1341 7.4 15.7342L7.4 5.22003Z"
            fill="currentColor"
        />
    </svg>
);

export default Icon;
