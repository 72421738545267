import cns from "classnames";

import Scrollbar from "@Components/Scrollbar";
import Buttons from "@Pages/product/SectionBody/Content/Buttons";
import Colors from "@Pages/product/SectionBody/Content/Colors";
import Info from "@Pages/product/SectionBody/Content/Info";
import Size from "@Pages/product/SectionBody/Content/Size";

import styles from "./index.module.scss";

export type ContentProps = {
    className?: string;
};

const Content: React.FC<ContentProps> = ({ className }) => {
    return (
        <Scrollbar className={cns(className, styles.component)}>
            <Info forCard />
            <Colors />
            <Size />
            <Buttons className={styles.component__buttons} />
        </Scrollbar>
    );
};

export default Content;
