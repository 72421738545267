import cns from "classnames";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

import VideoEmbedded from "@Components/VideoEmbedded";

import SvgIcon from "../SvgIcon";
import styles from "./index.module.scss";

export type VideoProps = React.VideoHTMLAttributes<HTMLVideoElement> & {
    className?: string;
    classNameWrapper?: string;
    classNameIconPlay?: string;
    src: string;
    embedded?: boolean;
    autoPlayInView?: boolean;
    autoPlayOnMount?: boolean;
    withPlayButton?: boolean;
};

const Video: React.FC<VideoProps> = ({
    className,
    classNameWrapper,
    classNameIconPlay,
    src,
    embedded,
    autoPlayInView,
    autoPlayOnMount,
    withPlayButton,
    ...props
}) => {
    const { ref, inView, entry } = useInView();

    const refWrapper = useRef<HTMLDivElement>(null);
    const refVideo = useRef<HTMLVideoElement>(null);

    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const handleWrapperClick = () => {
        const $video: HTMLVideoElement | null | undefined =
            refWrapper.current?.querySelector("video");

        $video?.[isPlaying ? "pause" : "play"]();
    };

    useEffect(() => {
        if (!autoPlayInView || !entry) {
            return;
        }

        const $video: HTMLVideoElement = entry?.target as HTMLVideoElement;

        $video?.[inView ? "play" : "pause"]();
    }, [inView]);

    useEffect(() => {
        if (!refWrapper.current) {
            return;
        }

        const $video: HTMLVideoElement | null =
            refWrapper.current.querySelector("video");

        const handleVideoPlay = () => setIsPlaying(true);
        const handleVideoPause = () => setIsPlaying(false);

        $video?.addEventListener("play", handleVideoPlay);
        $video?.addEventListener("pause", handleVideoPause);

        return () => {
            $video?.removeEventListener("play", handleVideoPlay);
            $video?.removeEventListener("pause", handleVideoPause);
        };
    }, []);

    useEffect(() => {
        if (autoPlayOnMount && refVideo.current) {
            refVideo.current.play();
        }
    }, []);

    if (embedded) {
        return <VideoEmbedded className={className} link={src} />;
    }

    let refValue = undefined;

    if (autoPlayInView) {
        refValue = ref;
    } else if (autoPlayOnMount) {
        refValue = refVideo;
    }

    const videoElement = (
        <video ref={refValue} className={className} {...props}>
            <source src={src} />
        </video>
    );

    if (withPlayButton) {
        return (
            <div
                ref={refWrapper}
                className={cns(classNameWrapper, styles.component, {
                    isPlaying,
                })}
                onClick={props.controls ? undefined : handleWrapperClick}
            >
                {videoElement}

                <SvgIcon
                    className={cns(
                        classNameIconPlay,
                        styles.component__iconPlay
                    )}
                    icon="video-play"
                />
            </div>
        );
    } else {
        return videoElement;
    }
};

export default Video;
