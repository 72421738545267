import _ from "lodash";

import {
    ApiCartItem,
    ApiCartResponse,
    CartItem,
    CartResponse,
    OfferSize,
} from "@Types/api";

import { mapOffer } from "./catalog";

const generateOfferSizes = (item: ApiCartItem): OfferSize[] => {
    const possibleAlternatives = item.alternativeOffers.filter(offer => {
        if (!offer.properties.TSVET || !item.offer.properties.TSVET) {
            return false;
        }

        return (
            offer.properties.TSVET.VALUE_ENUM ===
            item.offer.properties.TSVET.VALUE_ENUM
        );
    });

    const defaultSize = item.offer.properties.RAZMER?.VALUE_ENUM
        ? {
              name: item.offer.properties.RAZMER.VALUE_ENUM,
              offerId: item.offer.ID,
          }
        : null;

    const sizes = possibleAlternatives
        .filter(offer => !!offer.properties.RAZMER?.VALUE_ENUM)
        .map(offer => ({
            name: offer.properties.RAZMER.VALUE_ENUM,
            offerId: offer.ID,
        }));

    if (defaultSize) {
        sizes.push(defaultSize);
    }

    return sizes.sort((a, b) => String(a.name).localeCompare(String(b.name)));
};

export const mapCartItem = (item: ApiCartItem): CartItem => ({
    ID: item.cartId,
    product: {
        ID: +item.product.ID,
        slug: item.product.CODE,
    },
    offer: mapOffer(item.offer, item.product),
    price: item.price,
    count: item.count,
    maxCount: item.maxCount,
    basePrice: item.basePrice,
    alternativeOffers: item.alternativeOffers.map(o =>
        mapOffer(o, item.product)
    ),
    availableSizes: generateOfferSizes(item),
    imageUrl: item.offer.properties.PHOTO_LINKS?.VALUES[0] || "",
});

export const mapCartResponse = ({
    items,
    totals,
    eligible,
}: ApiCartResponse): CartResponse => {
    return {
        eligible,
        items: items
            .filter(item => !_.isEmpty(item.offer))
            .map(mapCartItem)
            .sort(
                (a, b) => a.product.ID - b.product.ID || a.offer.ID - b.offer.ID
            ),
        totals: {
            ...totals,
            isCouponActive: _.isString(totals.coupon),
            totalDiscount: totals.subtotal - totals.total,
        },
    };
};
