import type {
    ApiReviewsResponse,
    FavouritesResponse,
    ProductItem,
    ReviewsResponse,
    User,
    UserResponse,
    ViewedProductsResponse,
} from "@Types/api";

import _ from "lodash";
import { DateTime } from "luxon";

import { BACKEND_DATE_FORMAT } from "@Settings";

import { mapProductItem } from "./catalog";

export const mapFavouritesResponse = (
    data: FavouritesResponse
): ProductItem[] =>
    _.map(
        _.filter(data, item => !_.isEmpty(item.offer)),
        mapProductItem
    );

export const mapViewedProductsResponse = (
    data: ViewedProductsResponse
): ProductItem[] => _.map(data, mapProductItem);

export const mapUser = (data: UserResponse | null): User => {
    if (!data) {
        return {
            isLoggedIn: false,
            data: null,
        };
    }

    return {
        isLoggedIn: true,
        data: {
            id: data.ID,
            name: data.NAME,
            lastName: data.LAST_NAME,
            addresses: data.UF_ADDRESSES || [],
            patronymic: data.SECOND_NAME,
            birthday: _.isEmpty(data.PERSONAL_BIRTHDAY)
                ? undefined
                : DateTime.fromFormat(
                      data.PERSONAL_BIRTHDAY,
                      BACKEND_DATE_FORMAT
                  ).toJSDate(),
            email: data.EMAIL,
            phone: data.PERSONAL_PHONE,
            isSubscribedOnEmail: data.SUBSCRIPTION_EMAIL,
            isSubscribedOnSMS: data.SUBSCRIPTION_SMS,
            isAdmin: data.IS_ADMIN ?? false,
        },
    };
};

export const mapReviews = (data: ApiReviewsResponse): ReviewsResponse =>
    data.reduce(
        (acc, item) => ({ ...acc, [item.order_id]: item.offer_ids }),
        {}
    );
