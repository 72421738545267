import { DateTime } from "luxon";

import {
    ApiOrder,
    ApiOrderItem,
    CreatedApiOrder,
    CreatedOrder,
    Order,
    OrderItem,
} from "@Types/api";

import { mapOffer } from "./catalog";

const mapOrderItem = (item: ApiOrderItem): OrderItem => ({
    ...item,
    imageUrl: item.offer?.properties?.PHOTO_LINKS?.VALUES[0] || "",
    product: {
        ID: +item.product.ID,
        slug: item.product.CODE,
    },
    offer: item.offer ? mapOffer(item.offer, item.product) : {},
});

export const mapCreatedOrder = (data: CreatedApiOrder): CreatedOrder => ({
    ...data,
    items: data.items.map(mapOrderItem),
});

export const mapOrder = (data: ApiOrder): Order => ({
    ...data,
    id: +data.ID,
    items: data.items.map(mapOrderItem),
    createdAt: DateTime.fromFormat(data.createdAt, "dd.MM.yyyy HH:mm:ss"),
    client: {
        email: data.client.EMAIL,
        fio: data.client.FIO,
        phone: data.client.PHONE,
    },
    status: {
        id: data.status.ID,
        name: data.status.NAME,
        sort: +data.status.SORT,
    },
});
