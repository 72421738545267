// eslint-disable-next-line
export default {
    createdBy: {
        link: "https://bytetown.ru/",
    },
    main: {
        name: "Главная",
        link: "/",
    },
    catalog: {
        name: "Каталог",
        link: "/c",
    },
    category: {
        link: (slug: string) => `/c/${slug}`,
    },
    product: {
        link: (slug: string, offerId?: string | number | null) =>
            `/p/${slug}${
                typeof offerId === "undefined" || offerId === null
                    ? ""
                    : `?offer=${offerId}`
            }`,
    },
    cart: {
        name: "Корзина",
        link: "/cart",
    },
    checkout: {
        name: "Оформление заказа",
        link: "/checkout",
        completedLink: (orderId: number) => `/checkout/completed?id=${orderId}`,
    },
    profile: {
        name: "Личный кабинет",
        link: "/profile",
    },
    orders: {
        name: "Мои заказы",
        link: "/profile/orders",
    },
    bonuses: {
        name: "Бонусные баллы",
        link: "/profile/bonuses",
    },
    favorites: {
        name: "Избранное",
        link: "/favorites",
    },
    contacts: {
        name: "Контакты",
        link: "/contacts",
    },
    aboutUs: {
        name: "О нас",
        link: "/about-us",
    },
    production: {
        name: "О производстве",
        link: "/production",
    },
    help: {
        name: "Помощь покупателю",
        link: (slug: string) => `/help/${slug}`,
    },
    delivery: {
        name: "Доставка",
        link: "/help/delivery",
    },
    refund: {
        name: "Возврат",
        link: "/help/refund",
    },
    payment: {
        name: "Условия оплаты",
        link: "/help/payment",
    },
    sizeGuide: {
        name: "Руководство по размерам",
        link: "/help/size-guide",
    },
    trust: {
        name: "Нам доверяют",
        link: "/help/trust",
    },
    feedback: {
        name: "Идеи и предложения",
        link: "/help/feedback",
    },
    faq: {
        name: "Вопросы и ответы",
        link: "/help/faq",
    },
    offer: {
        name: "Договор оферты",
        link: "/help/offer",
    },
    policy: {
        link: "/info/policy",
    },
    lookbook: {
        name: "LOOKBOOK",
        link: "/lookbook",
    },
    lookbookDetail: {
        link: (slug: string) => `/lookbook/${slug}`,
    },
};
