import { useEffect, useRef, useState } from "react";

import Button, { ButtonProps } from "./Button";

const MS_UNTIL_SPINNER = 1500;

interface AsyncButtonProps extends ButtonProps {
    onClick: (e: React.MouseEvent) => Promise<void>;
}

const useDelayedSpinner = (isLoading: boolean) => {
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        let spinnerTimeout: NodeJS.Timeout | null = null;

        if (isLoading) {
            spinnerTimeout = setTimeout(
                () => setShowSpinner(true),
                MS_UNTIL_SPINNER
            );
        } else {
            setShowSpinner(false);
        }

        return () => {
            spinnerTimeout && clearTimeout(spinnerTimeout);
        };
    }, [isLoading]);

    return showSpinner;
};

export const AsyncButton: React.FC<AsyncButtonProps> = ({
    onClick,
    disabled,
    children,
    ...props
}) => {
    const mountedRef = useRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const showSpinner = useDelayedSpinner(isLoading);

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    const handleClick = async (e: React.MouseEvent) => {
        setIsLoading(true);
        await onClick(e);

        if (mountedRef.current) {
            setIsLoading(false);
        }
    };

    return (
        <Button
            disabled={isLoading || disabled}
            onClick={handleClick}
            {...props}
        >
            {showSpinner ? "Загрузка..." : children}
        </Button>
    );
};
