import { useSelector } from "react-redux";

import Popup from "@Components/Popup";
import VideoEmbedded from "@Components/VideoEmbedded";
import { getExtra } from "@Redux/popup";

import styles from "./index.module.scss";

export type PopupVideoProps = Record<string, never>;

export const popupId = "video";

const PopupVideo: React.FC<PopupVideoProps> = () => {
    const extra = useSelector(getExtra(popupId));

    return (
        <Popup
            className={styles.component}
            classNameContainer={styles.component__container}
            classNameButtonClose={styles.component__buttonClose}
            id={popupId}
        >
            {extra?.srcEmbedded ? (
                <VideoEmbedded link={extra?.srcEmbedded} />
            ) : (
                <video controls playsInline>
                    <source src={extra?.src} />
                </video>
            )}
        </Popup>
    );
};

export default PopupVideo;
