import { cloneElement, useMemo } from "react";

type VideoEmbeddedProps = {
    className?: string;
    link: string;
};

type TemplateProps = {
    type: string;
    url: string;
};

const mapTypeToTitle: any = {
    youtube: "Youtube Embedded Video",
    vimeo: "Vimeo Embedded Video",
    vevo: "Vevo Embedded Video",
    gdrive: "Google Drive Embedded Video",
};

const patterns = [
    {
        regex: /youtu\.be\/([\w\-.]+)/i,
        type: "youtube",
        url: "//www.youtube.com/embed/%1",
    },
    {
        regex: /youtube\.com(.+)v=([^&]+)/i,
        type: "youtube",
        url: "//www.youtube.com/embed/%2",
    },
    {
        regex: /vimeo\.com\/([0-9]+)(\/(\w+))?/i,
        type: "vimeo",
        url: "//player.vimeo.com/video/%1?h=%3",
    },
    {
        regex: /vimeo\.com\/(.*)\/([0-9]+(\?.+)?)/i,
        type: "vimeo",
        url: "//player.vimeo.com/video/%2",
    },
    {
        regex: /vevo\.com.*\/(\w+)/i,
        type: "vevo",
        url: "//cache.vevo.com/assets/html/embed.html?video=%1",
    },
    {
        regex: /drive\.google\.com\/(file\/d\/[a-zA-Z0-9-_]+)/i,
        type: "gdrive",
        url: "https://drive.google.com/%1/preview",
    },
];

function template({ type, url }: TemplateProps) {
    const title = mapTypeToTitle[type];

    if (!title) return null;

    return (
        <iframe
            src={url}
            title={title}
            frameBorder="0"
            allowFullScreen={true}
            aria-hidden="true"
            autoPlay={false}
        />
    );
}

function embed(link: string) {
    try {
        let pattern, match, position;

        for (var i = 0; i < patterns.length; i++) {
            pattern = patterns[i];

            if ((match = link.match(pattern.regex))) {
                let regex = /%([0-9]+)/g;
                let matches = null;
                let url = String(pattern.url);

                while ((matches = regex.exec(pattern.url))) {
                    let num: any = matches[1];

                    url = url.replace(`%${num}`, match[num] || "");
                }

                return template({
                    type: pattern.type,
                    url,
                });
            }
        }
    } catch (err) {
        console.error(err);
    }

    return null;
}

const VideoEmbedded: React.FC<VideoEmbeddedProps> = ({ className, link }) => {
    const component = useMemo(() => {
        let c: any = embed(link);

        return cloneElement(c, {
            className,
        });
    }, [className, link]);

    if (!component) return null;

    return <>{component}</>;
};

export default VideoEmbedded;
