import { Client } from "@Utils/api/client";

export class Repository {
    constructor(protected client: Client) {}

    protected getEmptyNavResponse = () => {
        return {
            items: [],
            nav: {
                total_count: 0,
                page_count: 0,
                current_page: 1,
                page_size: 1,
            },
        };
    };
}

export default Repository;
