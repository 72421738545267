export default () => `
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2861_49131)">
            <path
                d="M10.6859 5.75818C10.6859 5.3794 10.3788 5.07233 10 5.07233C9.62122 5.07233 9.31415 5.3794 9.31415 5.75818V11.245C9.31415 11.6238 9.62122 11.9308 10 11.9308C10.3788 11.9308 10.6859 11.6238 10.6859 11.245V5.75818Z"
                fill="currentColor" />
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12.1387 0.954593C10.7773 0.348469 9.22268 0.348469 7.86131 0.954593C7.0101 1.33357 6.32273 2.05985 5.6129 3.06455C4.9053 4.06608 4.11287 5.43862 3.08914 7.21179L3.05172 7.27659C2.02798 9.04975 1.23554 10.4223 0.72199 11.5358C0.206815 12.6529 -0.0784776 13.6114 0.0189183 14.538C0.174688 16.0201 0.952004 17.3664 2.15761 18.2423C2.91142 18.79 3.88409 19.0222 5.10909 19.1345C6.33022 19.2466 7.91508 19.2466 9.96253 19.2466H10.0374C12.0849 19.2466 13.6698 19.2466 14.8909 19.1345C16.1159 19.0222 17.0886 18.79 17.8424 18.2423C19.048 17.3664 19.8253 16.0201 19.9811 14.538C20.0785 13.6114 19.7932 12.6529 19.278 11.5358C18.7645 10.4223 17.972 9.04972 16.9482 7.27654L16.9108 7.21171C15.8871 5.43858 15.0947 4.06606 14.3871 3.06455C13.6773 2.05985 12.9899 1.33357 12.1387 0.954593ZM8.41923 2.2077C9.42546 1.7597 10.5745 1.7597 11.5808 2.2077C12.0868 2.43299 12.5978 2.90912 13.2668 3.85606C13.9338 4.80007 14.6953 6.11776 15.7416 7.93004C16.788 9.74231 17.5483 11.0607 18.0324 12.1103C18.518 13.1632 18.6748 13.8438 18.6169 14.3946C18.5018 15.4901 17.9272 16.4852 17.0361 17.1326C16.588 17.4582 15.9202 17.6626 14.7656 17.7686C13.6146 17.8742 12.0926 17.8749 10 17.8749C7.90736 17.8749 6.38543 17.8742 5.23441 17.7686C4.07982 17.6626 3.41197 17.4582 2.96388 17.1326C2.07278 16.4852 1.49824 15.4901 1.3831 14.3946C1.32521 13.8438 1.48205 13.1632 1.96761 12.1103C2.45167 11.0607 3.21204 9.74231 4.25836 7.93004C5.30467 6.11776 6.06624 4.80007 6.7332 3.85606C7.40222 2.90912 7.91323 2.43299 8.41923 2.2077Z"
                fill="currentColor" />
            <path
                d="M10.9145 13.9884C10.9145 14.4934 10.5051 14.9028 10 14.9028C9.49499 14.9028 9.08557 14.4934 9.08557 13.9884C9.08557 13.4833 9.49499 13.0739 10 13.0739C10.5051 13.0739 10.9145 13.4833 10.9145 13.9884Z"
                fill="currentColor" />
        </g>
        <defs>
            <clipPath id="clip0_2861_49131">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
`;
