import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";

import { events } from "@Events";
import { ProductFull } from "@Types/api";

import { useApiClient } from "./api/client";

const VIEWED_PRODUCTS_KEY = ["products.viewed"];

export const useViewedProducts = (ignoredProductId?: number) => {
    const client = useApiClient();

    const { data, refetch: refetchViewedProducts } = useQuery({
        queryFn: client.user.getViewedProducts,
        queryKey: VIEWED_PRODUCTS_KEY,
        placeholderData: [],
    });

    const { mutateAsync: addToViewed } = useMutation({
        mutationFn: client.user.addToViewedProducts,
        onSuccess: () => refetchViewedProducts(),
    });

    const useViewedProductsTrigger = (product: ProductFull) =>
        useEffect(() => {
            events.emit("product.viewed", { product });
            void addToViewed(product.id);
        }, [product.id]);

    const viewedProducts = useMemo(() => {
        if (!data) {
            return [];
        }

        return data.filter(p => p.id !== ignoredProductId);
    }, [data, ignoredProductId]);

    return [viewedProducts, { addToViewed, useViewedProductsTrigger }] as const;
};
