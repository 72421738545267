import cns from "classnames";

import Link from "@Components/ui/Link";
import { SearchItem } from "@Types/api";
import { formatCurrency } from "@Utils/helpers";

import styles from "./index.module.scss";

export type ItemProps = {
    className?: string;
    product: SearchItem;
};

const Item: React.FC<ItemProps> = ({ className, product }) => {
    if (!product) {
        return null;
    }

    return (
        <div className={cns(className, styles.component)}>
            <div className={cns(styles.component__name, "text text_p3")}>
                {product.name}
            </div>

            <div
                className={cns(
                    styles.component__price,
                    "text text_p2 text_medium text_uppercase"
                )}
            >
                {product.priceOld && (
                    <div
                        className={cns(
                            styles.component__priceOld,
                            "text text_regular"
                        )}
                    >
                        {formatCurrency(product.priceOld)}
                    </div>
                )}

                {formatCurrency(product.price)}
            </div>

            <Link className={styles.component__link} href={product.link} />
        </div>
    );
};

export default Item;
