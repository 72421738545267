import _ from "lodash";
import { useEffect, useLayoutEffect, useRef } from "react";

const isBrowser = typeof window !== `undefined`;

// fix ssr warning(can't use useLayoutEffect with ssr...)
const useIsomorphicLayoutEffect = isBrowser ? useLayoutEffect : useEffect;

const DIRECTION_CHANGE_TOLERANCE = 2;

export enum ScrollDirection {
    UP = 1,
    DOWN = -1,
    DEFAULT = 0,
}

type ScrollPosition = {
    x: number;
    y: number;
};

type GetScrollPositionProps = {
    elementRef?: React.MutableRefObject<HTMLElement>;
    useWindow: boolean;
};

const getScrollPosition = ({
    elementRef,
    useWindow = false,
}: GetScrollPositionProps): ScrollPosition => {
    if (!isBrowser) return { x: 0, y: 0 };

    const target = elementRef ? elementRef.current : document.body;
    const position = target.getBoundingClientRect();

    return useWindow
        ? { x: window.scrollX, y: window.scrollY }
        : { x: position.left, y: position.top };
};

type EffectProps = {
    prevPos: ScrollPosition;
    currPos: ScrollPosition;
    directionY: ScrollDirection;
};

const useScrollPosition = (
    effect: (props: EffectProps) => void,
    deps?: any[],
    elementRef?: React.MutableRefObject<HTMLElement>,
    useWindow?: boolean
) => {
    const position = useRef(getScrollPosition({ useWindow }));
    let directionY = ScrollDirection.DEFAULT;

    const callBack = () => {
        const currPos = getScrollPosition({ elementRef, useWindow });

        const newYPos = position.current?.y || 0;

        const differenceForY = currPos.y - newYPos;

        if (differenceForY > DIRECTION_CHANGE_TOLERANCE) {
            directionY = ScrollDirection.UP;
        } else if (differenceForY < -DIRECTION_CHANGE_TOLERANCE) {
            directionY = ScrollDirection.DOWN;
        }

        effect({
            prevPos: position.current,
            currPos,
            directionY,
        });

        position.current = currPos;
    };

    useIsomorphicLayoutEffect(() => {
        const handleScroll = _.debounce(callBack, 15);

        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => window.removeEventListener("scroll", handleScroll);
    }, deps);

    useEffect(() => {
        callBack();
    }, []);
};

export default useScrollPosition;
