import cns from "classnames";
import React, {
    forwardRef,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from "react";
import AnimateHeight from "react-animate-height";
import { useClickAway } from "react-use";

import Link from "@Components/ui/Link";
import { IconsList } from "@Components/ui/SvgIcon";
import TextIcon from "@Components/ui/TextIcon";
import useWindowSize from "@Hooks/window-size";

import styles from "./index.module.scss";

export type DropdownProps = {
    className?: string;
    classNameButton?: string;
    classNameButtonText?: string;
    classNameButtonIcon?: string;
    classNameList?: string;
    classNameListContent?: string;
    classNameItem?: string;
    buttonLink?: string;
    buttonText: React.ReactNode;
    buttonIcon?: IconsList;
    buttonIconIsRight?: boolean;
    onButtonClick?: (e?: React.MouseEvent) => void;
    items: ReactNode[];
    itemWrapper?: boolean;
    animationDuration?: number;
    open?: boolean;
    setOpen?: (params: boolean) => void;
    controlState?: boolean;
    strictControlState?: boolean;
    closeOnItemClick?: boolean;
    closeOnOutsideClick?: boolean;
};

const Dropdown = forwardRef<HTMLButtonElement, DropdownProps>(
    (
        {
            className,
            classNameButton,
            classNameButtonText,
            classNameButtonIcon,
            classNameList,
            classNameListContent,
            classNameItem,
            buttonLink,
            buttonText,
            buttonIcon = "angle-down",
            buttonIconIsRight = true,
            onButtonClick,
            items,
            itemWrapper = false,
            animationDuration = 500,
            open = false,
            setOpen,
            controlState = false,
            strictControlState = false,
            closeOnItemClick = false,
            closeOnOutsideClick = false,
        },
        ref
    ) => {
        const [isOpen, setIsOpen] = useState<boolean>(open);
        const [height, setHeight] = useState<"auto" | number>(
            open ? "auto" : 0
        );

        const refComponent = useRef<HTMLDivElement>(null);

        const windowSize = useWindowSize();

        useEffect(() => {
            setHeight(isOpen ? "auto" : 0);

            if (controlState && setOpen) {
                setOpen(isOpen);
            }
        }, [isOpen]);

        useEffect(() => {
            if (controlState || strictControlState) {
                setIsOpen(open);
            }
        }, [open]);

        useClickAway(refComponent, () => {
            if (isOpen && closeOnOutsideClick && !strictControlState) {
                setIsOpen(false);
            }
        });

        if (!items || !items.length) {
            return null;
        }

        const handleButtonClick = (e: React.MouseEvent) => {
            if (!strictControlState) {
                setIsOpen(prev => !prev);
            }

            if (onButtonClick) {
                onButtonClick(e);
            }
        };

        const handleItemClick = () => {
            if (!strictControlState) {
                setIsOpen(false);
            }
        };

        const buttonProps = {
            ref,
            className: cns(classNameButton, styles.component__button),
            "aria-expanded": height !== 0,
            onClick: handleButtonClick,
        };

        const buttonContent = (
            <TextIcon
                textClassName={classNameButtonText}
                iconClassName={cns(
                    classNameButtonIcon,
                    styles.component__icon,
                    { [styles.component__icon_reversed]: isOpen }
                )}
                icon={buttonIcon}
                isRight={buttonIconIsRight}
            >
                {buttonText}
            </TextIcon>
        );

        return (
            <div
                ref={refComponent}
                className={cns(className, styles.component)}
            >
                {buttonLink ? (
                    <Link href={buttonLink} simple {...buttonProps}>
                        {buttonContent}
                    </Link>
                ) : (
                    <button type="button" {...buttonProps}>
                        {buttonContent}
                    </button>
                )}

                <AnimateHeight
                    className={classNameList}
                    contentClassName={classNameListContent}
                    duration={windowSize.isDesktop ? animationDuration : 0}
                    height={height}
                >
                    {items.map((item, itemIndex) => {
                        if (itemWrapper || closeOnItemClick) {
                            return (
                                <div
                                    key={itemIndex}
                                    className={classNameItem}
                                    onClick={
                                        closeOnItemClick
                                            ? handleItemClick
                                            : undefined
                                    }
                                >
                                    {item}
                                </div>
                            );
                        }

                        return (
                            <React.Fragment key={itemIndex}>
                                {item}
                            </React.Fragment>
                        );
                    })}
                </AnimateHeight>
            </div>
        );
    }
);

export default Dropdown;
