import NextNProgress from "nextjs-progressbar";

export type RouterProgressBarProps = {};

const RouterProgressBar: React.FC<RouterProgressBarProps> = () => {
    return (
        <NextNProgress
            height={2}
            showOnShallow={false}
            options={{
                showSpinner: false,
            }}
        />
    );
};

export default RouterProgressBar;
