import type {
    ApiBrandVideosResponse,
    ApiContentBannersResponse,
    ApiContentMenuResponse,
    ApiContentSocialsResponse,
    ApiFaqResponse,
    ApiHighlightsResponse,
    ApiLookbookFullResponse,
    ApiLookbookResponse,
    ApiTeamResponse,
    ApiTrustResponse,
    FaqQueryParams,
    LookbookQueryParams,
    Result,
    TrustQueryParams,
} from "@Types/api";

import * as mappers from "@Api/mappers";
import { Repository } from "@Utils/api/repository";

class ContentApi extends Repository {
    getBanners = async (slug: "top" | "middle" | "bottom" | "menu") => {
        const result: Result<ApiContentBannersResponse> =
            await this.client.request.get(`/content/banners/${slug}`);

        return mappers.mapBannersResponse(result?.data ?? []);
    };

    getMenu = async (slug: "topmenu" | "bottommenu" | "burgermenu") => {
        const result: Result<ApiContentMenuResponse> =
            await this.client.request.get(`/content/menu/${slug}`);

        return mappers.mapMenuResponse(result?.data ?? []);
    };

    getSocials = async () => {
        const result: Result<ApiContentSocialsResponse> =
            await this.client.request.get("/content/social");

        return mappers.mapSocialsResponse(result?.data ?? []);
    };

    getBrandVideos = async () => {
        const result: Result<ApiBrandVideosResponse> =
            await this.client.request.get("/content/brand-videos");

        return mappers.mapBrandVideosResponse(result?.data ?? []);
    };

    getTeam = async () => {
        const result: Result<ApiTeamResponse> =
            await this.client.request.get("/content/team");

        return mappers.mapTeamResponse(result?.data ?? []);
    };

    getTrust = async (params?: TrustQueryParams) => {
        const result: Result<ApiTrustResponse> = await this.client.request.get(
            "/content/trust",
            params
        );

        return mappers.mapTrustResponse(result?.data ?? { items: [] });
    };

    getFaq = async (params?: FaqQueryParams) => {
        const result: Result<ApiFaqResponse> = await this.client.request.get(
            "/content/faq",
            params
        );

        return mappers.mapFaqResponse(result?.data ?? []);
    };

    getHighlights = async () => {
        const result: Result<ApiHighlightsResponse> =
            await this.client.request.get("/content/highlights");

        return mappers.mapHighlightsResponse(result?.data ?? []);
    };

    getLookbooks = async (params?: LookbookQueryParams) => {
        const result: Result<ApiLookbookResponse> =
            await this.client.request.get("/content/lookbooks", params);

        return mappers.mapLookbookResponse(result?.data ?? { items: [] });
    };

    getLookbook = async (slug: string) => {
        const result: Result<ApiLookbookFullResponse> =
            await this.client.request.get(`/content/lookbooks/${slug}`);

        return mappers.mapLookbookFullResponse(result?.data ?? null);
    };
}

export default ContentApi;
