import { useField } from "formik";

import Select, { SelectOption, SelectProps } from "@Components/ui/Select";

type FormikSelectProps<T> = Omit<SelectProps<T>, "name"> & {
    name: string;
    setValue: (
        options: SelectOption<T>[],
        fieldValue: T
    ) => SelectOption<T> | undefined;
};

export function FormikSelect<T>({
    name,
    options,
    setValue,
    ...props
}: FormikSelectProps<T>) {
    const [field, meta, helpers] = useField<T>(name);
    const error = meta.touched && meta.error ? meta.error : undefined;

    return (
        <Select
            {...field}
            {...props}
            options={options}
            value={setValue(options, field.value) || null}
            onChange={selectedOption => {
                if (!selectedOption) {
                    return;
                }

                void helpers.setValue(selectedOption.value);
            }}
            filterOption={({ value }, inputValue) => {
                return String(value).includes(inputValue);
            }}
            error={error}
        />
    );
}
