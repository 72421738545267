import cns from "classnames";

import pages from "@Settings/pages";
import Button from "@Components/ui/Button";

import styles from "./index.module.scss";

type MessageEmptyProps = {
    className?: string;
    classNameText?: string;
    classNameCaption?: string;
    classNameButton?: string;
    text?: string;
    caption?: string;
    button?: {
        link?: string;
        text?: string;
    };
};

const MessageEmpty: React.FC<MessageEmptyProps> = ({
    className,
    classNameText,
    classNameCaption,
    classNameButton,
    text = "Кажется, здесь ничего нет",
    caption = "Скорректируйте свой запрос или\u00A0попробуйте позже",
    button = {
        link: pages.main.link,
        text: "На главную",
    },
}) => {
    return (
        <div className={cns("text text_center", className, styles.component)}>
            <div
                className={cns(
                    classNameText,
                    "text text_h4",
                    styles.component__text
                )}
            >
                {text}
            </div>

            <div className={cns(classNameCaption, styles.component__caption)}>
                {caption}
            </div>

            <Button
                className={cns(classNameButton, styles.component__button)}
                href={button.link}
            >
                {button.text}
            </Button>
        </div>
    );
};

export default MessageEmpty;
