import cns from "classnames";
import { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";

import { openOneClickPopup } from "@Components/PopupOneClick";
import { AsyncButton } from "@Components/ui/Button";
import SvgIcon from "@Components/ui/SvgIcon";
import { useFavouriteProductMethods } from "@Hooks/user";
import { useProductContext } from "@Pages/product/context";

import styles from "./index.module.scss";

export type ButtonsProps = {
    className?: string;
};

const Buttons: React.FC<ButtonsProps> = ({ className }) => {
    const { ref: refButtonCart, inView } = useInView();

    const {
        product,
        offerId,
        sizeId,
        availableSizes,
        addToCart,
        hasMoreToAdd,
        isAvailable,
        isInCart,
    } = useProductContext();

    const { isFavourite, toggleFavourites } = useFavouriteProductMethods(
        product.id
    );

    const dispatch = useDispatch();

    const isNeedToSelectSize = useMemo(
        () => Object.keys(availableSizes).length && sizeId === null,
        [availableSizes, sizeId]
    );

    const getButtonText = () => {
        if (isInCart && hasMoreToAdd) {
            return "Добавить ещё";
        }

        if (hasMoreToAdd) {
            return "Добавить в корзину";
        }

        if (!isAvailable) {
            return "Предзаказ";
        }

        return "Добавили максимум";
    };

    const getOneClickTitle = () => {
        if (!isAvailable) {
            return "Нет в наличии";
        }

        if (isNeedToSelectSize) {
            return "Чтобы купить, выберите размер";
        }

        return;
    };

    const handleAddToCartClick = async () => {
        await addToCart();
    };

    const handleButtonOneClickClick = async () => {
        await addToCart("one-click");
        dispatch(openOneClickPopup());
    };

    useEffect(() => {
        document.documentElement.classList.toggle("buttonCartInView", inView);

        return () => {
            document.documentElement.classList.remove("buttonCartInView");
        };
    }, [inView]);

    return (
        <div className={cns(className, styles.component)}>
            <div
                ref={refButtonCart}
                className={styles.component__buttonCartWrapper}
            >
                <AsyncButton
                    className={styles.component__buttonCart}
                    onClick={handleAddToCartClick}
                    disabled={!offerId || (isAvailable && !hasMoreToAdd)}
                >
                    {getButtonText()}
                </AsyncButton>
            </div>

            <AsyncButton
                className={styles.component__buttonOneClick}
                color="tDark"
                onClick={handleButtonOneClickClick}
                disabled={isNeedToSelectSize || !isAvailable}
                title={getOneClickTitle()}
            >
                Купить в 1 клик
            </AsyncButton>

            <button
                className={cns(styles.component__buttonFavorites, {
                    inFavorites: isFavourite,
                })}
                type="button"
                aria-label={
                    isFavourite
                        ? "Убрать из избранного"
                        : "Добавить в избранное"
                }
                onClick={toggleFavourites}
            >
                <SvgIcon icon="heart" />
            </button>
        </div>
    );
};

export default Buttons;
