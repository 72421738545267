import { CLOUDPAYMENTS_PK } from "@Settings";
import { events } from "@Events";
import { PaymentType } from "@Types/api";

type CloudPaymentsOptions = {
    invoiceId: string | number;
    amount: number;
};

const PAYMENT_DESCRIPTION = "Оплата заказа";

export const openCloudPayemntsWidget = ({
    invoiceId,
    amount,
}: CloudPaymentsOptions) => {
    const widget = new window.cp.CloudPayments();

    return new Promise((resolve, reject) => {
        widget.charge(
            {
                publicId: CLOUDPAYMENTS_PK,
                description: PAYMENT_DESCRIPTION,
                amount: amount,
                currency: "RUB",
                invoiceId: String(invoiceId),
                skin: "classic",
                data: {
                    PAY_SYSTEM_ID: PaymentType.CloudPayments,
                },
            },
            options => {
                events.emit("order.payed");
                resolve(options);
            },
            (reason, options) => {
                reject(reason);
            }
        );
    });
};
