import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useScrollbarSize from "react-scrollbar-size";

const setProps = (value: number) => {
    document.documentElement.style.setProperty(
        "--scrollbar-width",
        `${value}px`
    );

    document.documentElement.classList.toggle("noScrollbar", !value);
};

const useSetScrollbarWidth = () => {
    const [bodyScrollHeight, setBodyScrollHeight] = useState<number>(0);

    const router = useRouter();

    const { width } = useScrollbarSize();

    useEffect(() => {
        setProps(bodyScrollHeight > innerHeight ? width : 0);
    }, [bodyScrollHeight, router.asPath, width]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            setBodyScrollHeight(document.body.scrollHeight);
        });

        resizeObserver.observe(document.body);

        return () => resizeObserver.disconnect();
    }, []);
};

export default useSetScrollbarWidth;
