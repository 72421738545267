import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import { events } from "@Events";
import {
    CartItem,
    CartResponse,
    ContextAddToCart,
    DeliveryType,
} from "@Types/api";

import { useDataContext } from "./api/client";

const CART_QUERY = (deliveryId?: DeliveryType) => ["cart", deliveryId];
const CART_RECOMMENDED_QUERY = ["cart.recommendations"];

export const useCart = (deliveryId?: DeliveryType) => {
    const queryClient = useQueryClient();
    const { client } = useDataContext();
    const {
        data,
        isLoading,
        isFetching,
        refetch: refetchCart,
    } = useQuery({
        queryKey: CART_QUERY(deliveryId),
        queryFn: () => {
            return client.cart.getCart({ delivery_id: deliveryId });
        },
    });

    const productsQuantity =
        _.sumBy(data?.items || [], item => item.count) || 0;
    const isEmpty = productsQuantity === 0;

    const { data: recommendations, refetch: refetchRecommendations } = useQuery(
        {
            queryKey: CART_RECOMMENDED_QUERY,
            queryFn: async () => {
                const cartData = queryClient.getQueryData<CartResponse>(
                    CART_QUERY(deliveryId)
                );

                const productSlugs =
                    cartData?.items
                        .map(cartItem => cartItem.product.slug)
                        .slice(0, 5) || [];

                if (_.isEmpty(productSlugs)) {
                    return [];
                }

                const result = await Promise.all(
                    productSlugs.map(slug =>
                        client.catalog.getProductRecommendations(slug)
                    )
                );

                return _.uniqBy(
                    _.flatten(result.map(r => r.products)),
                    p => p.id
                ).slice(0, 20);
            },
            enabled: !isEmpty,
            placeholderData: [],
        }
    );

    return {
        data,
        recommendations,
        productsQuantity,
        isLoading,
        isFetching,
        isEmpty,
        refetchCart: async () => {
            await queryClient.invalidateQueries(["cart"]);
            return await refetchCart();
        },
        refetchRecommendations,
    };
};

// only add and use promo. Other to CartItemMethods
export const useCartMethods = () => {
    const { client } = useDataContext();
    const { data, refetchCart, refetchRecommendations } = useCart();
    const queryClient = useQueryClient();

    const getCartItemById = (id: number) =>
        data?.items.find(item => item.ID === id) || null;

    const getCartItemByOfferId = (offerId: number) =>
        data?.items.find(item => item.offer.ID === offerId) || null;

    const { mutateAsync: applyPromocode } = useMutation(
        client.cart.applyPromocode
    );

    const { mutateAsync: removePromocode } = useMutation(
        client.cart.removePromocode
    );

    const { mutateAsync: addCartItem, isLoading: isAddCartItemLoading } =
        useMutation(client.cart.addCartItem);

    const { mutateAsync: updateCartItem, isLoading: isRemoveCartItemLoading } =
        useMutation(client.cart.updateCartItem, {
            onMutate: async ({ itemId, quantity, action }) => {
                await queryClient.cancelQueries({ queryKey: CART_QUERY() });
                const oldCart = queryClient.getQueryData(CART_QUERY());
                queryClient.setQueryData(
                    CART_QUERY(),
                    (oldCart?: CartResponse) => {
                        if (!oldCart || action === "delete") {
                            return;
                        }

                        const mapItem = (item: CartItem) => {
                            if (item.ID !== itemId) {
                                return item;
                            }
                            return {
                                ...item,
                                count: Math.min(quantity, item.offer.maxCount),
                            };
                        };

                        return {
                            ...oldCart,
                            // todo
                            // items: oldCart.items.map(mapItem),
                        };
                    }
                );
                return { oldCart };
            },
            onSettled: () => {
                // todo
            },
        });

    const removeCartItem = async (itemId: number, quantity = 1) => {
        const item = getCartItemById(itemId);

        if (!item) {
            return;
        }

        const targetQuantity = (item.count || 1) - quantity;
        await updateCartItem({
            itemId,
            quantity: targetQuantity,
            action: targetQuantity === 0 ? "delete" : "update",
        });

        events.emit("cart.item.removed", { item: _.cloneDeep(item), quantity });

        await refetchCart();
    };

    return {
        isLoading: isAddCartItemLoading || isRemoveCartItemLoading,

        // проблема с высотой при переключении цветов для предложений со скидкой

        removePromocode: async () => {
            try {
                await removePromocode();
            } catch (err) {}

            await refetchCart();
        },

        applyPromocode: async (code: string) => {
            try {
                await applyPromocode(code);
            } catch (err) {
                throw err;
            } finally {
                await refetchCart();
            }
        },

        /**
         * Добавить товар в корзину.
         * @param offerId - ID торгового предложения
         */
        addCartItem: async (
            offerId: number,
            quantity = 1,
            context: ContextAddToCart = {}
        ) => {
            const cartItem = getCartItemByOfferId(offerId);
            const existingCount = cartItem?.count || 0;

            if (!cartItem) {
                await addCartItem({ offerId, quantity });
            } else {
                await updateCartItem({
                    itemId: cartItem.ID,
                    quantity: cartItem.count + quantity,
                    action: "update",
                });
            }

            const cartResponse = await refetchCart();
            void refetchRecommendations();

            const newCartItem =
                cartResponse.data?.items.find(
                    item => item.offer.ID === offerId
                ) || null;

            if (newCartItem && newCartItem.count > existingCount) {
                events.emit("cart.item.added", {
                    ...context,
                    item: newCartItem,
                    quantity: newCartItem.count - existingCount,
                });
            }
        },
        removeCartItem,
        destroyCartItem: async (itemId: number) => {
            const cartItem = getCartItemById(itemId);

            if (!cartItem) {
                return;
            }

            await removeCartItem(itemId, cartItem.count);
            await refetchRecommendations();
        },
        changeSize: async (itemId: number, desiredSize: string) => {
            const cartItem = getCartItemById(itemId);

            if (!cartItem) {
                // log error or throw
                return;
            }

            const currentColor = cartItem.offer.color;
            const desiredOffer = cartItem.alternativeOffers.find(
                offer =>
                    offer.color === currentColor && offer.size === desiredSize
            );

            if (!desiredOffer) {
                return;
            }

            const existingOffer = getCartItemByOfferId(desiredOffer.ID);
            const maxQuantity = existingOffer
                ? existingOffer.maxCount - existingOffer.count
                : desiredOffer.maxCount;

            if (maxQuantity > 0) {
                await addCartItem({
                    offerId: desiredOffer.ID,
                    quantity: Math.min(cartItem.count, maxQuantity),
                });
            }

            await updateCartItem({ itemId, quantity: 0, action: "delete" });

            const { data: cart } = await refetchCart();

            events.emit("cart.item.size.changed");
        },
    };
};

/**
 * Используется в компоненте карточки товара/на странице товара
 * @param productId - ID товара
 */
export const useProductCartMethods = (productId: number, offerId: number) => {
    const { data } = useCart();
    const { addCartItem } = useCartMethods();

    const cartItem = data?.items.find(
        item => item.product.ID === productId && item.offer.ID === offerId
    );

    return {
        canAddMore: cartItem ? cartItem.maxCount > cartItem.count : true,
        isInCart: !!cartItem,
        addToCart: async (offerId: number, context: ContextAddToCart = {}) => {
            await addCartItem(offerId, 1, context);
        },
    };
};

/**
 * Используется в компоненте карточки предмета корзины
 * @param itemId - ID предмета в корзине
 */
export const useCartItemMethods = (itemId: number) => {
    const { data } = useCart();
    const { addCartItem, removeCartItem, destroyCartItem, changeSize } =
        useCartMethods();

    const cartItem = data?.items.find(item => item.ID === itemId);

    if (!cartItem) {
        throw new Error("Invalid CartItem.ID");
    }

    return {
        isLastItem: cartItem.count === 1,
        canAddMore: cartItem.count < cartItem.maxCount,
        hasDiscount: cartItem.price < cartItem.basePrice,
        increaseQuantity: (quantity = 1) =>
            addCartItem(cartItem.offer.ID, quantity),
        decreaseQuantity: (quantity = 1) => removeCartItem(itemId, quantity),
        destroy: () => destroyCartItem(itemId),
        changeSize: (desiredSize: string) => changeSize(itemId, desiredSize),
    };
};
