import type {
    ApiFullCategory,
    ApiOffer,
    ApiPagination,
    ApiProduct,
    ApiProductFull,
    ApiProductFullCharacteristics,
    ApiProductItem,
    ApiProductItemRec,
    ApiProductRecommendationsResponse,
    ApiProductReview,
    ApiProductReviewsResponse,
    ApiProductsResponse,
    ApiSearchItem,
    ApiSearchResponse,
    ApiStore,
    ApiWarehouse,
    FullCategory,
    Offer,
    Pagination,
    ProductFull,
    ProductItem,
    ProductItemRec,
    ProductRecommendationsResponse,
    ProductReview,
    ProductReviews,
    ProductsResponse,
    SearchItem,
    SearchResponse,
    Store,
    Warehouse,
} from "@Types/api";

import _ from "lodash";

import pages from "@Settings/pages";
import { isVideoSource } from "@Utils/helpers";

const productCharacteristicsFields = [
    "COMPOSIT",
    "OSNOVNOJ_MATERIAL",
    "VYSOTA_KABLUKA",
    "PODKLADKA",
    "VYSOTA_PODOSHVY",
    "NAPOLNITEL",
];

const offerCharacteristicsFields = [
    "ROST_MODELI",
    "PARAMETRY_MODELI",
    "NA_MODELI_RAZMER",
    "DLINA_RUKAVA",
    "DLINA_IZDELIYA",
    "OBHVAT_GRUDI",
    "OBHVAT_TALII",
    "OBHVAT_BEDER",
    "RAZMER_VVERHA",
    "RAZMER_NIZA",
    "DETALI",
];

const getCharacteristics = (
    fields: string[],
    obj: any
): ProductFull["characteristics"] => {
    const array: ProductFull["characteristics"] = [];

    fields.forEach((field: any) => {
        const fieldObj: ApiProductFullCharacteristics | undefined = obj[field];

        if (fieldObj) {
            array.push({
                key: fieldObj.NAME,
                value: fieldObj.VALUE,
            });
        }
    });

    return array;
};

const mapWarehouse = (data: ApiWarehouse): Warehouse => ({
    ID: +data.STORE_ID,
    address: data.STORE_ADDR || data.STORE_NAME,
    quantity: +data.AMOUNT,
    reserved: +data.QUANTITY_RESERVED,
    available: Number(data.AMOUNT) - Number(data.QUANTITY_RESERVED),
});

const getMaxCount = (offer: ApiOffer) => {
    if (offer.ACTIVE === "N") {
        return 0;
    }

    if (offer.QUANTITY) {
        return +offer.QUANTITY;
    }
    const warehouses = offer.warehouses?.map(mapWarehouse) || [];

    return Math.max(
        0,
        _.sumBy(warehouses, w => w.available)
    );
};

export const mapOffer = (offer: ApiOffer, product?: ApiProduct): Offer => {
    const warehouses = offer.warehouses?.map(mapWarehouse) || [];
    const maxCount = getMaxCount(offer);

    return {
        ID: offer.ID,
        name: offer.NAME || product?.NAME || "",
        code: product?.properties.CML2_ARTICLE?.VALUE || "",
        color: offer.properties.TSVET?.VALUE_ENUM || "",
        colorId: offer.properties.TSVET?.VALUE
            ? +offer.properties.TSVET?.VALUE
            : null,
        size: offer.properties.RAZMER?.VALUE_ENUM ?? null,
        warehouses,
        maxCount,
        isAvailable: maxCount > 0,
    };
};

export const mapProductItem = (item: ApiProductItem): ProductItem => {
    const labels = [];

    if (item.product.properties.HIT?.VALUE_XML_ID === "Y") {
        labels.push("Hit");
    }

    if (item.product.properties.NEW?.VALUE_XML_ID === "Y") {
        labels.push("New");
    }

    if (item.offer.sale.DISCONT > 0) {
        labels.push(`-${item.offer.sale.DISCONT}%`);
    }

    return {
        id: +item.product.ID,
        slug: item.product.CODE,
        link: pages.product.link(item.product.CODE, item.offer.ID),
        name: item.product.NAME,
        price: +item.offer.price,
        priceOld:
            item.offer.sale.MAIN_PRICE && item.offer.sale.SALE_PRICE
                ? +item.offer.sale.MAIN_PRICE
                : null,
        labels,
        gallery: item.offer.properties.PHOTO_LINKS?.VALUES ?? [],
        inFavorites: false,
        offer: mapOffer(item.offer, item.product),
        availableOffers: [],
        isAvailable:
            item.product.ACTIVE === "Y" && item.product.AVAILABLE === "Y",
    };
};

export const mapPagination = (data?: ApiPagination): Pagination | null => {
    if (!data) {
        return null;
    }

    return {
        countCurrent: Math.min(
            data.page_size * data.current_page,
            +data.total_count
        ),
        countTotal: +data.total_count,
        pageCurrent: +data.current_page,
        pagesCount: +data.page_count,
    };
};

export const mapProductsResponse = (
    data: ApiProductsResponse
): ProductsResponse => {
    return {
        products:
            data.items
                ?.filter(item => !_.isEmpty(item.offer))
                .map(mapProductItem) || [],
        pagination: mapPagination(data.nav),
    };
};

export const mapFullCategory = (data: ApiFullCategory): FullCategory => ({
    id: +data.ID,
    seo: {
        title: data.seo.META_TITLE,
        keywords: data.seo.META_KEYWORDS || "",
        description: data.seo.META_DESCRIPTION || "",
    },
});

export const mapProductFullResponse = (
    data: ApiProductFull
): ProductFull | null => {
    if (!data || !data.product || !data.offers?.length) {
        return null;
    }

    const defaultOfferId =
        data.defaultOfferId === null
            ? +data.offers[0].ID
            : +data.defaultOfferId;

    const labels: ProductFull["labels"] = [];
    const colors: ProductFull["colors"] = {};
    const sizes: ProductFull["sizes"] = {};
    const characteristics: ProductFull["characteristics"] = getCharacteristics(
        productCharacteristicsFields,
        data.product.properties
    );
    const reviews: ProductFull["reviews"] = [];
    const offers: ProductFull["offers"] = {};

    if (data.product.properties.HIT) {
        labels.push("Hit");
    }

    if (data.product.properties.NEW) {
        labels.push("New");
    }

    data.offers
        .filter(offer => offer.ACTIVE !== "N")
        .forEach(offer => {
            const offerId = +offer.ID;
            const colorId =
                +offer.properties.TSVET?.VALUE || offerId + Date.now();
            const sizeId = +offer.properties.RAZMER?.VALUE || null;
            const size = offer.properties.RAZMER?.VALUE_ENUM;
            const sizeAmount =
                data.product.ACTIVE === "N" || offer.ACTIVE === "N"
                    ? 0
                    : Math.max(
                          0,
                          _.sumBy(
                              offer.warehouses,
                              o =>
                                  Number(o.AMOUNT) - Number(o.QUANTITY_RESERVED)
                          )
                      );
            const offerCharacteristics = getCharacteristics(
                offerCharacteristicsFields,
                offer.properties
            );

            let colorCurrent = colors[colorId];

            if (!colorCurrent) {
                colors[colorId] = {
                    sort: +offer.SORT || 0,
                    offersId: [],
                    name: offer.properties.TSVET?.VALUE_ENUM || "",
                    sizes: {},
                    shops: {},
                    image:
                        offer.properties.PHOTO_LINKS?.VALUES.find(
                            value => !isVideoSource(value)
                        ) ?? null,
                };

                colorCurrent = colors[colorId];
            }

            colorCurrent.offersId.push(offerId);

            if (size && sizeId !== null) {
                colorCurrent.sizes[sizeId] = {
                    name: size,
                    amount: Math.max(0, sizeAmount),
                    offerId: offerId,
                };
            }
            const maxPossibleCount = getMaxCount(offer);

            offer.warehouses.forEach(warehouse => {
                const availableAmount = Math.min(
                    maxPossibleCount,
                    Number(warehouse.AMOUNT) -
                        Number(warehouse.QUANTITY_RESERVED)
                );

                let shopCurrent = colorCurrent.shops[+warehouse.STORE_ID];

                if (!shopCurrent) {
                    colorCurrent.shops[+warehouse.STORE_ID] = {
                        id: warehouse.STORE_ID,
                        address: warehouse.STORE_ADDR || warehouse.STORE_NAME,
                        sizesInStock: [],
                    };

                    shopCurrent = colorCurrent.shops[+warehouse.STORE_ID];
                }

                if (
                    !shopCurrent.sizesInStock.includes(size) &&
                    availableAmount > 0 &&
                    !!size
                ) {
                    shopCurrent.sizesInStock.push(size);
                }
            });

            if (!!size && sizeId !== null && !sizes[sizeId]) {
                sizes[sizeId] = size;
            }

            offers[offerId] = {
                name: offer.NAME || data.product.NAME,
                price: {
                    current: +offer.price,
                    old:
                        offer.sale.MAIN_PRICE && offer.sale.SALE_PRICE
                            ? +offer.sale.MAIN_PRICE
                            : null,
                    discount: offer.sale.DISCONT,
                },
                images: offer.properties.PHOTO_LINKS?.VALUES ?? [],
                colorId,
                sizeId,
                characteristics: offerCharacteristics,
                isAvailable: maxPossibleCount > 0,
            };
        });

    const { SIZE_TABLE } = data.product.properties;

    return {
        id: +data.product.ID,
        slug: data.product.CODE,
        isAvailable:
            data.product.ACTIVE === "Y" && data.product.AVAILABLE === "Y",
        categoryId: +data.product.SECTION_ID || null,
        article: data.product.properties.CML2_ARTICLE?.VALUE || "",
        name: data.product.NAME,
        labels,
        split: false,
        colors,
        sizes,
        sizesTable: {
            table: SIZE_TABLE?.TABLE ?? null,
            description: SIZE_TABLE?.DESCRIPTION ?? null,
            image: SIZE_TABLE?.IMAGE.SRC ?? null,
        },
        description: data.product.DETAIL_TEXT ?? null,
        characteristics,
        reviews,
        offers,
        default: {
            offerId: defaultOfferId,
            price: offers[defaultOfferId].price.current,
            priceOld: offers[defaultOfferId].price.old,
            shops: colors[offers[defaultOfferId].colorId].shops,
        },
        seo: {
            title: data.seo.META_TITLE,
            keywords: data.seo.META_KEYWORDS || "",
            description: data.seo.META_DESCRIPTION || "",
        },
        editLink: data.product.EDIT_LINK ?? null,
    };
};

export const mapProductItemRec = (item: ApiProductItemRec): ProductItemRec => {
    const labels = [];

    if (item.properties.HIT) {
        labels.push("Hit");
    }

    if (item.properties.NEW) {
        labels.push("New");
    }

    if (item.offer.sale.DISCONT) {
        labels.push(`-${item.offer.sale.DISCONT}%`);
    }

    return {
        id: +item.ID,
        slug: item.CODE,
        link: pages.product.link(item.CODE, item.offer.ID),
        name: item.NAME,
        price: +item.offer.price,
        priceOld:
            item.offer.sale.MAIN_PRICE && item.offer.sale.SALE_PRICE
                ? +item.offer.sale.MAIN_PRICE
                : null,
        labels,
        gallery: item.offer.properties.PHOTO_LINKS?.VALUES ?? [],
        offer: mapOffer(item.offer),
        isAvailable: item.AVAILABLE === "Y",
    };
};

export const mapProductRecommendationsResponse = (
    data: ApiProductRecommendationsResponse
): ProductRecommendationsResponse => {
    return {
        products:
            data.recommendations
                ?.filter(item => !_.isEmpty(item.offer))
                .map(mapProductItemRec) || [],
    };
};

export const mapProductReview = (review: ApiProductReview): ProductReview => ({
    name: review.name || "Анонимный",
    city: review.city,
    text: review.description,
    rating: Math.max(1, Math.min(5, +review.rating)),
    offerId: +review.offerId,
});

export const mapProductReviewsResponse = (
    data: ApiProductReviewsResponse
): ProductReviews => ({ ...data, items: data.items.map(mapProductReview) });

export const mapStore = (data: ApiStore): Store => ({
    id: data.ID,
    name: data.TITLE,
    sort: data.SORT,
    phone: data.PHONE,
    schedule: data.SCHEDULE || null,
    city: data.UF_LOCATION,
    fias_id: data.UF_LOCATION_FIAS_ID,
    address: data.ADDRESS,
    geo_lat: +data.GPS_N,
    geo_lon: +data.GPS_S,
    coordinates: [+data.GPS_N, +data.GPS_S],
    isOfflineStore: data.UF_OFFLINE_SHOP,
    isPickupPoint: data.ISSUING_CENTER,
});

export const mapSearchItem = (item: ApiSearchItem): SearchItem => ({
    id: +item.ID,
    slug: item.CODE,
    name: item.NAME,
    link: item.DETAIL_PAGE_URL,
    price:
        item.price.SALE_PRICE === null
            ? +item.price.MAIN_PRICE
            : +item.price.SALE_PRICE,
    priceOld: item.price.SALE_PRICE === null ? null : +item.price.MAIN_PRICE,
});

export const mapSearchResponse = (data: ApiSearchResponse): SearchResponse => ({
    items: data.result.map(mapSearchItem),
    count: data.count,
});
