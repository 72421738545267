import urlJoin from "url-join";

import { Locale } from "@Types/api";

export const SITE_DOMAIN = process.env.NEXT_PUBLIC_SITE_DOMAIN || "";
export const SITE_URL = `https://${SITE_DOMAIN}`;

export const isClient = typeof window !== "undefined";
export const isSSR = !isClient;

export const NEXT_RELEASE = false;

export const BACKEND_ENTRYPOINT = process.env.BACKEND_ENTRYPOINT || ""; // обязательно без NEXT_PUBLIC, здесь нужно разное значение либо на билде, либо в runtime https://nextjs.org/docs/pages/building-your-application/configuring/environment-variables#runtime-environment-variables

export const FRONTEND_ENTRYPOINT =
    process.env.NEXT_PUBLIC_FRONTEND_ENTRYPOINT || "";

export const API_URL = urlJoin(
    isClient ? FRONTEND_ENTRYPOINT : BACKEND_ENTRYPOINT,
    "/api"
);

export const GTAG_ID = process.env.NEXT_PUBLIC_GTAG_ID;
export const METRIKA_ID = process.env.NEXT_PUBLIC_YA_METRIKA_ID;
export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;
export const YMAPS_API_KEY = process.env.NEXT_PUBLIC_YMAPS_API_KEY;

export const CLOUDPAYMENTS_PK = process.env.NEXT_PUBLIC_CLOUDPAYMENTS_PK;

export const REVALIDATE_IN_SECONDS = 600;

export const BACKEND_DATE_FORMAT = "dd.MM.yyyy";
export const BACKEND_FULL_DATE_FORMAT = "dd.MM.yyyy HH:mm:ss";

export const SHOW_PAYMENT_IN_PARTS = false;

export default {
    defaultLocale: Locale.RU,
    keys: {
        AUTH: "AUTH_JWT",
    },
};
