import type {
    ApiOrder,
    ApiReviewsResponse,
    BonusResponse,
    FavouritesResponse,
    Result,
    UserEditableFields,
    UserPayload,
    UserResponse,
} from "@Types/api";

import _ from "lodash";
import { DateTime } from "luxon";

import { BACKEND_DATE_FORMAT } from "@Settings";
import * as mappers from "@Api/mappers";
import * as utils from "@Utils";
import { Repository } from "@Utils/api/repository";

type LoginPayload = {
    phone: string;
    sms_code?: string;
};

type SubscriptionPayload = {
    email: string;
};

type ResponseForToggleFavourites = Result<string> & {
    code: "add_success" | "remove_success";
};

type ResponseForGetFavouriteProducts = Result<FavouritesResponse>;

class UserApi extends Repository {
    getUser = async () => {
        const result: Result<UserResponse | null> =
            await this.client.request.get("/users/me");
        return mappers.mapUser(result.data);
    };

    getBonusBalance = async () => {
        const result: Result<BonusResponse> = await this.client.request.get(
            "/users/me/primehill"
        );

        return result?.data?.bonusBalance || 0;
    };

    updateUser = async (data: Partial<UserEditableFields>) => {
        const body: UserPayload = {
            EMAIL: data.email,
            NAME: data.name,
            LAST_NAME: data.lastName,
            SECOND_NAME: data.patronymic,
            PERSONAL_BIRTHDAY: !!data.birthday
                ? DateTime.fromJSDate(data.birthday).toFormat(
                      BACKEND_DATE_FORMAT
                  )
                : undefined,
            UF_ADDRESSES: data.addresses,
        };

        if (_.isBoolean(data.isSubscribedOnSMS)) {
            body.UF_SMS_SUBSCRIBE = utils.toBooleanSymbol(
                data.isSubscribedOnSMS
            );
        }

        if (!_.isEmpty(body)) {
            await this.client.request.put("/users/me", body);
        }
    };

    subscribeEmail = async (body: SubscriptionPayload) => {
        await this.client.request.post("/users/subscribe", body);
    };

    unsubscribeEmail = async (body: SubscriptionPayload) => {
        await this.client.request.delete("/users/subscribe", body);
    };

    login = async (payload: LoginPayload) => {
        const phoneOnlyNumbers = payload.phone.replace(/\D/g, "");
        const result: Result<string> = await this.client.request.post(
            "/auth/login",
            { ...payload, phone: phoneOnlyNumbers }
        );

        return result.data;
    };

    logout = async () => {
        await this.client.request.post("/auth/logout");
        return true;
    };

    getOrder = async (id: string) => {
        const result: Result<ApiOrder> = await this.client.request.get(
            `/order/${id}`
        );
        return result.data ? mappers.mapOrder(result.data) : null;
    };

    getOrders = async () => {
        const result: Result<ApiOrder[]> =
            await this.client.request.get("/users/me/orders");
        return result.data?.map(mappers.mapOrder) || [];
    };

    getFavouriteProducts = async () => {
        const result: ResponseForGetFavouriteProducts =
            await this.client.request.get(`/users/me/favourites`);
        return mappers.mapFavouritesResponse(result.data);
    };

    getViewedProducts = async () => {
        const result: ResponseForGetFavouriteProducts =
            await this.client.request.get(`/users/me/viewed`);
        return mappers.mapFavouritesResponse(result.data);
    };

    addToViewedProducts = async (productId: number) => {
        await this.client.request.post(`/users/me/viewed/${productId}`);
    };

    toggleFavouriteProduct = async (productId: string | number) => {
        const result: Result<ResponseForToggleFavourites> =
            await this.client.request.put(`/users/me/favourites/${productId}`);

        return result.data;
    };

    getReviews = async () => {
        const result: Result<ApiReviewsResponse> =
            await this.client.request.get(`/users/me/reviews`);

        return mappers.mapReviews(result.data ?? []);
    };
}

export default UserApi;
