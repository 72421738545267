import { useWindowSize } from "@react-hook/window-size";
import { useEffect } from "react";
import UAParser from "ua-parser-js";

const linuxOS: string[] = [
    "fedora",
    "debian",
    "gentoo",
    "linpus",
    "mageia",
    "mandriva",
    "meego",
    "mint",
    "pclinuxos",
    "redhat",
    "sailfish",
    "slackware",
    "suse",
    "tizen",
    "ubuntu",
    "vectorlinux",
];

let parserResult: UAParser.IResult | null = null;
let classesArray: string[] = [];

const addClass = (key: string, value: string): void => {
    value = value.toString().toLowerCase().replace(/[\s.]/g, "-");

    classesArray.push(`is-${key}-${value}`);
};

const useUAParser = () => {
    const windowSize = useWindowSize();

    useEffect(() => {
        parserResult = new UAParser().getResult();

        if (classesArray.length) {
            document.documentElement.classList.remove(...classesArray);

            classesArray = [];
        }

        if (parserResult.browser.name) {
            addClass("browser", parserResult.browser.name);
        }

        if (
            parserResult.os.name &&
            linuxOS.indexOf(parserResult.os.name.toLowerCase()) >= 0
        ) {
            addClass("os", "linux");
        }

        if (parserResult.os.name) {
            addClass("os", parserResult.os.name);
        }

        document.documentElement.classList.add(...classesArray);
    }, [windowSize]);
};

export default useUAParser;
