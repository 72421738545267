import { EventEmitter } from "events";
import _ from "lodash";
import { useEffect } from "react";

import { isSSR } from "@Settings";

import { AllEvents, Callback, EventList, Payload } from "./types";

export type Plugin = () => void;

class Events<T extends EventList> {
    private _emitter = new EventEmitter();

    emit<K extends keyof T>(eventName: K, ...payload: Payload<T, K>) {
        if (isSSR) {
            return false;
        }

        return this._emitter.emit(eventName as string, ...payload);
    }

    on<K extends keyof T>(eventName: K, cb: Callback<T, K>) {
        if (isSSR) {
            return this;
        }

        this._emitter.on(eventName as string, cb as (...params: any[]) => void);

        return this;
    }

    off<K extends keyof T>(eventName: K, cb: Callback<T, K>) {
        this._emitter.off(
            eventName as string,
            cb as (...params: any[]) => void
        );
    }
}

export const events = new Events<AllEvents>();

class EventsContainer {
    private readonly container = new Map<
        keyof AllEvents,
        Callback<AllEvents, keyof AllEvents>[]
    >();

    constructor(private readonly events: Events<AllEvents>) {}

    on(eventName: keyof AllEvents, cb: Callback<AllEvents, keyof AllEvents>) {
        if (!this.container.has(eventName)) {
            this.container.set(eventName, []);
        }

        const cbEventGroup = this.container.get(eventName);

        cbEventGroup?.push(cb);
        this.events.on(eventName, cb);

        return this;
    }

    release() {
        this.container.forEach((group, eventName) => {
            group.forEach(cb => {
                events.off(eventName, cb);
            });
        });
    }
}

type EventsCallback = (e: EventsContainer) => void;

export const useEvents = (cb: EventsCallback, deps: unknown[] = []) => {
    useEffect(() => {
        const e = new EventsContainer(events);
        cb(e);

        return () => {
            e.release();
        };
    }, [...deps]);
};
