import { isSSR } from "@Settings";

import { parseOrDefault } from "./common";

const checkIsUnavailable = () => {
    if (isSSR) {
        // console.warn("localStorage is not available!");
        return true;
    }

    return false;
};

const get = <T = any>(key: string, defaultValue?: T): T | undefined => {
    if (checkIsUnavailable()) {
        return defaultValue;
    }

    return parseOrDefault(window.localStorage.getItem(key), defaultValue);
};

const has = (key: string): boolean => {
    return Boolean(get(key, null));
};

const set = <T = any>(key: string, value: T): boolean => {
    if (checkIsUnavailable()) {
        return false;
    }

    window.localStorage.setItem(key, JSON.stringify(value));
    return true;
};

const reset = (key: string): boolean => {
    return set(key, null);
};

const getOrSetDefault = <T = any>(key: string, defaultValue?: T): T => {
    if (has(key)) {
        return get(key);
    }

    set(key, defaultValue);
    return get(key);
};

export const storage = {
    get,
    has,
    set,
    reset,
    getOrSetDefault,
};

export function createStorage<T extends object>(key: string) {
    return {
        get: () => get<T>(key),
        set: (value: T) => set(key, value),
    };
}
