import { createSlice } from "@reduxjs/toolkit";
import { DefaultRootState } from "react-redux";

const initialState = {};

const catalogSlice = createSlice({
    name: "app",
    initialState: initialState,
    reducers: {},
});

const { actions, reducer } = catalogSlice;

export const {} = actions;

export default reducer;
