import "@Assets/main.scss";
import "rc-tooltip/assets/bootstrap_white.css";
import "swiper/css/bundle";
import "react-datepicker/dist/react-datepicker.css";

import type { NextPageWithLayout } from "@Types/common";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import _ from "lodash";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import qs from "qs";
import React, { useEffect } from "react";
import Modal from "react-modal";
import { Provider } from "react-redux";
import urlJoin from "url-join";

import { isSSR } from "@Settings";
import MessageCookie from "@Components/MessageCookie";
import RouterProgressBar from "@Components/RouterProgressBar";
import Toasitfy from "@Components/Toastify";
import { events, initPlugins } from "@Events";
import useRouterPopstateTransition from "@Hooks/router-popstate-transition";
import useSetScrollbarWidth from "@Hooks/scrollbar-width";
import useUAParser from "@Hooks/ua-parser";
import useVUnits from "@Hooks/v-units";
import LayoutDefault from "@Layouts/default";
import store from "@Redux/store";
import { DataProvider } from "@Utils/data-provider";
import { GetStaticPropsAllProps } from "@Utils/datalayer";

declare global {
    interface Window {
        cp: {
            CloudPayments: any;
        };
        CDEKWidget: any;
        cdek: any;
        ym?: (...params: any[]) => void;
        dataLayer?: any[];
    }
}

type AppPropsWithLayout = AppProps<GetStaticPropsAllProps> & {
    Component: NextPageWithLayout;
};

if (isSSR) {
    React.useLayoutEffect = React.useEffect;
}

initPlugins();

const queryClientOptions = {
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    },
};

const queryClient = new QueryClient(queryClientOptions);

Modal.setAppElement("#__next");

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
    const router = useRouter();

    useRouterPopstateTransition();

    useEffect(() => {
        history.scrollRestoration = "manual";
    }, []);

    useEffect(() => {
        const url =
            urlJoin(
                router.asPath.split("?")[0],
                qs.stringify(
                    _.pick(router.query, [
                        "size",
                        "color",
                        "season",
                        "price",
                        "sort",
                        "show",
                    ]),
                    { addQueryPrefix: true }
                )
            ) || "/";

        events.emit("page_view", { url });
    }, [router.asPath]);

    useSetScrollbarWidth();
    useUAParser();
    useVUnits();

    const Layout = Component.Layout || LayoutDefault;

    return (
        <>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <DataProvider data={pageProps.data}>
                        <Layout {...pageProps}>
                            <RouterProgressBar />
                            <Component {...pageProps} />
                            <Toasitfy />
                        </Layout>

                        <MessageCookie />
                    </DataProvider>
                </QueryClientProvider>
            </Provider>
        </>
    );
}

export default MyApp;
