import Footer from "@Components/Footer";
import Header from "@Components/Header";
import PopupAuth from "@Components/PopupAuth";
import PopupFormResult from "@Components/PopupFormResult";
import PopupOneClick from "@Components/PopupOneClick";
import PopupPreorder from "@Components/PopupPreorder";
import PopupProduct from "@Components/PopupProduct";
import PopupSizes from "@Components/PopupSizes";
import PopupSplit from "@Components/PopupSplit";
import PopupVideo from "@Components/PopupVideo";
import Search from "@Components/Search";

export type LayoutProps = React.PropsWithChildren;

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <>
            <Header />
            {children}
            <Footer />
            <Search />
            <PopupAuth />
            <PopupOneClick />
            <PopupProduct />
            <PopupSplit />
            <PopupSizes />
            <PopupVideo />
            <PopupFormResult />
            <PopupPreorder />
        </>
    );
};

export default Layout;
