type IconProps = {
    className?: string;
};

const Icon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16 0.000244141L19.5922 11.056H31.2169L21.8123 17.8888L25.4046 28.9445L16 22.1117L6.59544 28.9445L10.1877 17.8888L0.783095 11.056H12.4078L16 0.000244141Z"
            fill="currentColor"
        />
    </svg>
);

export default Icon;
