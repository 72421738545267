import cns from "classnames";

import pages from "@Settings/pages";
import MessageEmpty from "@Components/MessageEmpty";
import Scrollbar from "@Components/Scrollbar";
import { SearchItem } from "@Types/api";
import { declOfNums } from "@Utils/helpers";

import Item from "./Item";
import styles from "./index.module.scss";

export type ResultProps = {
    className?: string;
    query?: string | null;
    items: SearchItem[];
    count: number;
    isLoading?: boolean;
};

const Result: React.FC<ResultProps> = ({
    className,
    query,
    items,
    count,
    isLoading,
}) => {
    return (
        <div className={cns(className, styles.component)}>
            {isLoading && (
                <div className={styles.component__loader}>
                    <div className="loader"></div>
                </div>
            )}

            <div className={styles.component__title}>
                {declOfNums(count, ["Найден", "Найдено", "Найдено"])} {count}{" "}
                {declOfNums(count, ["товар", "товара", "товаров"])}
            </div>

            <div className={styles.component__content}>
                {count ? (
                    <Scrollbar className={styles.component__list}>
                        {items.map((item, itemIndex) => (
                            <Item
                                key={itemIndex}
                                className={styles.component__item}
                                product={item}
                            />
                        ))}
                    </Scrollbar>
                ) : (
                    <MessageEmpty
                        className={styles.component__messageEmpty}
                        classNameText={styles.component__messageEmptyText}
                        classNameCaption={styles.component__messageEmptyCaption}
                        classNameButton={styles.component__messageEmptyButton}
                        text={`По запросу${
                            query ? ` «${query}»` : ""
                        } ничего не найдено`}
                        caption="Скорректируйте свой запрос или найдите  нужный товар в каталоге"
                        button={{
                            link: pages.catalog.link,
                            text: "Перейти в каталог",
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default Result;
