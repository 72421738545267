import { isSSR } from "@Settings";

import initMetrika from "./metrika";

const initializers = [initMetrika];

export const initPlugins = () => {
    if (isSSR) {
        return;
    }

    for (const init of initializers) {
        init();
    }
};

export * from "./core";
