import cns from "classnames";
import { LegacyRef, useEffect, useRef, useState } from "react";
import { useMeasure } from "react-use";

import Scrollbar from "@Components/Scrollbar";
import TextIcon from "@Components/ui/TextIcon";
import useWindowSize from "@Hooks/window-size";

import styles from "./index.module.scss";

export type TableSizesProps = {
    className?: string;
    table?: string;
    caption?: React.ReactNode;
};

type TableDataProps = {
    divider?: boolean;
    first?: boolean;
    text?: string;
};

const TableSizes: React.FC<TableSizesProps> = ({
    className,
    table,
    caption = "Все размеры указаны в см*",
}) => {
    const windowSize = useWindowSize();
    const [ref, { width: measureWidth }] = useMeasure();

    const [tableData, setTableData] = useState<TableDataProps[] | null>(null);
    const [tableColsCount, setTableColsCount] = useState<number>(0);
    const [tableHasScroll, setTableHasScroll] = useState<boolean>(false);

    const refComponent = useRef<HTMLDivElement>(null);
    const refDefaultTable = useRef<HTMLDivElement>(null);
    const refTable = useRef<HTMLDivElement>(null);

    const handleButtonScrollClick = () => {
        const $scrollContainer = refTable.current?.parentElement;

        $scrollContainer?.scrollTo({
            left: $scrollContainer?.scrollLeft + 100,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        if (!refDefaultTable.current) {
            return;
        }

        const arrayData: TableDataProps[] = [];

        refDefaultTable.current
            .querySelectorAll("table tr")
            .forEach((tr, trIndex) => {
                if (trIndex) {
                    arrayData.push({
                        divider: true,
                        first: trIndex === 1,
                    });
                }

                tr.querySelectorAll("td").forEach((td, tdIndex) => {
                    arrayData.push({
                        first: !tdIndex,
                        text: td.textContent?.trim() ?? "",
                    });
                });
            });

        setTableData(arrayData);
        setTableColsCount(
            refDefaultTable.current.querySelectorAll("tr:first-child td").length
        );
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (!refComponent.current || !refTable.current) {
                return;
            }

            setTableHasScroll(
                refComponent.current.clientWidth < refTable.current.clientWidth
            );
        }, 0);
    }, [tableData, windowSize.width, measureWidth]);

    useEffect(() => {
        if (!refTable.current) {
            return;
        }

        refTable.current.style.setProperty(
            "--cols-count",
            String(tableColsCount)
        );
    }, [tableColsCount]);

    if (!table) {
        return null;
    }

    return (
        <div ref={refComponent} className={cns(className, styles.component)}>
            <div ref={ref as LegacyRef<HTMLDivElement>}></div>

            {!tableData && (
                <div
                    ref={refDefaultTable}
                    className="isHidden"
                    dangerouslySetInnerHTML={{ __html: table }}
                ></div>
            )}

            {tableData && (
                <Scrollbar className={styles.component__scrollbar}>
                    <div
                        ref={refTable}
                        className={cns(
                            styles.component__table,
                            "text text_p3",
                            {
                                isSingle: tableColsCount === 2,
                            }
                        )}
                    >
                        {tableData.map((item, itemIndex) => (
                            <div
                                key={itemIndex}
                                className={cns({
                                    [styles.component__cell]: !item.divider,
                                    [styles.component__cell_first]:
                                        item.first && !item.divider,
                                    [styles.component__divider]: item.divider,
                                    [styles.component__divider_first]:
                                        item.first && item.divider,
                                })}
                            >
                                {item.text}
                            </div>
                        ))}
                    </div>
                </Scrollbar>
            )}

            {(caption || tableHasScroll) && (
                <footer
                    className={cns(styles.component__footer, "text text_p4")}
                >
                    {caption && (
                        <p className={styles.component__caption}>{caption}</p>
                    )}

                    {tableHasScroll && (
                        <button
                            className={styles.component__buttonScroll}
                            type="button"
                            onClick={handleButtonScrollClick}
                        >
                            <TextIcon
                                className={styles.component__prompt}
                                iconClassName={styles.component__promptIcon}
                                icon="angle-right"
                                isRight
                            >
                                Листайте вправо
                            </TextIcon>
                        </button>
                    )}
                </footer>
            )}
        </div>
    );
};

export default TableSizes;
