import pages from "@Settings/pages";
import { BreadcrumbsLinks } from "@Components/Breadcrumbs";
import { CategoriesList, Category, ContentMenuItem } from "@Types/api";

type ReturnedCategory = Category | null;

class Categories {
    private _listFlat: CategoriesList = {};

    constructor(private _list: CategoriesList = {}) {
        this.createFlatList();
        this.setPathProps();
    }

    get list() {
        return this._listFlat;
    }

    get tree() {
        return this._list;
    }

    getById = (
        id: string | number,
        list: CategoriesList = this.list
    ): ReturnedCategory => list[id] ?? null;

    getBySlug = (
        slug: string,
        list: CategoriesList = this.list
    ): ReturnedCategory =>
        Object.values(list).find(c => c.CODE === slug) ?? null;

    getParent(category: Category): ReturnedCategory {
        if (!category.PARENT_CATEGORY) {
            return null;
        }

        return this.getById(category.PARENT_CATEGORY);
    }

    getAllAncestors(category: Category): Category[] {
        if (category.PARENT_CATEGORIES?.length) {
            return category.PARENT_CATEGORIES.map(id =>
                this.getById(id)
            ).filter(c => c) as Category[];
        }

        const ancestors: Category[] = [];

        let parentCategory = this.getParent(category);

        while (parentCategory) {
            ancestors.push(parentCategory);

            parentCategory = this.getParent(parentCategory);
        }

        return ancestors;
    }

    getNestedChildBySlug(slug: string[]): ReturnedCategory {
        let level: number = 0;
        let category: ReturnedCategory = null;
        let categoryCurrent = this.getBySlug(slug[level], this.tree);

        while (categoryCurrent) {
            if (categoryCurrent.SUBSECTIONS && slug[level + 1]) {
                level++;

                categoryCurrent = this.getBySlug(
                    slug[level],
                    categoryCurrent.SUBSECTIONS
                );
            } else if (!slug[level + 1]) {
                category = categoryCurrent;
                categoryCurrent = null;
            } else {
                categoryCurrent = null;
            }
        }

        return category;
    }

    getBreadcrumbsLinks(
        category: Category,
        link: (slug: string) => string
    ): BreadcrumbsLinks {
        return [
            ...this.getAllAncestors(category).map(c => ({
                link: link(c.PATH),
                text: c.NAME,
            })),
        ];
    }

    getPopular = () =>
        Object.values(this.list).filter(c => c.UF_POPULAR === "1");

    getMenuItems(
        slug: string,
        linkAll?: boolean | string,
        linkAllNested?: boolean | string,
        linkAllNestedFromLevel?: number,
        level?: number,
        linkAllNestedExcludeLevels?: number[]
    ): ContentMenuItem[] | null {
        const category = this.getBySlug(slug);

        if (!category?.SUBSECTIONS) {
            return null;
        }

        const items = [];

        if (
            linkAllNested &&
            (typeof linkAllNestedFromLevel === "undefined" ||
                (typeof level !== "undefined" &&
                    level >= linkAllNestedFromLevel &&
                    !linkAllNestedExcludeLevels?.includes(level)))
        ) {
            items.push({
                id: +`${category.ID}` + Date.now(),
                code: `category-${category.CODE}-all`,
                link: pages.category.link(category.PATH),
                text:
                    typeof linkAllNested === "string"
                        ? linkAllNested
                        : "Смотреть все",
                sublinks: null,
            });
        }

        items.push(
            ...Object.values(category.SUBSECTIONS)
                .sort((c1, c2) => Number(c1.SORT) - Number(c2.SORT))
                .map(c => ({
                    id: +`${category.ID}${c.ID}`,
                    code: `category-${c.CODE}`,
                    link: pages.category.link(c.PATH),
                    text: c.NAME,
                    sublinks: c.SUBSECTIONS
                        ? this.getMenuItems(
                              c.CODE,
                              false,
                              linkAllNested,
                              linkAllNestedFromLevel,
                              typeof level !== "undefined"
                                  ? level + 1
                                  : undefined,
                              linkAllNestedExcludeLevels
                          )
                        : null,
                }))
        );

        if (linkAll) {
            items.push({
                id: +`${category.ID}` + Date.now(),
                code: `category-${category.CODE}-all`,
                link: pages.category.link(category.PATH),
                text: typeof linkAll === "string" ? linkAll : "Смотреть все",
                sublinks: null,
            });
        }

        return items;
    }

    setChildrenParentProps(category: Category) {
        if (!category.SUBSECTIONS) {
            return;
        }

        Object.values(category.SUBSECTIONS).forEach(subCategory => {
            const categoryParents = category.PARENT_CATEGORIES ?? [];

            subCategory.PARENT_CATEGORY = category.ID;

            if (subCategory.PARENT_CATEGORIES) {
                if (!subCategory.PARENT_CATEGORIES.includes(category.ID)) {
                    subCategory.PARENT_CATEGORIES.push(
                        ...categoryParents,
                        category.ID
                    );
                }
            } else {
                subCategory.PARENT_CATEGORIES = [
                    ...categoryParents,
                    category.ID,
                ];
            }
        });
    }

    setPathProps() {
        Object.values(this.list).forEach(category => {
            category.PATH = `${
                category.PARENT_CATEGORIES
                    ? `${this.getAllAncestors(category)
                          .map(c => c.CODE)
                          .join("/")}/`
                    : ""
            }${category.CODE}`;
        });
    }

    createFlatList(list = this.tree) {
        Object.values(list).forEach(category => {
            this.list[category.ID] = category;

            this.setChildrenParentProps(category);

            if (category.SUBSECTIONS) {
                this.createFlatList(category.SUBSECTIONS);
            }
        });
    }
}

export default Categories;
