type IconProps = {
    className?: string;
};

const Icon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.7142 19.2107C14.9746 19.2107 18.4284 15.7497 18.4284 11.4804C18.4284 7.211 14.9746 3.75 10.7142 3.75C6.45376 3.75 3 7.211 3 11.4804C3 15.7497 6.45376 19.2107 10.7142 19.2107Z"
            fill="transparent"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.0005 20.9275L16.7148 16.6328"
            fill="transparent"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Icon;
