import { useContext } from "react";

import { DataContext, StaticData } from "@Utils/datalayer";

export const useApiClient = () => {
    const value = useDataContext();

    return value.client;
};

export const useDataContext = <T = unknown>() => {
    const value = useContext(DataContext);

    if (!value) {
        throw new Error("value of data context is null");
    }

    return {
        client: value.client,
        data: value.data as StaticData<T>,
        store: value.store,
    };
};
