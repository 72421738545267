type IconProps = {
    className?: string;
};

const Icon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.85887 5C3.38453 5 3 5.33579 3 5.75C3 6.16421 3.38453 6.5 3.85887 6.5H19.8911C20.3655 6.5 20.75 6.16421 20.75 5.75C20.75 5.33579 20.3655 5 19.8911 5H3.85887Z"
            fill="currentColor"
        />
        <path
            d="M3.85887 11C3.38453 11 3 11.3358 3 11.75C3 12.1642 3.38453 12.5 3.85887 12.5H19.8911C20.3655 12.5 20.75 12.1642 20.75 11.75C20.75 11.3358 20.3655 11 19.8911 11H3.85887Z"
            fill="currentColor"
        />
        <path
            d="M3.85887 17C3.38453 17 3 17.3358 3 17.75C3 18.1642 3.38453 18.5 3.85887 18.5H19.8911C20.3655 18.5 20.75 18.1642 20.75 17.75C20.75 17.3358 20.3655 17 19.8911 17H3.85887Z"
            fill="currentColor"
        />
    </svg>
);

export default Icon;
