import cns from "classnames";
import React from "react";

import Link, { LinkProps } from "@Components/ui/Link";

import styles from "./index.module.scss";

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
    LinkProps & {
        size?: "md" | "sm";
        color?: "primary" | "light" | "tDark" | "tLight";
    };

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            size,
            color = "primary",
            href,
            blank,
            simple,
            phone,
            email,
            type = "button",
            disabled,
            title,
            onClick,
            children,
        },
        ref
    ) => {
        const classNames = cns(className, styles.component, {
            [styles[`component_${size}`]]: size,
            [styles[`component_${color}`]]: color,
        });

        let handleClick;

        if (onClick) {
            handleClick = onClick;
        }

        if (href || phone) {
            return (
                <Link
                    ref={ref}
                    className={classNames}
                    href={href}
                    blank={blank}
                    simple={simple}
                    phone={phone}
                    email={email}
                    title={title}
                >
                    {children}
                </Link>
            );
        }

        return (
            <button
                ref={ref}
                className={classNames}
                type={type}
                disabled={disabled}
                title={title}
                onClick={handleClick}
            >
                {children}
            </button>
        );
    }
);

export default Button;
