import type { Result } from "@Types/api";
import type { IncomingMessage } from "http";

import retry from "async-retry";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import _ from "lodash";
import qs from "qs";
import { toast } from "react-toastify";
import urlJoin from "url-join";

import { API_URL, isClient, isSSR } from "@Settings";
import Client from "@Utils/api/client";

const DEFAULT_ERROR_MESSAGE =
    "Что-то пошло не так. Попробуйте позже или перезагрузите страницу (F5).";

export class UserError extends Error {
    constructor(
        public readonly code: string,
        message?: string,
        opts?: ErrorOptions
    ) {
        super(message, opts);
    }
}

export class ApiRequest {
    public readonly req?: IncomingMessage;

    constructor(protected client: Client) {
        this.req = this.client.context.req; // req is always unexisted because GetStaticProps doesnt give req
    }

    private fetchData = async (
        apiEndpoint: string,
        queryParams = {},
        fetchParams: Partial<AxiosRequestConfig> = {}
    ) => {
        const fullUrl = urlJoin(
            API_URL,
            apiEndpoint,
            qs.stringify(_.defaults({}, queryParams), { addQueryPrefix: true })
        );

        try {
            const response = await axios({
                url: fullUrl,
                method: "GET",
                withCredentials: true,
                headers: this.getHeaders(this.req),
                ...fetchParams,
                timeout: 20000,
            });

            if (response.status === 404) {
                return null;
            }

            if (response.status !== 200) {
            }

            return response.data;
        } catch (err) {
            if (isSSR) {
                throw err;
            }

            if (!(err instanceof AxiosError) || !err.response) {
                toast(DEFAULT_ERROR_MESSAGE, {
                    position: "bottom-right",
                });
                throw err;
            }

            if (err.config?.method === "get") {
                return {
                    data: null,
                };
            }

            const { data, status } = err.response.data as Result<
                { message: string; code?: string } | string
            >;

            if (status === 404) {
                return null;
            }

            // todo: temp fix. remove asap
            if (err.response.data.suggestions) {
                return err.response.data;
            }

            if (_.isString(data)) {
                throw new Error(data);
            }

            const message = data?.message || DEFAULT_ERROR_MESSAGE;

            if (data?.code) {
                throw new UserError(data.code, message);
            }

            throw new Error(message);
        }
    };

    public get = async (apiEndpoint: string, queryParams = {}) => {
        if (isClient) {
            return await this.fetchData(apiEndpoint, queryParams);
        }

        try {
            return await retry(() => this.fetchData(apiEndpoint, queryParams), {
                retries: 5,
                onRetry: err => {
                    if (err instanceof AxiosError && err.response) {
                        const { data } = err.response.data as Result<
                            { message: string } | string
                        >;

                        console.log(
                            "retry",
                            apiEndpoint,
                            _.isString(data) ? data : data?.message
                        );
                        return;
                    }

                    console.log("retry", apiEndpoint, err);
                },
            });
        } catch (err) {
            return null;
        }
    };

    public post = async (apiEndpoint: string, data = {}, queryParams = {}) => {
        return await this.fetchData(apiEndpoint, queryParams, {
            method: "POST",
            data: JSON.stringify(data),
        });
    };

    public put = async (apiEndpoint: string, data = {}, queryParams = {}) => {
        return await this.fetchData(apiEndpoint, queryParams, {
            method: "PUT",
            data: JSON.stringify(data),
        });
    };

    public delete = async (
        apiEndpoint: string,
        data = {},
        queryParams = {}
    ) => {
        return await this.fetchData(apiEndpoint, queryParams, {
            method: "DELETE",
            data: JSON.stringify(data),
        });
    };

    private getHeaders(req?: IncomingMessage) {
        const result: Record<string, string> = {
            "Content-Type": "application/json",
            Accept: "application/json",
        };

        if (isSSR) {
            result["X-Forwarded-Proto"] = "https";
        }

        if (req?.headers.cookie) {
            result["cookie"] = req.headers.cookie;
        }

        return result;
    }
}

export default ApiRequest;
