import _ from "lodash";
import { useEffect, useState } from "react";

export enum Orientation {
    Portrait = 0,
    Landscape = 1,
}

export interface WindowSize {
    width?: number;
    height?: number;
    isDesktop?: boolean;
    isTablet?: boolean;
    isTabletOnly?: boolean;
    isMobile?: boolean;
    isPortrait?: boolean;
}

const getScreenOrientation = (width: number, height: number) =>
    width / height > 1 ? Orientation.Landscape : Orientation.Portrait;

const getWindowSize = (): WindowSize => {
    const isDesktop = window.innerWidth > 1024;
    const isTablet = window.innerWidth <= 1024;
    const isTabletOnly = window.innerWidth <= 1024 && window.innerWidth >= 768;
    const isMobile = window.innerWidth < 768;

    const screenOrientation = getScreenOrientation(
        window.innerWidth,
        window.innerHeight
    );

    return {
        width: window.innerWidth,
        height: window.innerHeight,
        isDesktop,
        isTablet,
        isTabletOnly,
        isMobile,
        isPortrait: screenOrientation === Orientation.Portrait,
    };
};

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
        isDesktop: undefined,
        isTablet: undefined,
        isTabletOnly: undefined,
        isMobile: undefined,
        isPortrait: undefined,
    });

    useEffect(() => {
        const handleResize = _.debounce(() => {
            setWindowSize(getWindowSize());
        }, 50);

        window.addEventListener("resize", handleResize, { passive: true });

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
};

export default useWindowSize;
