import cns from "classnames";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import TableSizes from "@Components/TableSizes";
import LinkContacts from "@Components/ui/LinkContacts";
import { useGetCommonDataSocials } from "@Hooks/api/common";
import { getExtra } from "@Redux/popup";

import { popupId } from "..";
import styles from "./index.module.scss";

export type TableProps = {
    className?: string;
};

const Table: React.FC<TableProps> = ({ className }) => {
    const dataSocials = useGetCommonDataSocials();

    const sizesTable = useSelector(getExtra(popupId))?.sizesTable;

    const contacts = useMemo(
        () => dataSocials.filter(item => item.showInSizeTable),
        [dataSocials]
    );

    if (!sizesTable) {
        return null;
    }

    return (
        <div className={cns(className, styles.component, "text text_p4")}>
            <TableSizes
                className={styles.component__table}
                table={sizesTable.table}
            />

            {!!contacts.length && (
                <>
                    <p className={styles.component__text}>
                        Не уверены в правильном выборе размера?
                        <br />
                        Напишите нам или позвоните, и мы вам поможем
                    </p>

                    <div className={styles.component__contacts}>
                        {contacts.map((contact, i) => (
                            <LinkContacts
                                key={i}
                                className={styles.component__contactsItem}
                                contact={contact}
                                withIcon
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default Table;
