import cns from "classnames";
import _ from "lodash";
import { useSelector } from "react-redux";

import Popup from "@Components/Popup";
import { close, getExtra, open } from "@Redux/popup";

import Form from "./Form";
import styles from "./index.module.scss";

export type PopupPreorderProps = Record<string, never>;

type PopupPreorderExtra = {
    offerId: number;
    productName: string;
};

const popupId = "preorder";

export const openPopupPreorder = (extra: PopupPreorderExtra) =>
    open({ id: popupId, extra });

export const closePopupPreorder = () => close(popupId);

const PopupPreorder: React.FC<PopupPreorderProps> = () => {
    const data = useSelector(getExtra<PopupPreorderExtra>(popupId));

    return (
        <Popup
            className={styles.component}
            classNameContainer={styles.component__container}
            id={popupId}
        >
            <h2
                className={cns(
                    styles.component__title,
                    "text text_h4 text_uppercase"
                )}
            >
                Предзаказ
            </h2>
            <h3
                className={cns(
                    styles.component__subtitle,
                    "text text_p4 text_regular"
                )}
            >
                {data?.productName}
            </h3>

            <div className={cns(styles.component__content, "text text_p4")}>
                Заполните контактные данные.
                <br />
                Мы свяжемся с вами, когда товар появится в наличии.
            </div>

            <Form offerId={data?.offerId ?? -1} />
        </Popup>
    );
};

export default PopupPreorder;
