import { SHOW_PAYMENT_IN_PARTS } from "@Settings";
import {
    ApiBrandVideosItem,
    ApiBrandVideosResponse,
    ApiContentBannersItem,
    ApiContentBannersResponse,
    ApiContentInfoPageResponse,
    ApiContentMenuItem,
    ApiContentMenuResponse,
    ApiContentPageItem,
    ApiContentSocialsItem,
    ApiContentSocialsResponse,
    ApiFaqItem,
    ApiFaqResponse,
    ApiHighlightsItem,
    ApiHighlightsResponse,
    ApiLookbookFullResponse,
    ApiLookbookItem,
    ApiLookbookResponse,
    ApiTeamItem,
    ApiTeamResponse,
    ApiTrustItem,
    ApiTrustResponse,
    BrandVideosItem,
    BrandVideosResponse,
    ContentBannersItem,
    ContentBannersResponse,
    ContentInfoPage,
    ContentMenuItem,
    ContentMenuResponse,
    ContentPageItem,
    ContentSocialsItem,
    ContentSocialsResponse,
    FaqItem,
    FaqResponse,
    HighlightItem,
    HighlightResponse,
    LookbookFullResponse,
    LookbookItem,
    LookbookResponse,
    TeamItem,
    TeamResponse,
    TrustItem,
    TrustResponse,
} from "@Types/api";

import { mapPagination, mapProductItem } from "./catalog";

const CODE_CATEGORY_PREFIX = "$category$";

export const mapPageHelpItem = (data: ContentPageItem): ContentPageItem => {
    const icons: { [key: string]: string[] } = {
        delivery: ["truck", "store", "device", "card"],
        payment: ["bankCard", "dolyami"],
        refund: ["hanger", "hangerBroken"],
    };

    let cardsCount = null;
    let cards = null;
    let items = null;

    if (data.slug === "delivery") {
        cardsCount = 4;
    } else if (data.slug === "payment") {
        cardsCount = 2;
    } else if (data.slug === "refund") {
        cardsCount = 2;
    } else if (data.slug === "size-guide") {
        let imagesIndex = 0;

        items = data.texts
            .reduce((acc: any, text: string, textIndex: number) => {
                const i: number = Math.floor(textIndex / 2);

                if (!acc[i]) {
                    acc[i] = [];
                }

                acc[i].push(text);

                return acc;
            }, [])
            .map((item: [string, string], itemIndex: number) => {
                let image = null;

                if (itemIndex === 0 || itemIndex === 2) {
                    image = data.images[imagesIndex];

                    imagesIndex++;
                }

                return {
                    title: item[0],
                    content: item[1],
                    image,
                };
            });

        data.texts = [];
    }

    if (data.texts.length && cardsCount) {
        cards = data.texts.slice(0, cardsCount).map((text, textIndex) => ({
            icon: icons[data.slug]?.[textIndex] ?? null,
            text,
        }));

        if (data.slug === "payment" && !SHOW_PAYMENT_IN_PARTS) {
            cards = cards.slice(0, 1); // todo: remove
        }

        data.texts = data.texts.slice(cardsCount);
    }

    return {
        ...data,
        cards,
        items,
    };
};

export const mapPageItem = (data: ApiContentPageItem): ContentPageItem => {
    const pageItemData: ContentPageItem = {
        id: +data.ID,
        slug: data.CODE,
        name: data.NAME,
        texts: data.TEXTS.map(p => p.TEXT),
        images: data.IMAGES.map(i => i.SRC),
        pageTitle: data.seo.PAGE_TITLE,
        seo: {
            title: data.seo.META_TITLE,
            keywords: data.seo.META_KEYWORDS || "",
            description: data.seo.META_DESCRIPTION || "",
        },
    };

    return mapPageHelpItem(pageItemData);
};

export const mapInfoPage = (
    data: ApiContentInfoPageResponse
): ContentInfoPage => ({
    text: data.DETAIL_TEXT,
    pageTitle: data.seo.PAGE_TITLE,
    seo: {
        title: data.seo.META_TITLE,
        keywords: data.seo.META_KEYWORDS || "",
        description: data.seo.META_DESCRIPTION || "",
    },
});

export const mapBannersItem = (
    item: ApiContentBannersItem
): ContentBannersItem => {
    const media: ContentBannersItem["media"] = [];

    let button = null;

    if (item.PROPERTY_VIDEO_LEFT_VALUE_URL) {
        media.push({
            type: "video",
            src: item.PROPERTY_VIDEO_LEFT_VALUE_URL as string,
        });
    } else {
        media.push({
            type: "image",
            src: item.PROPERTY_PICTURE_LEFT_VALUE_URL as string,
        });
    }

    if (item.PROPERTY_VIDEO_RIGHT_VALUE_URL) {
        media.push({
            type: "video",
            src: item.PROPERTY_VIDEO_RIGHT_VALUE_URL,
        });
    } else if (item.PROPERTY_PICTURE_RIGHT_VALUE_URL) {
        media.push({
            type: "image",
            src: item.PROPERTY_PICTURE_RIGHT_VALUE_URL,
        });
    }

    if (item.PROPERTY_CTA_LINK_VALUE && item.PROPERTY_CTA_TEXT_VALUE) {
        button = {
            link: item.PROPERTY_CTA_LINK_VALUE,
            text: item.PROPERTY_CTA_TEXT_VALUE,
        };
    }

    return {
        media,
        title: item.NAME,
        text: item.PREVIEW_TEXT ?? null,
        button,
    };
};

export const mapBannersResponse = (
    data: ApiContentBannersResponse
): ContentBannersResponse => data.map(mapBannersItem);

export const mapMenuItem = (item: ApiContentMenuItem): ContentMenuItem => {
    const sublinks = item.SUBSECTIONS?.map(mapMenuItem);

    return {
        id: +item.ID,
        code: item.CODE,
        categorySlug: item.CODE.startsWith(CODE_CATEGORY_PREFIX)
            ? item.CODE.replace(CODE_CATEGORY_PREFIX, "")
            : null,
        link: item.UF_LINK,
        text: item.NAME,
        sublinks: sublinks?.length ? sublinks : null,
    };
};

export const mapMenuResponse = (
    data: ApiContentMenuResponse
): ContentMenuResponse => data.map(mapMenuItem);

export const mapSocialsItem = (
    item: ApiContentSocialsItem
): ContentSocialsItem => ({
    id: +item.ID,
    name: item.NAME,
    icon: item.ICON.SRC,
    type: item.TYPE,
    value: item.VALUE,
    showInFooter: item.SHOW_FOOTER,
    showInSizeTable: item.SHOW_SIZE_TABLE,
    showInMenu: item.SHOW_MENU,
});

export const mapSocialsResponse = (
    data: ApiContentSocialsResponse
): ContentSocialsResponse => data.map(mapSocialsItem);

export const mapTrustItem = (item: ApiTrustItem): TrustItem => {
    let videoIndex = 0;

    const gallery = item.FILES.map(file => {
        const isVideo = file.CONTENT_TYPE.includes("video");

        if (isVideo) {
            videoIndex++;
        }

        return {
            image: isVideo ? item.VIDEO_PREVIEWS[videoIndex - 1].SRC : file.SRC,
            video: isVideo ? file.SRC : null,
        };
    });

    return {
        id: +item.ID,
        name: item.NAME,
        text: item.DETAIL_TEXT ?? null,
        link:
            item.CTA_LINK && item.CTA_TEXT
                ? {
                      href: item.CTA_LINK,
                      text: item.CTA_TEXT,
                  }
                : null,
        gallery,
    };
};

export const mapTrustResponse = (data: ApiTrustResponse): TrustResponse => ({
    items: data.items?.map(mapTrustItem) || [],
    pagination: mapPagination(data.nav),
});

export const mapBrandVideosItem = (
    item: ApiBrandVideosItem
): BrandVideosItem => ({
    id: +item.ID,
    image: item.PREVIEW_IMAGE.SRC,
    video: item.VIDEO.SRC,
});

export const mapBrandVideosResponse = (
    data: ApiBrandVideosResponse
): BrandVideosResponse => data.map(mapBrandVideosItem);

export const mapTeamItem = (item: ApiTeamItem): TeamItem => ({
    id: +item.ID,
    name: item.NAME,
    job: item.JOB ?? null,
    text: item.DETAIL_TEXT ?? null,
    quote: item.QUOTE ?? null,
    image: item.PHOTO.SRC,
});

export const mapTeamResponse = (data: ApiTeamResponse): TeamResponse =>
    data.map(mapTeamItem);

export const mapFaqItem = (item: ApiFaqItem): FaqItem => ({
    title: item.question,
    content: item.answer,
});

export const mapFaqResponse = (data: ApiFaqResponse): FaqResponse =>
    data.map(group => ({
        code: group.code,
        title: group.name,
        items: group.items.map(mapFaqItem),
    }));

export const mapHighlightsItem = (item: ApiHighlightsItem): HighlightItem => ({
    id: +item.ID,
    name: item.NAME,
    link:
        item.CTA_LINK && item.CTA_TEXT
            ? {
                  href: item.CTA_LINK,
                  text: item.CTA_TEXT,
              }
            : null,
    image: item.PREVIEW_PICTURE?.SRC ?? null,
    items: item.FILES.map(file => ({
        type: file.CONTENT_TYPE.includes("video") ? "video" : "image",
        src: file.SRC,
    })),
});

export const mapHighlightsResponse = (
    data: ApiHighlightsResponse
): HighlightResponse => data.map(mapHighlightsItem);

export const mapLookbookItem = (item: ApiLookbookItem): LookbookItem => {
    return {
        id: +item.ID,
        slug: item.CODE,
        name: item.NAME,
        image: item.PREVIEW_PICTURE.SRC,
    };
};

export const mapLookbookResponse = (
    data: ApiLookbookResponse
): LookbookResponse => ({
    items: data.items?.map(mapLookbookItem) || [],
    pagination: mapPagination(data.nav),
});

export const mapLookbookFullResponse = (
    data: ApiLookbookFullResponse
): LookbookFullResponse => ({
    id: +data.ID,
    name: data.NAME,
    images: data.IMAGES.map(image => image.SRC),
    products: data.OFFERS?.map(mapProductItem) || [],
    seo: {
        title: data.seo.META_TITLE,
        keywords: data.seo.META_KEYWORDS || "",
        description: data.seo.META_DESCRIPTION || "",
    },
});
