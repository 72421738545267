import cx from "classnames";
import _ from "lodash";
import NextImage, { ImageProps as NextImageProps } from "next/image";
import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";

// import { renderToStaticMarkup } from "react-dom/server";

import { isClient } from "@Settings";
import placeholderImg from "@Assets/images/placeholder.svg";

import css from "./index.module.scss";

// let loadingAttributePolyfill: any;

// if (isClient) {
//     loadingAttributePolyfill =
//         require("loading-attribute-polyfill/dist/loading-attribute-polyfill.module").default;
// }

export type ImageProps = Omit<NextImageProps, "src" | "placeholder"> & {
    classNameWrapper?: string;
    classNameLoader?: string;
    src?: NextImageProps["src"] | null;
    placeholder?: "default" | "white";
    size?: string;
    sizeTablet?: string;
    sizeMobile?: string;
    withLoader?: boolean;
};

const placeholderStyles: CSSProperties = {
    objectFit: "contain",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "50%",
    width: 100,
    height: 100,
};

// export const LoadingAttrPolyfill = (props: any) => {
//     const { children } = props;
//     const staticMarkup = renderToStaticMarkup(children);
//     const noScriptRef = useRef(null);

//     useEffect(() => {
//         console.log(noScriptRef.current);
//         if (noScriptRef.current && loadingAttributePolyfill) {
//             loadingAttributePolyfill.prepareElement(noScriptRef.current);
//         }
//     }, []);

//     return (
//         <noscript
//             ref={noScriptRef}
//             dangerouslySetInnerHTML={{ __html: staticMarkup }}
//         />
//     );
// };

const ImageWithLoader: React.FC<ImageProps> = ({
    classNameWrapper,
    classNameLoader,
    src,
    placeholder,
    ...props
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const memoImage = useMemo(
        () =>
            src ? (
                <NextImage
                    src={src}
                    onLoad={() => setIsLoading(false)}
                    onError={() => setIsLoading(false)}
                    {...props}
                />
            ) : null,
        [src]
    );

    if (!src) {
        return null;
    }

    return (
        <div className={cx(classNameWrapper, css.component)}>
            {isLoading && (
                <div className={cx(classNameLoader, css.component__loader)}>
                    <div className="loader"></div>
                </div>
            )}

            {memoImage}
        </div>
    );
};

const Image: React.FC<ImageProps> = ({
    classNameWrapper,
    classNameLoader,
    src,
    placeholder = "default",
    size,
    sizeMobile,
    sizeTablet,
    withLoader,
    ...props
}) => {
    const getSizes = () => {
        const sizes = [];

        if (sizeMobile) {
            sizes.push(`(max-width: 768px) ${sizeMobile}`);
        }

        if (sizeTablet) {
            sizes.push(`(max-width: 1024px) ${sizeTablet}`);
        }

        if (size) {
            sizes.push(size);
        }

        if (_.isEmpty(sizes)) {
            return;
        }

        return sizes.join(", ");
    };

    if (!src) {
        return (
            <div
                className={cx(
                    props.className,
                    css.emptyWrapper,
                    css[`_placeholder_${placeholder}`]
                )}
            >
                <NextImage
                    src={placeholderImg.src}
                    alt={props.alt}
                    width={100}
                    height={100}
                    style={placeholderStyles}
                />
            </div>
        );
    }

    const imageProps = {
        src,
        sizes: getSizes(),
        quality: 90,
        ...props,
    };

    if (withLoader) {
        return (
            <ImageWithLoader
                classNameWrapper={classNameWrapper}
                classNameLoader={classNameLoader}
                placeholder={placeholder}
                {...imageProps}
            />
        );
    }

    return <NextImage {...imageProps} />;
};

export const ImageWrapper: React.FC<
    React.PropsWithChildren<{ className?: string }>
> = ({ className, children }) => (
    <div className={cx(css.wrapper, className)}>{children}</div>
);

export default Image;
