import cns from "classnames";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { FreeMode, Keyboard, Mousewheel, Navigation } from "swiper/modules";
import { Swiper } from "swiper/types";

import pages from "@Settings/pages";
import Slider from "@Components/Slider";
import Image from "@Components/ui/Image";
import Link from "@Components/ui/Link";
import SvgIcon from "@Components/ui/SvgIcon";
import { useProductContext } from "@Pages/product/context";

import styles from "./index.module.scss";

export type ColorsProps = {
    className?: string;
};

const Colors: React.FC<ColorsProps> = ({ className }) => {
    const { product, colorId, setColorId } = useProductContext();

    const [swiper, setSwiper] = useState<Swiper | null>(null);

    const refArrowPrev = useRef<HTMLButtonElement>(null);
    const refArrowNext = useRef<HTMLButtonElement>(null);

    const colorsLength = Object.keys(product.colors).length;
    const colorName = product.colors[colorId as number]?.name;

    useEffect(() => {
        if (swiper) {
            swiper.update();
        }
    }, [swiper]);

    if (!colorsLength || (colorsLength === 1 && !colorName)) {
        return null;
    }

    const handleImageClick = (e: React.MouseEvent, colorIndex: number) => {
        e.preventDefault();

        setColorId(colorIndex);
    };

    return (
        <div className={cns(className, styles.component)}>
            <div className={styles.component__header}>
                <div className={cns(styles.component__color, "text text_p3")}>
                    <span>{colorName ? "Цвет: " : <pre></pre>}</span>
                    {colorName}
                </div>

                {Object.values(product.colors).length > 1 && (
                    <div className={styles.component__arrows}>
                        <button
                            ref={refArrowPrev}
                            className={cns(
                                styles.component__arrow,
                                styles.component__arrow_prev
                            )}
                            type="button"
                            aria-label="Предыдущий цвет"
                        >
                            <SvgIcon icon="angle-left" />
                        </button>

                        <button
                            ref={refArrowNext}
                            className={cns(
                                styles.component__arrow,
                                styles.component__arrow_next
                            )}
                            type="button"
                            aria-label="Следующий цвет"
                        >
                            <SvgIcon icon="angle-right" />
                        </button>
                    </div>
                )}
            </div>

            <Slider
                className={styles.component__slider}
                classNamesSlide={styles.component__slide}
                modules={[FreeMode, Keyboard, Mousewheel, Navigation]}
                slidesPerView={5}
                spaceBetween="12px"
                cssMode
                freeMode
                navigation={{
                    prevEl: refArrowPrev.current,
                    nextEl: refArrowNext.current,
                }}
                breakpoints={{
                    1025: {
                        slidesPerView: 6,
                        spaceBetween: "8px",
                        cssMode: false,
                    },
                    768: {
                        slidesPerView: 4,
                    },
                }}
                slides={_.sortBy(
                    Object.entries(product.colors),
                    ([i, color]) => color.sort
                ).map(([colorIndex, color]) => (
                    <Link
                        key={colorIndex}
                        className={cns(styles.component__image, {
                            isCurrent: +colorIndex === colorId,
                        })}
                        href={pages.product.link(
                            product.slug,
                            color.offersId[0]
                        )}
                        title={color.name}
                        simple
                        onClick={e => handleImageClick(e, +colorIndex)}
                    >
                        <Image
                            src={color.image}
                            alt=""
                            fill
                            size="5vw"
                            sizeTablet="16vw"
                        />
                    </Link>
                ))}
                onSwiper={setSwiper}
            />
        </div>
    );
};

export default Colors;
