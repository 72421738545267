type IconProps = {
    className?: string;
};

const Icon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="86"
        height="86"
        viewBox="0 0 86 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="43"
            cy="43"
            r="42"
            fill="transparent"
            stroke="currentColor"
            strokeWidth="2"
        />
        <path
            d="M62 39.5359C64.6667 41.0755 64.6667 44.9245 62 46.4641L36.5 61.1865C33.8333 62.7261 30.5 60.8016 30.5 57.7224L30.5 28.2776C30.5 25.1984 33.8333 23.2739 36.5 24.8135L62 39.5359Z"
            fill="currentColor"
        />
    </svg>
);

export default Icon;
