import cns from "classnames";
import _ from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateEffect } from "react-use";

import pages from "@Settings/pages";
import Popup from "@Components/Popup";
import { popupId as popupIdProduct } from "@Components/PopupProduct";
import Scrollbar from "@Components/Scrollbar";
import Link from "@Components/ui/Link";
import TextIcon from "@Components/ui/TextIcon";
import { useCart } from "@Hooks/cart";
import { close, getIsOpen, open } from "@Redux/popup";

import Form from "./Form";
import Product from "./Product";
import Sum from "./Sum";
import styles from "./index.module.scss";

export type PopupOneClickProps = Record<string, never>;

const POPUP_ID = "one-click";

export const openOneClickPopup = () => open({ id: POPUP_ID });

export const closeOneClickPopup = () => close(POPUP_ID);

const PopupOneClick: React.FC<PopupOneClickProps> = () => {
    const dispatch = useDispatch();

    const { data } = useCart();
    const isOpen = useSelector(getIsOpen(POPUP_ID));

    useEffect(() => {
        if (isOpen) {
            dispatch(close(popupIdProduct));
        }
    }, [isOpen]);

    useUpdateEffect(() => {
        if (_.isEmpty(data?.items)) {
            dispatch(closeOneClickPopup());
        }
    }, [data]);

    return (
        <Popup
            className={styles.component}
            classNameContainer={styles.component__container}
            id={POPUP_ID}
        >
            <h2
                className={cns(
                    styles.component__title,
                    "text text_h4 text_uppercase"
                )}
            >
                Покупка в 1 клик
            </h2>

            <Scrollbar className={styles.component__products}>
                {data?.items.map((item, i) => (
                    <Product
                        key={i}
                        className={styles.component__product}
                        itemId={item.ID}
                        image={item.imageUrl}
                        name={item.offer.name}
                        color={item.offer.color}
                        size={item.offer.size}
                        count={item.count}
                        price={item.price}
                    />
                ))}
            </Scrollbar>

            <Sum className={styles.component__sum} />

            <Form className={styles.component__form} />

            <Link className={styles.component__link} href={pages.cart.link}>
                <TextIcon
                    textClassName="link"
                    iconClassName={styles.component__linkIcon}
                    icon="angle-right-sm"
                    isRight
                >
                    Перейти в корзину
                </TextIcon>
            </Link>
        </Popup>
    );
};

export default PopupOneClick;
