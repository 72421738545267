import type { IncomingMessage } from "http";

import { Locale } from "@Types/api";

import ApiRequest from "./api-request";
import CartApi from "./repositories/cart";
import CatalogApi from "./repositories/catalog";
import CheckoutRepository from "./repositories/checkout";
import ContentApi from "./repositories/content";
import DadataRepository from "./repositories/dadata";
import FeedbackRepository from "./repositories/feedback";
import PagesRepository from "./repositories/pages";
import SettingsRepository from "./repositories/settings";
import UserApi from "./repositories/user";

export type ApiClientCtx = {
    req?: IncomingMessage;
    locale?: Locale;
};

export type ClientOptions = {
    messages: {
        default: string;
        tooOften: string;
        invalidPhone: string;
    };
};

export class Client {
    public readonly messages;
    public readonly request: ApiRequest;
    // repositories
    public readonly user: UserApi;
    public readonly catalog: CatalogApi;
    public readonly cart: CartApi;
    public readonly content: ContentApi;
    public readonly checkout: CheckoutRepository;
    public readonly dadata: DadataRepository;
    public readonly pages: PagesRepository;
    public readonly feedback: FeedbackRepository;
    public readonly settings: SettingsRepository;

    constructor(
        public context: ApiClientCtx = {},
        opts?: ClientOptions
    ) {
        this.messages = opts?.messages || {};
        this.request = new ApiRequest(this);

        this.user = new UserApi(this);
        this.catalog = new CatalogApi(this);
        this.cart = new CartApi(this);
        this.content = new ContentApi(this);
        this.pages = new PagesRepository(this);
        this.dadata = new DadataRepository(this);
        this.checkout = new CheckoutRepository(this);
        this.feedback = new FeedbackRepository(this);
        this.settings = new SettingsRepository(this);
    }
}

export const apiClient = new Client();

export default Client;
