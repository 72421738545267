import Cookies from "js-cookie";
import _ from "lodash";
import { useState } from "react";

const useCookie = <T extends object | string = any>(
    key: string,
    initialValue: T | (() => T) = null,
    options: Cookies.CookieAttributes = {}
) => {
    const [value, setInnerValue] = useState<T>(() => {
        if (Boolean(Cookies.get(key))) {
            return Cookies.get(key) as T;
        }

        const newValue = _.isFunction(initialValue)
            ? initialValue()
            : initialValue;

        Cookies.set(key, newValue, options);

        return newValue;
    });

    const setValue = (newValue: T) => {
        setInnerValue(newValue);
        Cookies.set(key, newValue, options);
    };

    return [value, setValue] as const;
};

export default useCookie;
