import * as Yup from "yup";

import {
    AddressFormFields,
    CDEKAddress,
    CheckoutFormFields,
    City,
    CourierDeliveryType,
    DadataSuggestion,
    DeliveryGroup,
    FastCheckoutFormFields,
    PickupDeliveryType,
    Store,
    SubscriptionFormFields,
    UserAddress,
} from "@Types/api";

const ERRORS = {
    NAME: {
        REQUIRED: "Имя обязательно",
        WRONG_FORMAT: "Неверный формат",
    },
    LAST_NAME: {
        REQUIRED: "Фамилия обязательна",
        WRONG_FORMAT: "Неверный формат",
    },
    PATRONYMIC: {
        WRONG_FORMAT: "Неверный формат",
    },
    EMAIL: {
        REQUIRED: "Email обязателен",
        WRONG_FORMAT: "Неверный формат",
    },
    PHONE: {
        REQUIRED: "Телефон обязателен",
        WRONG_FORMAT: "Неверный формат",
    },
    CITY: {
        REQUIRED: "Населеный пункт обязателен",
    },
    ADDRESS: {
        REQUIRED: "Адрес обязателен",
    },
};

const EMAIL_REG_EXP =
    /^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i;

export const FIELDS = {
    email: Yup.string()
        .matches(EMAIL_REG_EXP, ERRORS.EMAIL.WRONG_FORMAT)
        .required(ERRORS.EMAIL.REQUIRED),
    phone: Yup.string()
        .required(ERRORS.PHONE.REQUIRED)
        .min(11, ERRORS.PHONE.WRONG_FORMAT),
};

export const dadataObject = Yup.mixed<DadataSuggestion>();

export const cityObject = Yup.mixed<City>();

export const addressObject = Yup.mixed<UserAddress>();

export const cdekObject = Yup.mixed<CDEKAddress>();

export const FAST_CHECKOUT_SCHEMA: Yup.ObjectSchema<FastCheckoutFormFields> =
    Yup.object({
        name: Yup.string().required(ERRORS.NAME.REQUIRED),
        email: Yup.string()
            .email(ERRORS.EMAIL.WRONG_FORMAT)
            .required(ERRORS.EMAIL.REQUIRED),
        phone: Yup.string().required(ERRORS.PHONE.REQUIRED),
    });

export const CHECKOUT_SCHEMA: Yup.ObjectSchema<CheckoutFormFields> = Yup.object(
    {
        city: cityObject.required(ERRORS.CITY.REQUIRED),
        name: Yup.string().required(ERRORS.NAME.REQUIRED),
        lastName: Yup.string().required(ERRORS.LAST_NAME.REQUIRED),
        patronymic: Yup.string().optional(),
        userAddress: addressObject.optional().when("deliveryGroup", {
            is: "courier",
            then: schema => schema.required(ERRORS.ADDRESS.REQUIRED),
            otherwise: schema => schema.nullable(),
        }),
        cdekAddress: cdekObject
            .optional()
            .when(["deliveryGroup", "pickupDeliveryId"], {
                is: (group: DeliveryGroup, id: PickupDeliveryType) =>
                    group === "pickup" && id === PickupDeliveryType.CDEKPickup,
                then: schema => schema.required(ERRORS.ADDRESS.REQUIRED),
                otherwise: schema => schema.nullable(),
            }),
        pickupStore: Yup.object<Store>().optional(),
        email: Yup.string()
            .email(ERRORS.EMAIL.WRONG_FORMAT)
            .required(ERRORS.EMAIL.REQUIRED),
        phone: Yup.string().required(ERRORS.PHONE.REQUIRED),
        comments: Yup.string().optional(),
        deliveryGroup: Yup.string().oneOf(["courier", "pickup"]).required(),
        pickupDeliveryId: Yup.mixed<PickupDeliveryType>()
            .oneOf(Object.values(PickupDeliveryType) as PickupDeliveryType[])
            .required(),
        courierDeliveryId: Yup.mixed<CourierDeliveryType>()
            .oneOf(Object.values(CourierDeliveryType) as CourierDeliveryType[])
            .required(),
    }
);

export const ADDRESS_SCHEMA: Yup.ObjectSchema<AddressFormFields> = Yup.object({
    city: cityObject.required(ERRORS.CITY.REQUIRED),
    address: Yup.mixed<DadataSuggestion>()
        .test({
            message: "Дом не указан",
            test: val => {
                return !!val?.data.house;
            },
        })
        .required(ERRORS.ADDRESS.REQUIRED),
    apartment: Yup.string().optional(),
    intercom: Yup.string().optional(),
    entrance: Yup.string().optional(),
    floor: Yup.string().optional(),
});

export const SUBSCRIPTION_SCHEMA: Yup.ObjectSchema<SubscriptionFormFields> =
    Yup.object({
        email: Yup.string()
            .email(ERRORS.EMAIL.WRONG_FORMAT)
            .required(ERRORS.EMAIL.REQUIRED),
    });
