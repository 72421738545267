import cns from "classnames";
import { Formik, Form as FormikForm, useFormikContext } from "formik";
import { useEffect } from "react";

import pages from "@Settings/pages";
import { FormikInput } from "@Components/Formik";
import { usePopupContext } from "@Components/Popup";
import Button from "@Components/ui/Button";
import Link from "@Components/ui/Link";
import { useCheckoutSubmit } from "@Hooks/checkout";
import { useUser } from "@Hooks/user";
import { FastCheckoutFormFields, PaymentType } from "@Types/api";
import { FAST_CHECKOUT_SCHEMA } from "@Utils/schemas";

import styles from "./index.module.scss";

export type FormProps = {
    className?: string;
};

const FormContent = () => {
    const { setValues, isSubmitting } = useFormikContext();
    const { user } = useUser();

    useEffect(() => {
        if (!user?.data) {
            return;
        }

        const { name, email, phone } = user.data;

        void setValues(
            {
                name: name || "",
                email: email || "",
                phone: phone || "",
            },
            false
        );
    }, [user?.data, setValues]);

    return (
        <>
            <FormikInput
                className={styles.component__input}
                id="boc-name"
                name="name"
                placeholder="Имя*"
            />

            <FormikInput
                className={styles.component__input}
                type="tel"
                id="boc-phone"
                name="phone"
                placeholder="Номер телефона*"
            />

            <FormikInput
                className={styles.component__input}
                type="email"
                id="boc-email"
                name="email"
                placeholder="E-mail*"
            />

            <p className={styles.component__policyText}>
                Отправляя эту форму, вы соглашаетесь с условиями{" "}
                <Link href={pages.policy.link} simple blank>
                    Политики конфиденциальности
                </Link>
            </p>

            <Button
                className={styles.component__button}
                type="submit"
                disabled={isSubmitting}
            >
                Оплатить
            </Button>
        </>
    );
};

const Form: React.FC<FormProps> = ({ className }) => {
    const { close } = usePopupContext();
    const checkoutSubmit = useCheckoutSubmit();

    const handleSubmit = async (values: FastCheckoutFormFields) => {
        await checkoutSubmit({
            quick_order: "Y",
            paysystem_id: PaymentType.CloudPayments,
            ...values,
        });

        close();
    };

    return (
        <div className={cns(className, styles.component)}>
            <p className={cns(styles.component__text, "text text_p3")}>
                Заполните данные и с вами свяжется наш менеджер для
                подтверждения и уточнения заказа
            </p>

            <Formik
                initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                }}
                validationSchema={FAST_CHECKOUT_SCHEMA}
                onSubmit={handleSubmit}
            >
                <FormikForm className={styles.component__form}>
                    <FormContent />
                </FormikForm>
            </Formik>
        </div>
    );
};

export default Form;
