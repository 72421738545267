import cx from "classnames";
import { Form, Formik, FormikHelpers } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { FormikInput } from "@Components/Formik";
import { openFormResultPopup } from "@Components/PopupFormResult";
import ButtonIcon from "@Components/ui/ButtonIcon";
import { useUser } from "@Hooks/user";
import { SubscriptionFormFields } from "@Types/api";
import { SUBSCRIPTION_SCHEMA } from "@Utils/schemas";

import styles from "./index.module.scss";

type SubscriptionFormProps = {
    className?: string;
};

export const SubscriptionForm: React.FC<SubscriptionFormProps> = ({
    className,
}) => {
    const { subscribeEmail } = useUser();
    const dispatch = useDispatch();

    const handleSubmit = async (
        data: SubscriptionFormFields,
        { resetForm }: FormikHelpers<SubscriptionFormFields>
    ) => {
        try {
            await subscribeEmail(data);
            resetForm();
            dispatch(
                openFormResultPopup({
                    title: "Спасибо!",
                    text: "Вы успешно оформили подписку :)",
                })
            );
        } catch (err) {
            // todo: check err code
            toast("Ваш email уже подписан на рассылку.", {
                position: "bottom-right",
            });
        }
    };

    return (
        <Formik
            initialValues={{
                email: "",
            }}
            validationSchema={SUBSCRIPTION_SCHEMA}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form className={cx(className, styles.component)}>
                    <FormikInput
                        className={styles.component__input}
                        type="email"
                        id="fs-email"
                        name="email"
                        placeholder="E-mail"
                    />

                    <ButtonIcon
                        className={styles.component__button}
                        disabled={isSubmitting}
                        type="submit"
                        icon="angle-right-sm"
                        aria-label="Подписаться"
                        title="Подписаться"
                    />
                </Form>
            )}
        </Formik>
    );
};

export default SubscriptionForm;
