import type { FeedbackIdea } from "@Types/api";

import { Repository } from "@Utils/api/repository";

class FeedbackRepository extends Repository {
    addIdea = async (data: FeedbackIdea) => {
        await this.client.request.post("/feedback/ideas", data);
    };
}

export default FeedbackRepository;
