import { METRIKA_ID } from "@Settings";
import { events } from "@Events";

type Product = {
    id: string;
    name: string;
    price: number;
    variant?: string;
    quantity?: number;
};

const PURHASE_GOAL_ID = 32272530;

function sendEcommerceEvent(
    name: string,
    payload: { [key: string]: unknown; products: Product[] }
) {
    window.dataLayer?.push({
        ecommerce: {
            currencyCode: "RUB",
            [name]: payload,
        },
    });
}

const initMetrika = () => {
    if (!METRIKA_ID) {
        return;
    }

    events
        .on("order.payed", () => {
            window.ym?.(METRIKA_ID, "reachGoal", "order-payed");
        })
        .on("user.favourites.add_success", () => {
            window.ym?.(METRIKA_ID, "reachGoal", "add-to-favorites");
        })
        .on("page_view", ({ url }) => {
            window.ym?.(METRIKA_ID, "hit", url);
        })
        .on("product.viewed", ({ product }) => {
            sendEcommerceEvent("detail", {
                products: [
                    {
                        id: String(product.id),
                        name: product.slug,
                        price: product.default.price,
                    },
                ],
            });
        })
        .on("product.quickview.viewed", () => {
            window.ym?.(METRIKA_ID, "reachGoal", "quickview");
        })
        .on("cart.item.added", ({ item, quantity }) => {
            sendEcommerceEvent("add", {
                products: [
                    {
                        id: String(item.product.ID),
                        variant: String(item.offer.ID),
                        name: item.product.slug,
                        price: item.price,
                        quantity,
                    },
                ],
            });
            window.ym?.(METRIKA_ID, "reachGoal", "add-to-cart");
        })
        .on("cart.item.removed", ({ item, quantity }) => {
            sendEcommerceEvent("remove", {
                products: [
                    {
                        id: String(item.product.ID),
                        variant: String(item.offer.ID),
                        name: item.product.slug,
                        price: item.price,
                        quantity,
                    },
                ],
            });
        })
        .on("checkout.begin", () => {
            window.ym?.(METRIKA_ID, "reachGoal", "begin_checkout");
        })
        .on("order.created", ({ order }) => {
            sendEcommerceEvent("purchase", {
                actionField: {
                    id: order.id,
                    goal_id: PURHASE_GOAL_ID,
                },
                products: order.items.map(item => ({
                    id: String(item.product.ID),
                    variant: String(item.offer.ID),
                    name: item.product.slug,
                    price: item.price,
                })),
            });
        });
};

export default initMetrika;
