type IconProps = {
    className?: string;
};

const Icon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M38.5748 11.4781C39.1417 10.9112 39.1417 9.99206 38.5748 9.42517C38.0079 8.85828 37.0888 8.85828 36.5219 9.42517L24 21.9471L11.4781 9.42517C10.9112 8.85828 9.99206 8.85828 9.42517 9.42517C8.85828 9.99206 8.85828 10.9112 9.42517 11.4781L21.9471 24L9.42522 36.5219C8.85833 37.0888 8.85833 38.0079 9.42522 38.5748C9.99211 39.1417 10.9112 39.1417 11.4781 38.5748L24 26.0529L36.5219 38.5748C37.0888 39.1417 38.0079 39.1417 38.5748 38.5748C39.1417 38.0079 39.1417 37.0888 38.5748 36.5219L26.0529 24L38.5748 11.4781Z"
            fill="currentColor"
        />
    </svg>
);

export default Icon;
