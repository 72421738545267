import _ from "lodash";
import { useRouter } from "next/router";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { openPopupPreorder } from "@Components/PopupPreorder";
import { useProductCartMethods } from "@Hooks/cart";
import { useViewedProducts } from "@Hooks/products";
import {
    ProductFull,
    ProductFullOfferPrice,
    ProductFullShops,
    ProductFullSizes,
} from "@Types/api";

type ProductContextDefault = {
    product: ProductFull;
    offerId: number | null;
    setOfferId: (params: any) => void;
    colorId: number | null;
    setColorId: (params: any) => void;
    sizeId: number | null;
    setSizeId: (params: any) => void;
    price: ProductFullOfferPrice | null;
    labels: string[];
    availableSizes: ProductFullSizes;
    characteristics: ProductFull["characteristics"];
    shops: ProductFullShops;
    images: string[];
    addToCart: (from?: "one-click") => Promise<void>;
    isSizeError: boolean;
    setIsSizeError: (params: any) => void;
    hasMoreToAdd: boolean;
    isAvailable: boolean;
    isInCart: boolean;
    getOfferIdFromQuery: () => number | null;
};

type ProductProviderProps = React.PropsWithChildren & {
    product: ProductFull;
};

const ProductContext = createContext<ProductContextDefault>({
    product: {} as ProductFull,
    offerId: null,
    setOfferId: () => {},
    colorId: null,
    setColorId: () => {},
    sizeId: null,
    setSizeId: () => {},
    price: null,
    labels: [],
    availableSizes: {},
    characteristics: [],
    shops: {},
    images: [],
    addToCart: () => Promise.resolve(),
    isSizeError: false,
    setIsSizeError: () => {},
    hasMoreToAdd: false,
    isAvailable: false,
    isInCart: false,
    getOfferIdFromQuery: () => null,
});

const ProductProvider: React.FC<ProductProviderProps> = ({
    product,
    children,
}) => {
    const dispatch = useDispatch();
    const [, { useViewedProductsTrigger }] = useViewedProducts();
    useViewedProductsTrigger(product);

    const router = useRouter();

    const [offerId, setOfferId] = useState(product.default.offerId);
    const [colorId, setColorId] = useState(
        product.offers[product.default.offerId].colorId
    );
    const [sizeId, setSizeId] = useState<number | null>(null);
    const [isSizeError, setIsSizeError] = useState<boolean>(false);

    const cartMethods = useProductCartMethods(product.id, offerId);

    const selectedOffer = product.offers[offerId];

    const price = useMemo(() => selectedOffer.price, [offerId]);

    const labels = useMemo(() => {
        const list = [...product.labels];

        if (price.discount) {
            list.push(`-${price.discount}%`);
        }

        return list;
    }, [price]);

    const availableSizes = useMemo(
        () => product.colors[colorId].sizes,
        [colorId]
    );

    const characteristics = useMemo(() => {
        const list = [...product.characteristics];

        if (product.colors[colorId].name) {
            list.push({
                key: "Цвет",
                value: product.colors[colorId].name,
            });
        }

        if (sizeId !== null && availableSizes[sizeId]) {
            list.push({
                key: "Размер",
                value: availableSizes[sizeId].name,
            });
        }

        if (offerId !== null && selectedOffer?.characteristics?.length) {
            list.push(...selectedOffer.characteristics);
        }

        return list;
    }, [offerId, colorId, sizeId]);

    const shops = useMemo(() => product.colors[colorId].shops, [colorId]);

    const images = useMemo(() => selectedOffer.images ?? [], [offerId]);

    const isAvailable = selectedOffer?.isAvailable;
    const hasMoreToAdd = isAvailable && cartMethods.canAddMore;

    const getOfferIdFromQuery = useCallback(
        () =>
            router.query.offer && product.offers[+router.query.offer]
                ? +router.query.offer
                : null,
        [product, router.query.offer]
    );

    useEffect(() => {
        const offerIdFromQuery = getOfferIdFromQuery();
        const offerIdActual = offerIdFromQuery ?? product.default.offerId;

        setOfferId(offerIdActual);

        setColorId(product.offers[offerIdActual].colorId);

        setSizeId(null);
    }, [product]);

    useEffect(() => {
        const offerIdFromQuery = getOfferIdFromQuery();
        const colorCurrent = product.colors[colorId];

        if (
            offerIdFromQuery &&
            colorCurrent.offersId.includes(offerIdFromQuery)
        ) {
            setOfferId(offerIdFromQuery);
        } else if (colorCurrent.offersId.includes(offerId)) {
            setOfferId(offerId);
        } else {
            setOfferId(colorCurrent.offersId[0]);
        }

        if (Object.keys(availableSizes).length === 1) {
            setSizeId(+Object.keys(availableSizes)[0]);
        } else {
            setSizeId(null);
        }
    }, [colorId]);

    useEffect(() => {
        if (sizeId === null) {
            return;
        }

        setOfferId(availableSizes[sizeId].offerId);
    }, [sizeId]);

    const addToCart = async (from?: "one-click") => {
        if (Object.keys(availableSizes).length && sizeId === null) {
            toast("Выберите размер", {
                position: "bottom-right",
            });

            setIsSizeError(true);

            return;
        }

        setIsSizeError(false);

        if (!isAvailable) {
            dispatch(openPopupPreorder({ offerId, productName: product.name }));

            return;
        }

        try {
            await cartMethods.addToCart(offerId, { from });
        } catch (err) {
            if (err instanceof Error) {
                toast(err.message || "Ошибка добавления товара", {
                    position: "bottom-right",
                });
            }
        }
    };

    return (
        <ProductContext.Provider
            value={{
                product,
                offerId,
                setOfferId,
                colorId,
                setColorId,
                sizeId,
                setSizeId,
                price,
                labels,
                availableSizes,
                characteristics,
                shops,
                images,
                addToCart,
                isSizeError,
                setIsSizeError,
                isInCart: cartMethods.isInCart,
                hasMoreToAdd,
                isAvailable,
                getOfferIdFromQuery,
            }}
        >
            {children}
        </ProductContext.Provider>
    );
};

export const useProductContext = () => useContext(ProductContext);

export default ProductProvider;
