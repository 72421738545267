import cns from "classnames";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import styles from "./index.module.scss";

type AnimateFadeInProp = PropsWithChildren & {
    className?: string;
    type?: "fadeIn" | "fadeInTop" | "fadeInBottom";
    offset?: number;
    delay?: number;
    parent?: boolean;
    done?: boolean;
};

const AnimateFadeIn: React.FC<AnimateFadeInProp> = ({
    className,
    type = "fadeInBottom",
    offset = -50,
    delay,
    parent = false,
    done = false,
    children,
}) => {
    const [isDone, setIsDone] = useState(done);

    const { ref, inView, entry } = useInView({
        rootMargin: `${offset}px`,
        delay,
        triggerOnce: true,
    });

    const classNameProp = cns(
        className,
        styles.component,
        styles[`component_${type}`],
        {
            [styles.component_animated]: inView || isDone,
        }
    );

    const styleProp = {
        transitionDelay: delay ? `${delay}ms` : null,
    };

    useEffect(() => {
        if (entry?.target) {
            if (entry.target.getBoundingClientRect().left > innerWidth) {
                setIsDone(true);
            }
        }
    }, [entry]);

    return (
        <>
            {React.Children.map(children, child => {
                if (React.isValidElement(child) && !parent) {
                    return React.cloneElement(child, {
                        ref,
                        className: cns(classNameProp, child?.props?.className),
                        style: styleProp,
                    });
                }

                return (
                    <div ref={ref} className={classNameProp} style={styleProp}>
                        {child}
                    </div>
                );
            })}
        </>
    );
};

export default AnimateFadeIn;
