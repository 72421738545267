import { DateTime } from "luxon";

import { Offer, ProductTemp, Store } from "../catalog";
import { DadataSuggestion } from "../dadata";
import { UserAddress } from "../user";
import { DeliveryDetails, DeliveryGroup, PaymentDetails } from "./responses";

export enum PickupDeliveryType {
    OfflinePickup = "3",
    CDEKPickup = "69",
}

export enum CourierDeliveryType {
    Courier = "2",
}

export const DeliveryType = { ...CourierDeliveryType, ...PickupDeliveryType };
export type DeliveryType = PickupDeliveryType | CourierDeliveryType;

export enum PaymentType {
    CloudPayments = "12",
}

export type City = {
    value: string;
    data: {
        fias_id: string;
        geo_lat: string;
        geo_lon: string;
    };
};

export type CDEKAddress = {
    address: string;
    code: string;
    work_time: string;
    name: string;
};

export type OrderItem = {
    product: ProductTemp;
    offer: Offer;
    imageUrl: string;
    price: number;
    count: number;
};

export type CreatedOrder = {
    id: number;
    payment: PaymentDetails;
    items: OrderItem[];
    price: number;
};

export type OrderStatus = {
    id: string;
    name: string;
    sort: number;
};

export type Order = CreatedOrder & {
    createdAt: DateTime;
    delivery: DeliveryDetails;
    client: {
        email: string;
        fio: string;
        phone: string;
    };
    status: OrderStatus;
};

export type FastCheckoutFormFields = {
    name: string;
    email: string;
    phone: string;
};

export type CheckoutFormFields = {
    city?: City;
    name: string;
    lastName: string;
    patronymic?: string;
    deliveryGroup: DeliveryGroup;
    pickupDeliveryId: PickupDeliveryType;
    courierDeliveryId: CourierDeliveryType;
    userAddress?: UserAddress;
    cdekAddress?: CDEKAddress;
    pickupStore?: Partial<Store>;
    email: string;
    phone: string;
    comments?: string;
    bonus: number;
};

export type AddressFormFields = {
    city?: City;
    address?: DadataSuggestion;
    apartment?: string;
    intercom?: string;
    entrance?: string;
    floor?: string;
};
