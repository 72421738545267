import type { ApiSettings, Result } from "@Types/api";

import { Repository } from "@Utils/api/repository";

class SettingsRepository extends Repository {
    getAll = async () => {
        const result: Result<ApiSettings> =
            await this.client.request.get(`/settings`);

        return result?.data;
    };
}

export default SettingsRepository;
