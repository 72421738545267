import type { GetServerSidePropsContext, GetStaticPropsContext } from "next";

import { GetStaticPropsResult } from "next/types";
import React from "react";

import { Locale } from "@Types/api";
import Client from "@Utils/api/client";
import CatalogApi from "@Utils/api/repositories/catalog";
import ContentApi from "@Utils/api/repositories/content";
import SettingsRepository from "@Utils/api/repositories/settings";

export interface CommonData {
    categories: Awaited<ReturnType<typeof CatalogApi.prototype.getCategories>>;
    menuTop: Awaited<ReturnType<typeof ContentApi.prototype.getMenu>>;
    menuBottom: Awaited<ReturnType<typeof ContentApi.prototype.getMenu>>;
    menuBurger: Awaited<ReturnType<typeof ContentApi.prototype.getMenu>>;
    socials: Awaited<ReturnType<typeof ContentApi.prototype.getSocials>>;
    stores: Awaited<ReturnType<typeof CatalogApi.prototype.getStores>>;
    settings: Awaited<ReturnType<typeof SettingsRepository.prototype.getAll>>;
    burgerBanners: Awaited<ReturnType<typeof ContentApi.prototype.getBanners>>;
}

export type AllSpecificPageData<T = unknown> = T extends object
    ? T
    : {
          [key: string]: any;
      };

export interface StaticData<T = unknown> {
    common: CommonData;
    page: AllSpecificPageData<T>;
}

export interface GetStaticPropsAllProps {
    data: StaticData;
}

export interface UserGetStaticPropsAllProps {
    data: AllSpecificPageData;
    [key: string]: any;
}

type GetStaticPropsUserFnReturnType =
    GetStaticPropsResult<UserGetStaticPropsAllProps>;

export type GetStaticPropsUserFn = (
    ctx: GetStaticPropsContext & GetServerSidePropsContext,
    client: Client,
    commonData: CommonData
) => Promise<GetStaticPropsUserFnReturnType>;

export const withCommonProps =
    (fn: GetStaticPropsUserFn, opts?: any) =>
    async (ctx: GetStaticPropsContext & GetServerSidePropsContext) => {
        const client = new Client({ locale: ctx.locale as Locale });

        const commonParallelQueries = await Promise.all([
            client.catalog.getCategories(),
            client.content.getMenu("topmenu"),
            client.content.getMenu("bottommenu"),
            client.content.getMenu("burgermenu"),
            client.content.getSocials(),
            client.catalog.getStores(),
            client.settings.getAll(),
            client.content.getBanners("menu"),
        ]);

        const commonData: CommonData = {
            categories: commonParallelQueries[0],
            menuTop: commonParallelQueries[1],
            menuBottom: commonParallelQueries[2],
            menuBurger: commonParallelQueries[3],
            socials: commonParallelQueries[4],
            stores: commonParallelQueries[5],
            settings: commonParallelQueries[6],
            burgerBanners: commonParallelQueries[7],
        };

        const { props, ...rest } = await fn(ctx, client, commonData);

        let pageData = {},
            restProps = {};

        if (props) {
            const { data, ..._restProps } = props;

            pageData = data;
            restProps = _restProps;
        }

        return {
            props: {
                data: {
                    common: commonData,
                    page: pageData,
                },
                ...restProps,
            },
            ...rest,
        };
    };

interface DataContextValue {
    data: StaticData;
    client: Client;
    store: AllSpecificPageData;
}

export const DataContext = React.createContext<DataContextValue | null>(null);
