import cns from "classnames";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import React, { ForwardedRef } from "react";

import TextIcon from "@Components/ui/TextIcon";
import { normalizePhone, printPhone } from "@Utils/common";

import styles from "./index.module.scss";

export type LinkProps = React.HTMLAttributes<HTMLElement> &
    Omit<NextLinkProps, "href"> & {
        href?: string;
        blank?: boolean;
        simple?: boolean;
        phone?: string;
        phoneIcon?: boolean;
        phoneIconClassName?: string;
        email?: string;
        emailIcon?: boolean;
        emailIconClassName?: string;
    };

type SimpleLinkProps = React.HTMLAttributes<HTMLElement> & {
    href?: string;
    blank?: boolean;
};

const SimpleLink = React.forwardRef<HTMLElement, SimpleLinkProps>(
    ({ className, href, blank, children, ...props }, ref) => {
        return (
            <a
                ref={ref as ForwardedRef<HTMLAnchorElement>}
                className={cns(className, styles.link)}
                href={href}
                {...(blank && {
                    target: "_blank",
                    rel: "noopener noreferrer nofollow",
                })}
                {...props}
            >
                {children}
            </a>
        );
    }
);

const Link = React.forwardRef<HTMLElement, LinkProps>(
    (
        {
            className,
            href,
            blank = false,
            simple = false,
            phone,
            phoneIcon = false,
            phoneIconClassName,
            email,
            emailIcon = false,
            emailIconClassName,
            children,
            ...props
        },
        ref
    ) => {
        let linkHref = href;
        let linkChildren = children;

        if (simple) {
            if (phone) {
                linkHref = `tel:${normalizePhone(phone)}`;
                linkChildren = printPhone(normalizePhone(phone));

                if (phoneIcon) {
                    linkChildren = (
                        <TextIcon
                            iconClassName={phoneIconClassName}
                            icon="phone"
                        >
                            {linkChildren}
                        </TextIcon>
                    );
                }
            } else if (email) {
                linkHref = `mailto:${email}`;
                linkChildren = email;

                if (emailIcon) {
                    linkChildren = (
                        <TextIcon
                            iconClassName={emailIconClassName}
                            icon="email"
                        >
                            {linkChildren}
                        </TextIcon>
                    );
                }
            }
        }

        const commonLinkProps = {
            className: cns(className, {
                [styles.component_phone]: phone,
                [styles.component_email]: email,
            }),
            href: linkHref as string,
        };

        if (simple) {
            return (
                <SimpleLink
                    ref={ref}
                    {...commonLinkProps}
                    blank={blank}
                    {...props}
                >
                    {linkChildren}
                </SimpleLink>
            );
        }

        return (
            <NextLink
                ref={ref as ForwardedRef<HTMLAnchorElement>}
                {...commonLinkProps}
                passHref
                {...props}
            >
                {linkChildren}
            </NextLink>
        );
    }
);

export default Link;
