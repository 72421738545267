import cns from "classnames";

import Image from "@Components/ui/Image";
import Link from "@Components/ui/Link";

import styles from "./index.module.scss";

export type CardCategoryProps = {
    className?: string;
    link?: string;
    image?: string;
    text: string;
};

const CardCategory: React.FC<CardCategoryProps> = ({
    className,
    link,
    image,
    text,
}) => {
    return (
        <div className={cns(className, styles.component)}>
            <div className={styles.component__image}>
                <Image src={image} alt="" fill size="25vw" sizeTablet="75vw" />
            </div>

            <div
                className={cns(
                    styles.component__text,
                    "text text_h5 text_uppercase"
                )}
            >
                {text}
            </div>

            {link && (
                <Link
                    className={styles.component__link}
                    href={link}
                    aria-label={text}
                />
            )}
        </div>
    );
};

export default CardCategory;
