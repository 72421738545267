import React, { ReactNode, useMemo } from "react";

import Categories from "@Models/categories";
import Client from "@Utils/api/client";

import { DataContext, StaticData } from "./datalayer";

interface DataProviderProps {
    data: StaticData;
    children: ReactNode;
}

export const DataProvider: React.FC<DataProviderProps> = ({
    children,
    data,
}) => {
    const client = useMemo(() => new Client(), []);
    const categories = useMemo(
        () => new Categories(data?.common?.categories),
        []
    );

    return (
        <DataContext.Provider
            value={{
                client,
                data,
                store: {
                    categories,
                },
            }}
        >
            {children}
        </DataContext.Provider>
    );
};
