import { useField } from "formik";

import Checkbox, { CheckboxProps } from "@Components/ui/Checkbox";

type FormikCheckboxProps = CheckboxProps & {
    name: string;
};

export const FormikCheckbox: React.FC<FormikCheckboxProps> = ({
    name,
    children,
    ...props
}) => {
    const [field, meta] = useField(name);
    const error = meta.touched && meta.error ? meta.error : null;

    return (
        <Checkbox {...field} {...props} error={error}>
            {children}
        </Checkbox>
    );
};
