import cns from "classnames";
import { useSelector } from "react-redux";

import Popup from "@Components/Popup";
import Image from "@Components/ui/Image";
import Link from "@Components/ui/Link";
import { getExtra } from "@Redux/popup";
import { formatCurrency } from "@Utils/helpers";

import imageDolyami from "./images/dolyami.jpg";
import styles from "./index.module.scss";

export type PopupSplitProps = Record<string, never>;

export const popupId = "split";

const PopupSplit: React.FC<PopupSplitProps> = ({}) => {
    const price = useSelector(getExtra(popupId))?.price;
    const priceSplitted = price ? formatCurrency(price / 4) : 0;

    return (
        <Popup
            className={styles.component}
            classNameContainer={styles.component__container}
            id={popupId}
        >
            <div className={styles.component__image}>
                <Image
                    src={imageDolyami}
                    alt=""
                    fill
                    size="30vw"
                    sizeTablet="100vw"
                />
            </div>

            <div className={styles.component__content}>
                <h2
                    className={cns(
                        styles.component__title,
                        "text text_h4 text_uppercase"
                    )}
                >
                    Оплачивайте покупку по частям
                </h2>

                <p className={cns(styles.component__text, "text text_p5")}>
                    Получайте заказы сразу, а платите за них постепенно без
                    процентов и переплат
                </p>

                <div
                    className={cns(styles.component__parts, "text text_center")}
                >
                    <div className={styles.component__part}>
                        <div
                            className={cns(
                                styles.component__partPrice,
                                "text text_p3 text_uppercase"
                            )}
                        >
                            {priceSplitted}
                        </div>

                        <div className={styles.component__partCaption}>
                            сегодня
                        </div>
                    </div>

                    <div className={styles.component__part}>
                        <div
                            className={cns(
                                styles.component__partPrice,
                                "text text_p3 text_uppercase"
                            )}
                        >
                            {priceSplitted}
                        </div>

                        <div className={styles.component__partCaption}>
                            через 2 недели
                        </div>
                    </div>

                    <div className={styles.component__part}>
                        <div
                            className={cns(
                                styles.component__partPrice,
                                "text text_p3 text_uppercase"
                            )}
                        >
                            {priceSplitted}
                        </div>

                        <div className={styles.component__partCaption}>
                            через 4 недели
                        </div>
                    </div>

                    <div className={styles.component__part}>
                        <div
                            className={cns(
                                styles.component__partPrice,
                                "text text_p3 text_uppercase"
                            )}
                        >
                            {priceSplitted}
                        </div>

                        <div className={styles.component__partCaption}>
                            через 6 недели
                        </div>
                    </div>
                </div>

                <ul className={cns(styles.component__list, "text text_p5")}>
                    <li className={styles.component__listItem}>
                        Оплачивайте покупки частями — по 25% каждые две недели
                    </li>

                    <li className={styles.component__listItem}>
                        Никаких дополнительных платежей — как обычная оплата
                        картой
                    </li>
                </ul>

                <p className={styles.component__info}>
                    Подробную информацию о работе сервиса можно посмотреть на
                    сайте{" "}
                    <Link href="https://dolyame.ru" simple blank>
                        dolyame.ru
                    </Link>
                </p>
            </div>
        </Popup>
    );
};

export default PopupSplit;
