import cns from "classnames";
import {
    OverlayScrollbarsComponent,
    OverlayScrollbarsComponentRef,
} from "overlayscrollbars-react";

import "overlayscrollbars/overlayscrollbars.css";

import { forwardRef } from "react";

import styles from "./index.module.scss";

export type ScrollbarProps = React.PropsWithChildren & {
    className?: string;
    onEnd?: () => void;
};

const Scrollbar = forwardRef<OverlayScrollbarsComponentRef, ScrollbarProps>(
    ({ onEnd, className, children, ...props }, ref) => {
        return (
            <OverlayScrollbarsComponent
                ref={ref}
                className={cns(className, styles.component)}
                events={{
                    scroll: instance => {
                        const { viewport } = instance.elements();
                        const { scrollTop, clientHeight, scrollHeight } =
                            viewport;

                        if (scrollTop + clientHeight === scrollHeight) {
                            onEnd?.();
                        }
                    },
                }}
                {...props}
            >
                {children}
            </OverlayScrollbarsComponent>
        );
    }
);

export default Scrollbar;
