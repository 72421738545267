type IconProps = {
    className?: string;
};

const Icon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.8335 9.375C3.48832 9.375 3.2085 9.65482 3.2085 10C3.2085 10.3452 3.48832 10.625 3.8335 10.625H17.1668C17.512 10.625 17.7918 10.3452 17.7918 10C17.7918 9.65482 17.512 9.375 17.1668 9.375H3.8335Z"
            fill="currentColor"
        />
    </svg>
);

export default Icon;
