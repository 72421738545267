import cns from "classnames";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useKeyPressEvent } from "react-use";

import SvgIcon, { IconsList } from "@Components/ui/SvgIcon";
import useScrollLock from "@Hooks/scroll-lock";
import { close, create, getIsOpen } from "@Redux/popup";

import styles from "./index.module.scss";

export type PopupProps = React.PropsWithChildren & {
    className?: string;
    classNameOverlay?: string;
    classNameContainer?: string;
    classNameButtonClose?: string;
    id: string;
    closeIcon?: IconsList;
};

const PopupContext = React.createContext({
    close: () => {
        return;
    },
});

export const usePopupContext = () => useContext(PopupContext);

const Popup: React.FC<PopupProps> = ({
    className,
    classNameOverlay,
    classNameContainer,
    classNameButtonClose,
    id,
    closeIcon = "close-thin",
    children,
}) => {
    const refContainer = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();
    const isOpen = useSelector(getIsOpen(id));
    const router = useRouter();
    const scrollLock = useScrollLock(`popup-${id}`);

    const handleCloseClick = () => dispatch(close(id));

    useEffect(() => {
        dispatch(create(id));
    }, []);

    useEffect(() => {
        dispatch(close(id));
    }, [router.asPath]);

    useEffect(() => {
        if (isOpen) {
            scrollLock.lock();
        } else {
            scrollLock.unlock();
        }
    }, [isOpen]);

    useKeyPressEvent("Escape", () => dispatch(close(id)));

    if (!isOpen) {
        return null;
    }

    const handleOverlayClick = () => dispatch(close(id));

    return createPortal(
        <PopupContext.Provider
            value={{
                close: handleCloseClick,
            }}
        >
            <div className={cns(className, styles.component)}>
                <div
                    className={cns(classNameOverlay, styles.component__overlay)}
                    onClick={handleOverlayClick}
                ></div>

                <div
                    ref={refContainer}
                    className={cns(
                        classNameContainer,
                        styles.component__container
                    )}
                >
                    <button
                        className={cns(
                            classNameButtonClose,
                            styles.component__buttonClose
                        )}
                        type="button"
                        aria-label="Закрыть окно"
                        onClick={handleCloseClick}
                    >
                        <SvgIcon icon={closeIcon} />
                    </button>

                    {children}
                </div>
            </div>
        </PopupContext.Provider>,
        document.body
    );
};

export default Popup;
