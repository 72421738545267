import popup from "@Redux/popup";
import productPopupGallery from "@Redux/productPopupGallery";

import app from "./app";

export default function createRootReducer() {
    return {
        app,
        popup,
        productPopupGallery,
    };
}
