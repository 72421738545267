import cns from "classnames";
import React from "react";

import SvgIcon, { IconsList } from "@Components/ui/SvgIcon";

import styles from "./index.module.scss";

export type TextIconProps = React.HtmlHTMLAttributes<HTMLSpanElement> & {
    icon: IconsList;
    isRight?: boolean;
    iconClassName?: string;
    textClassName?: string;
};

const TextIcon: React.FC<TextIconProps> = ({
    className,
    icon,
    isRight,
    iconClassName,
    textClassName,
    children,
    ...props
}) => {
    return (
        <span
            className={cns(className, styles.component, {
                [styles["component--right"]]: isRight,
            })}
            {...props}
        >
            <SvgIcon
                icon={icon}
                className={cns(iconClassName, styles.component__icon)}
            />
            <span className={cns(textClassName, styles.component__text)}>
                {children}
            </span>
        </span>
    );
};

export default TextIcon;
