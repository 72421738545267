import cns from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";

import Container from "@Components/Container";
import Dropdown from "@Components/Dropdown";
import Link from "@Components/ui/Link";
import LinkContacts from "@Components/ui/LinkContacts";
import SvgIcon from "@Components/ui/SvgIcon";
import {
    useGetCommonDataMenuBurger,
    useGetCommonDataSocials,
} from "@Hooks/api/common";
import { useGetStoreCategories } from "@Hooks/api/store";
import useWindowSize from "@Hooks/window-size";
import { ContentMenuItem } from "@Types/api";

import styles from "./index.module.scss";

export type BurgerMenuMobileProps = {
    onClose?: () => void;
    onAuthClick?: () => void;
};

const getTabId = (id: string | number) => `tab-${id}`;

const getLinks = (links: ContentMenuItem[], level: number) => {
    const classNameLink = cns(styles.component__link, "text", {
        [styles[`component__link_lv${level}`]]: level,
        text_h5: level === 0,
        text_p3: level === 1,
        text_p5: level > 1,
        text_uppercase: level === 0,
    });

    return links.map(item => {
        if (item.sublinks?.length) {
            return (
                <Dropdown
                    key={item.code}
                    className={styles.component__dropdown}
                    classNameButton={classNameLink}
                    classNameButtonIcon={styles.component__dropdownIcon}
                    buttonText={item.text}
                    buttonIcon="angle-down-sm"
                    items={getLinks(item.sublinks, level + 1)}
                />
            );
        }

        return (
            <Link
                key={item.code}
                className={classNameLink}
                href={item.link ?? "#"}
            >
                {item.text}
            </Link>
        );
    });
};

const BurgerMenuMobile: React.FC<BurgerMenuMobileProps> = ({
    onClose,
    onAuthClick,
}) => {
    const dataMenuBurger = useGetCommonDataMenuBurger();
    const dataSocials = useGetCommonDataSocials();
    const storeCategories = useGetStoreCategories();

    const [tabs, setTabs] = useState<any>({});

    const windowSize = useWindowSize();

    const linksTop = useMemo(
        () =>
            [...dataMenuBurger.filter(item => item.categorySlug)].map(item => ({
                ...item,
                sublinks: storeCategories.getMenuItems(
                    item.categorySlug,
                    false,
                    true,
                    0,
                    0
                ),
            })),
        [dataMenuBurger]
    );

    const linksBody = useMemo(
        () => dataMenuBurger.filter(item => !item.categorySlug),
        [dataMenuBurger]
    );

    const socials = useMemo(
        () => dataSocials.filter(item => item.showInMenu),
        [dataSocials]
    );

    const setTabsState = useCallback(
        (id?: string | number) => {
            const tabsObject: any = {};

            linksTop.forEach((item, itemIndex) => {
                if (item.sublinks?.length) {
                    tabsObject[getTabId(itemIndex)] = id === itemIndex;
                }
            });

            setTabs(tabsObject);
        },
        [linksTop]
    );

    useEffect(() => {
        if (windowSize.isDesktop) {
            return;
        }

        setTabsState(0);
    }, [windowSize.isDesktop, setTabsState]);

    const handleTopLinkClick = (id: string | number) => {
        setTabsState(id);
    };

    return (
        <div className={styles.component}>
            <Container className={styles.component__container}>
                <button
                    className={styles.component__close}
                    type="button"
                    aria-label="Закрыть меню"
                    onClick={onClose}
                >
                    <SvgIcon icon="close-thin" />
                </button>

                {!!linksTop.length && (
                    <>
                        <div className={styles.component__top}>
                            {linksTop.map((item, itemIndex) =>
                                item.sublinks?.length ? (
                                    <button
                                        key={item.code}
                                        className={cns(
                                            styles.component__topLink,
                                            "text text_h5 text_uppercase",
                                            {
                                                isCurrent:
                                                    tabs[getTabId(itemIndex)],
                                            }
                                        )}
                                        type="button"
                                        onClick={() =>
                                            handleTopLinkClick(itemIndex)
                                        }
                                    >
                                        {item.text}
                                    </button>
                                ) : (
                                    <Link
                                        key={item.code}
                                        className={cns(
                                            styles.component__topLink,
                                            "text text_h5 text_uppercase"
                                        )}
                                        href={item.link ?? "#"}
                                    >
                                        {item.text}
                                    </Link>
                                )
                            )}
                        </div>

                        <div className={styles.component__tabs}>
                            {linksTop.map((item, itemIndex) =>
                                item.sublinks?.length ? (
                                    <div
                                        key={item.code}
                                        className={cns(styles.component__tab, {
                                            isHidden:
                                                !tabs[getTabId(itemIndex)],
                                        })}
                                    >
                                        {getLinks(item.sublinks, 0)}
                                    </div>
                                ) : null
                            )}
                        </div>
                    </>
                )}

                <div className={styles.component__nav}>
                    {!!linksBody.length && getLinks(linksBody, 0)}

                    <button
                        className={cns(
                            styles.component__link,
                            styles.component__link_lv0,
                            "text text_h5 text_uppercase"
                        )}
                        type="button"
                        onClick={onAuthClick}
                    >
                        Личный кабинет
                    </button>
                </div>

                {!!socials && (
                    <div
                        className={cns(
                            styles.component__socials,
                            "text text_h5"
                        )}
                    >
                        {socials.map((social, i) => (
                            <LinkContacts key={i} contact={social} />
                        ))}
                    </div>
                )}
            </Container>
        </div>
    );
};

export default BurgerMenuMobile;
