import type { UnPromisify } from "@Types/types";

import _ from "lodash";
import { useCallback } from "react";

type Callback = (...args: any[]) => any;
type AsyncCallback = (...args: any[]) => Promise<any>;

export const useDebounce = <T extends Callback>(
    cb: T,
    ms: number,
    deps: unknown[] = [],
    params?: _.DebounceSettings
) => {
    return useCallback(_.debounce(cb, ms, params), [...deps]);
};

export const useAsyncDebounce = <T extends AsyncCallback>(
    cb: T,
    ms: number,
    deps: unknown[] = []
) => {
    const debouncedFn = useDebounce(
        (resolve, reject, args) => {
            cb(...args)
                .then(resolve)
                .catch(reject);
        },
        ms,
        deps
    );

    return (...args: Parameters<T>): Promise<UnPromisify<ReturnType<T>>> =>
        new Promise((resolve, reject) => {
            debouncedFn(resolve, reject, args);
        });
};

export default useDebounce;
