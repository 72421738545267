type IconProps = {
    className?: string;
};

const Icon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20 6.582C17.04 6.285 14.0622 6.132 11.0933 6.132C9.33333 6.132 7.57333 6.222 5.81333 6.402L4 6.582M8.88889 5.673L9.08444 4.494C9.22667 3.639 9.33333 3 10.8356 3H13.1644C14.6667 3 14.7822 3.675 14.9156 4.503L15.1111 5.673M18.0889 9.426L17.5111 18.489C17.4133 19.902 17.3333 21 14.8533 21H9.14667C6.66667 21 6.58667 19.902 6.48889 18.489L5.91111 9.426"
            fill="transparent"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Icon;
