import cns from "classnames";
import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Keyboard, Mousewheel, Pagination } from "swiper/modules";
import { Swiper } from "swiper/types";

import Slider from "@Components/Slider";
import Image from "@Components/ui/Image";
import Video from "@Components/ui/Video";
import { useProductContext } from "@Pages/product/context";
import { isVideoSource } from "@Utils/helpers";

import styles from "./index.module.scss";

export type GalleryProps = {
    className?: string;
};

type ItemProps = {
    className?: string;
    src: string;
};

const Item: React.FC<ItemProps> = ({ className, src }) => {
    if (isVideoSource(src)) {
        return (
            <Video
                className={className}
                classNameWrapper={styles.component__video}
                src={src}
                loop
                muted
                playsInline
                preload="metadata"
                withPlayButton
            />
        );
    }

    return (
        <Image
            className={className}
            src={src}
            alt=""
            fill
            size="35vw"
            sizeTablet="95vw"
        />
    );
};

const Gallery: React.FC<GalleryProps> = ({ className }) => {
    const { images } = useProductContext();

    const [swiper, setSwiper] = useState<Swiper | null>(null);

    const refPagination = useRef<HTMLDivElement>(null);

    const sortedSlides = useMemo(() => {
        if (_.isEmpty(images)) {
            return [""];
        }

        const itemsImages: string[] = [];
        const itemsVideos: string[] = [];

        images.forEach(item => {
            if (isVideoSource(item)) {
                itemsVideos.push(item);
            } else {
                itemsImages.push(item);
            }
        });

        return [...itemsImages, ...itemsVideos];
    }, [images]);

    const handleSlideChange = () => {
        swiper?.slides?.forEach((slide, slideIndex) => {
            const $video = slide?.querySelector("video");

            if (
                $video &&
                !$video.paused &&
                slideIndex !== swiper?.activeIndex
            ) {
                $video.currentTime = 0;

                $video.pause();
            }
        });
    };

    useEffect(() => {
        if (swiper) {
            swiper.update();
        }
    }, [swiper]);

    return (
        <div className={cns(className, styles.component)}>
            <>
                <Slider
                    className={styles.component__slider}
                    classNamesSlide={styles.component__slide}
                    modules={[Keyboard, Mousewheel, Pagination]}
                    loop
                    pagination={{
                        el: refPagination.current,
                        clickable: true,
                    }}
                    slides={sortedSlides.map((item, itemIndex) => (
                        <Item
                            key={itemIndex}
                            className={styles.component__item}
                            src={item}
                        />
                    ))}
                    onSwiper={setSwiper}
                    onInit={handleSlideChange}
                    onSlideChange={handleSlideChange}
                />

                <div ref={refPagination}></div>
            </>
        </div>
    );
};

export default Gallery;
