import _ from "lodash";
import { DateTime } from "luxon";

import { BACKEND_FULL_DATE_FORMAT } from "@Settings";
import { City, DadataSuggestion, UserAddress } from "@Types/api";

export function parseOrDefault<T = any>(
    rawValue: string | null | undefined,
    defaultValue: any = {}
): T {
    if (_.isEmpty(rawValue)) {
        return defaultValue;
    }

    if (typeof rawValue !== "string") {
        return rawValue;
    }

    try {
        return JSON.parse(rawValue);
    } catch (err) {
        console.error(err);
        return defaultValue;
    }
}

export function truncateText(text: string, maxLength: number) {
    let tempText = text.substr(0, maxLength);

    tempText = tempText.substr(
        0,
        Math.min(text.length, tempText.lastIndexOf(" "))
    );

    if (text.length > maxLength) {
        tempText += "...";
    }

    return tempText;
}

export const getUriSlug = <T extends string = string>(
    slug?: string | string[]
): T | null => {
    if (!slug || _.isEmpty(slug)) {
        return null;
    }

    return encodeURIComponent(Array.isArray(slug) ? slug[0] : slug) as T;
};

export const formatTime = (time?: string) => {
    if (!time) {
        return null;
    }

    const [h, m] = time.split(":");

    return [h, m].join(":");
};

export const normalizePhone = (phone: string) => {
    return phone && String(phone).replaceAll(/[^0-9+]/g, "");
};

const normalizedPhoneRx = /(\+7|7|8)([0-9]{3})([0-9]{3})([0-9]{2})([0-9]{2})/;

export const printPhone = (phone: string | null) => {
    let m = phone?.match(normalizedPhoneRx);

    if (m?.length > 0) {
        return `${m[1]} ${m[2]} ${m[3]} ${m[4]} ${m[5]}`;
    }

    return phone;
};

export const printText = (text?: string) => text || "Missing text data...";

export const getFullNameFromParts = (
    name: string,
    lastName: string,
    patronymic?: string
) => {
    const parts = [lastName, name];

    if (patronymic) {
        parts.push(patronymic);
    }

    return parts.join(" ");
};

export const forwardedClick =
    (fn: (...args: any[]) => void = null, ...args) =>
    (e: React.SyntheticEvent) => {
        e.preventDefault();
        fn?.(...args);
    };

export const generateCity = ({ data }: DadataSuggestion): City => ({
    value: data.city_with_type || "",
    data: {
        fias_id: data.city_fias_id || "",
        geo_lat: data.geo_lat || "0",
        geo_lon: data.geo_lon || "0",
    },
});

export const getAddressSuggestion = ({ data }: DadataSuggestion) => {
    const parts = [data.street_with_type];

    data.house && parts.push(`${data.house_type} ${data.house}`);
    data.block && parts.push(`${data.block_type} ${data.block}`);

    return parts.join(", ");
};

export const getAddressFullValue = (address: UserAddress) => {
    if (!address) {
        return "Ошибка генерации адреса";
    }

    const { value, apartment, intercom, entrance, floor } = address;

    const parts = [value];

    apartment && parts.push(`кв. ${apartment}`);
    intercom && parts.push(`домофон ${intercom}`);
    entrance && parts.push(`подъезд ${entrance}`);
    floor && parts.push(`этаж ${floor}`);

    return parts.join(", ");
};

export const createDateTime = (datetime: string) =>
    DateTime.fromFormat(datetime, BACKEND_FULL_DATE_FORMAT);

const SIZES: Record<string, number> = {
    XXS: 42,
    XS: 44,
    S: 46,
    M: 48,
    L: 50,
    XL: 52,
    "2XL": 54,
    "3XL": 56,
    "4XL": 58,
    XXL: 54,
    XXXL: 56,
    XXXXL: 58,
};

const size2Number = (str: string) => {
    if (!str) {
        return 0;
    }

    if (SIZES[str]) {
        return 1000 + SIZES[str];
    }

    return +str || 0;
};

export const convertSizeToNumber = (size: string) => {
    if (!size) {
        return 0;
    }

    const [minSize, maxSize] = size.split("-");

    const minValue = size2Number(minSize);
    const maxValue = size2Number(maxSize);

    if (maxValue) {
        return (minValue + maxValue) / 2;
    }

    return minValue;
};
