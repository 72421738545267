import cns from "classnames";
import _ from "lodash";
import React, { useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import ru from "react-phone-input-2/lang/ru.json";

import SvgIcon, { IconsList } from "@Components/ui/SvgIcon";

import "react-phone-input-2/lib/style.css";

import styles from "./index.module.scss";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
    React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
        classNameInput?: string;
        label?: string;
        error?: string | null;
        success?: string | null;
        icon?: IconsList;
        isTextarea?: boolean;
    };

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            className,
            classNameInput,
            id,
            label,
            error,
            success,
            icon,
            isTextarea = false,
            ...props
        },
        ref
    ) => {
        const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);
        const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

        const info = error || success;

        const isPhone = props.type === "tel";

        const inputProps = {
            ref: inputRef,
            className: cns(classNameInput, styles.component__field, {
                [styles.component__field_textarea]: isTextarea,
                [styles.component__field_phone]: isPhone && !props.disabled,
            }),
            id: id,
            ...props,
        };

        React.useImperativeHandle<
            HTMLInputElement | null,
            HTMLInputElement | null
        >(ref, () => inputRef.current);

        if (isPhone) {
            delete inputProps.value;
        }

        return (
            <div
                className={cns(className, styles.component, {
                    isInfo: !!info,
                    isError: error,
                })}
            >
                <div className={styles.component__wrapper}>
                    {isPhone && (
                        <PhoneInput
                            disabled={inputProps.disabled}
                            localization={ru}
                            country="ru"
                            preferredCountries={["ru", "by", "kz"]}
                            enableSearch
                            disableSearchIcon
                            masks={{
                                ru: "... ...-..-..",
                            }}
                            searchPlaceholder="Страна или код"
                            searchNotFound="Ничего не найдено"
                            specialLabel={""}
                            value={props.value as string}
                            inputProps={{
                                ...inputProps,
                                "data-is-valid": isPhoneValid,
                            }}
                            isValid={(inputNumber, country: any) => {
                                setTimeout(() => {
                                    setIsPhoneValid(
                                        country.format
                                            .split("")
                                            .filter((s: string) => s === ".")
                                            .length === inputNumber.length
                                    );
                                }, 0);

                                return true;
                            }}
                            containerClass={styles.component__phone}
                            buttonClass={cns(
                                styles.component__phoneDropdownButton,
                                {
                                    isHidden: inputProps.disabled,
                                }
                            )}
                            dropdownClass={styles.component__phoneDropdown}
                            searchClass={styles.component__phoneDropdownSearch}
                        />
                    )}

                    {!isPhone && !isTextarea && <input {...inputProps} />}

                    {!isPhone && isTextarea && (
                        <textarea {...inputProps}></textarea>
                    )}

                    {icon && (
                        <SvgIcon
                            className={cns(
                                styles.component__icon,
                                styles[`component__icon_${icon}`]
                            )}
                            icon={icon}
                        />
                    )}
                </div>

                {!!info && (
                    <label
                        className={cns(styles.component__info, {
                            [styles._error]: !!error,
                            [styles._success]: !error && !!success,
                        })}
                        htmlFor={id}
                    >
                        {info}
                    </label>
                )}
            </div>
        );
    }
);

export default Input;
