import type {
    ApiContentInfoPageResponse,
    ApiContentPageItem,
    Result,
} from "@Types/api";

import * as mappers from "@Api/mappers";
import { Repository } from "@Utils/api/repository";

export type StaticPage =
    | "main"
    | "about-us"
    | "production"
    | "delivery"
    | "faq"
    | "offer"
    | "payment"
    | "refund"
    | "size-guide"
    | "feedback"
    | "trust"
    | "cart"
    | "checkout"
    | "contacts"
    | "favorites"
    | "error"
    | "profile"
    | "profile_orders"
    | "profile_bonuses"
    | "catalog"
    | "catalog_product"
    | "catalog_new"
    | "catalog_hit"
    | "catalog_sale"
    | "lookbook";

class PagesRepository extends Repository {
    getInfoPage = async (slug: string) => {
        const result: Result<ApiContentInfoPageResponse> =
            await this.client.request.get(`/page/info/${slug}`);

        return result?.data ? mappers.mapInfoPage(result.data) : null;
    };

    getPage = async (slug: StaticPage) => {
        const result: Result<ApiContentPageItem> =
            await this.client.request.get(`/page/${slug}`);

        return result?.data ? mappers.mapPageItem(result.data) : null;
    };
}

export default PagesRepository;
