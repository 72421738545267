type IconProps = {
    className?: string;
};

const Icon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.04286 3C3.91462 3 3 3.88395 3 4.97436C3 6.06477 3.91462 6.94872 5.04286 6.94872C6.1711 6.94872 7.08571 6.06477 7.08571 4.97436C7.08571 3.88395 6.1711 3 5.04286 3ZM4.11429 4.97436C4.11429 4.47872 4.53002 4.07692 5.04286 4.07692C5.55569 4.07692 5.97143 4.47872 5.97143 4.97436C5.97143 5.47 5.55569 5.87179 5.04286 5.87179C4.53002 5.87179 4.11429 5.47 4.11429 4.97436Z"
            fill="currentColor"
        />
        <path
            d="M9.5 4.4359C9.1923 4.4359 8.94286 4.67697 8.94286 4.97436C8.94286 5.27174 9.1923 5.51282 9.5 5.51282L15.4429 5.51282C15.7506 5.51282 16 5.27174 16 4.97436C16 4.67698 15.7506 4.4359 15.4429 4.4359L9.5 4.4359Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.9571 8.02564C12.8289 8.02564 11.9143 8.90959 11.9143 10C11.9143 11.0904 12.8289 11.9744 13.9571 11.9744C15.0854 11.9744 16 11.0904 16 10C16 8.90959 15.0854 8.02564 13.9571 8.02564ZM13.0286 10C13.0286 9.50436 13.4443 9.10256 13.9571 9.10256C14.47 9.10256 14.8857 9.50436 14.8857 10C14.8857 10.4956 14.47 10.8974 13.9571 10.8974C13.4443 10.8974 13.0286 10.4956 13.0286 10Z"
            fill="currentColor"
        />
        <path
            d="M3.55714 9.46154C3.24944 9.46154 3 9.70262 3 10C3 10.2974 3.24944 10.5385 3.55714 10.5385L9.5 10.5385C9.8077 10.5385 10.0571 10.2974 10.0571 10C10.0571 9.70262 9.8077 9.46154 9.5 9.46154L3.55714 9.46154Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.04286 13.0513C3.91462 13.0513 3 13.9352 3 15.0256C3 16.116 3.91462 17 5.04286 17C6.1711 17 7.08571 16.116 7.08571 15.0256C7.08571 13.9352 6.1711 13.0513 5.04286 13.0513ZM4.11429 15.0256C4.11429 14.53 4.53002 14.1282 5.04286 14.1282C5.55569 14.1282 5.97143 14.53 5.97143 15.0256C5.97143 15.5213 5.55569 15.9231 5.04286 15.9231C4.53002 15.9231 4.11429 15.5213 4.11429 15.0256Z"
            fill="currentColor"
        />
        <path
            d="M9.5 14.4872C9.1923 14.4872 8.94286 14.7283 8.94286 15.0256C8.94286 15.323 9.1923 15.5641 9.5 15.5641H15.4429C15.7506 15.5641 16 15.323 16 15.0256C16 14.7283 15.7506 14.4872 15.4429 14.4872H9.5Z"
            fill="currentColor"
        />
    </svg>
);

export default Icon;
