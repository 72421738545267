import { useDataContext } from "./client";

export const useGetCommonData = () => {
    const value = useDataContext();

    return value.data.common;
};

export const useGetCommonDataCategories = () => {
    const value = useDataContext();

    return value.data.common.categories;
};

export const useGetCommonDataMenuTop = () => {
    const value = useDataContext();

    return value.data.common.menuTop;
};

export const useGetCommonDataMenuBottom = () => {
    const value = useDataContext();

    return value.data.common.menuBottom;
};

export const useGetCommonDataMenuBurger = () => {
    const value = useDataContext();

    return value.data.common.menuBurger;
};

export const useGetCommonDataSocials = () => {
    const value = useDataContext();

    return value.data.common.socials;
};

export const useCommonDataOfflineShops = () => {
    const { stores } = useGetCommonData();
    return stores.filter(s => s.isOfflineStore);
};

export const useCommonDataSettings = () => {
    const { settings } = useGetCommonData();
    return settings;
};

export const useGetCommonDataBurgerBanners = () => {
    const value = useDataContext();

    return value.data.common.burgerBanners;
};
