import {
    Action,
    combineReducers,
    configureStore,
    getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
// import createSagaMiddleware from "redux-saga";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import createRootReducer from "./reducers";

// import rootSaga from "./sagas";

const IS_DEV = process.env.NODE_ENV !== "production";

// const sagaMiddleware = createSagaMiddleware();

const middlewares = [
    // sagaMiddleware,
    ...getDefaultMiddleware({
        immutableCheck: false,
        thunk: true,
        serializableCheck: {
            ignoredActions: ["popup/open"],
            ignoredPaths: ["popup"],
        },
    }),
];

// if (IS_DEV) {
//     let loggerMiddleware: Middleware = createLogger({
//         collapsed: (getState, action, logEntry) => !logEntry.error,
//     });
//     middlewares.push(loggerMiddleware);
// }

const combinedReducer = combineReducers({
    ...createRootReducer(),
});

// const rootReducer = (state: RootState, action: AnyAction): RootState => {
//     if (action.type === HYDRATE) {
//         return { ...state, ...action.payload };
//     }

//     return combinedReducer(state, action);
// };

type RootState = ReturnType<typeof combinedReducer>;
declare module "react-redux" {
    interface DefaultRootState extends RootState {}
}

const store = configureStore({
    reducer: combinedReducer,
    middleware: middlewares,
    devTools: IS_DEV,
});

// const makeStore: MakeStore<RootState> = (context: Context) =>
// configureStore({
//     reducer,
//     middleware: middlewares,
//     devTools: IS_DEV,
// });

// export const wrapper = createWrapper<RootState>(makeStore, { debug: false });

// sagaMiddleware.run(rootSaga);

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type AppThunkDispatch = ThunkDispatch<RootState, undefined, Action>;

export default store;
