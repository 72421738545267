import cns from "classnames";
import { InputHTMLAttributes } from "react";

import SvgIcon from "@Components/ui/SvgIcon";

import styles from "./index.module.scss";

export type CheckboxProps = Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "size"
> & {
    size?: "md" | "sm";
    className?: string;
    error?: string | null;
};

const Checkbox: React.FC<CheckboxProps> = ({
    size = "md",
    className,
    error,
    children,
    ...props
}) => {
    return (
        <label
            className={cns(
                className,
                styles.component,
                styles[`_size_${size}`],
                {
                    isDisabled: props.disabled,
                }
            )}
        >
            <input
                className={styles.component__input}
                type="checkbox"
                {...props}
            />

            <span className={styles.component__icon}>
                <SvgIcon icon="angle-down-sm" />
            </span>

            <span className={cns(styles.component__text, "text text_p4")}>
                {children}
            </span>

            {error && <span className={styles.component__error}>{error}</span>}
        </label>
    );
};

export default Checkbox;
