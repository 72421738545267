import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { useState } from "react";

import { events } from "@Events";
import { UserData, UserEditableFields } from "@Types/api";
import { createStorage } from "@Utils/local-storage";

import { useApiClient } from "./api/client";

const LOCAL_USER_QUERY = ["local_user"];
const USER_QUERY = ["user"];
const USER_ORDERS_QUERY = ["user.orders"];
const FAVOURITES_QUERY = ["user.favourites"];
const USER_REVIEWS_QUERY = ["user.reviews"];

const localUser = createStorage<Partial<UserData>>("user");

export const useLocalUser = () => {
    const { data, refetch: refetchUser } = useQuery({
        queryKey: LOCAL_USER_QUERY,
        queryFn: localUser.get,
        placeholderData: null,
    });

    const { mutate: updateLocalUser } = useMutation(
        (data: Partial<UserData>) =>
            Promise.resolve(
                localUser.set(_.merge(localUser.get() || {}, data))
            ),
        {
            onSuccess: () => {
                void refetchUser();
            },
        }
    );

    return {
        localUser: data,
        updateLocalUser,
    };
};

export const useUser = () => {
    const apiClient = useApiClient();

    const {
        data,
        isLoading,
        refetch: refetchUser,
    } = useQuery({
        queryKey: USER_QUERY,
        queryFn: apiClient.user.getUser,
        placeholderData: null,
    });

    const { mutateAsync: subscribeEmail } = useMutation({
        mutationFn: apiClient.user.subscribeEmail,
        onSuccess: () => refetchUser(),
    });
    const { mutateAsync: unsubscribeEmail } = useMutation({
        mutationFn: apiClient.user.unsubscribeEmail,
        onSuccess: () => refetchUser(),
    });
    const { mutateAsync: updateUser } = useMutation(apiClient.user.updateUser);
    const { mutateAsync: logout } = useMutation({
        mutationFn: apiClient.user.logout,
        onSuccess: () => refetchUser(),
    });

    return {
        user: data,
        isReady: !!data,
        isLoggedIn: Boolean(data?.data),
        isLoading,
        logout,
        subscribeEmail,
        updateUser: async (payload: Partial<UserEditableFields>) => {
            if (payload.email) {
                subscribeEmail({
                    email: payload.email,
                }).catch(() => {
                    console.log("subscription error", payload.email);
                });
            }

            await updateUser(payload);
            await refetchUser();

            events.emit("user.updated");
        },
    };
};

export const useUserOrders = () => {
    const apiClient = useApiClient();

    const {
        data: orders,
        isLoading,
        isFetched,
        refetch: refetchOrders,
    } = useQuery({
        queryKey: USER_ORDERS_QUERY,
        queryFn: apiClient.user.getOrders,
        placeholderData: [],
    });

    return { orders, isFetched, isLoading, refetchOrders };
};

export const useAuth = () => {
    const queryClient = useQueryClient();
    const apiClient = useApiClient();
    const [phone, setPhone] = useState<string | null>(null);

    const { mutate: mutateLogin, isLoading } = useMutation(
        apiClient.user.login
    );

    const { mutate: mutateLogout } = useMutation(apiClient.user.logout);

    const login = async (phone: string) => {
        return new Promise((resolve, reject) => {
            mutateLogin(
                { phone },
                {
                    onSuccess: () => {
                        setPhone(phone);
                        resolve(true);
                    },
                    onError: errorMessage => {
                        reject(errorMessage);
                    },
                }
            );
        });
    };

    const sendLoginConfirmation = async (sms: string, newPhone?: string) => {
        const finalPhone = newPhone || phone;

        if (!finalPhone) {
            throw new Error("Call login first before using sendConfirmation!");
        }

        return new Promise((resolve, reject) => {
            mutateLogin(
                { phone: finalPhone, sms_code: sms },
                {
                    onSuccess: async data => {
                        setPhone(null);
                        await queryClient.refetchQueries(USER_QUERY);
                        resolve(true);
                    },
                    onError: errorMessage => {
                        reject(errorMessage);
                    },
                }
            );
        });
    };

    return {
        phone,
        isLoading,
        logout: mutateLogout,
        login,
        sendLoginConfirmation,
    };
};

export const useFavouriteProducts = () => {
    const apiClient = useApiClient();

    const {
        data,
        isLoading,
        refetch: refetchFavourites,
    } = useQuery({
        queryKey: FAVOURITES_QUERY,
        queryFn: apiClient.user.getFavouriteProducts,
        placeholderData: [],
    });

    const { mutateAsync } = useMutation(apiClient.user.toggleFavouriteProduct);

    return {
        data,
        productsQuantity: Object.keys(data || {}).length,
        isLoading,
        toggleFavourites: async (productId: string | number) => {
            const result = await mutateAsync(productId);
            await refetchFavourites();
            events.emit(`user.favourites.${result.code}`);
        },
    };
};

/**
 * Используется в компоненте карточки товара/на странице товара
 * @param productId - ID товара
 */
export const useFavouriteProductMethods = (productId: string | number) => {
    const { data, toggleFavourites } = useFavouriteProducts();

    return {
        isFavourite: _.includes(
            (data || []).map(item => Number(item.id)),
            Number(productId)
        ),
        toggleFavourites: () => toggleFavourites(productId),
    };
};

export const useUserReviews = () => {
    const apiClient = useApiClient();

    const {
        data: reviews,
        isLoading,
        isFetched,
        refetch: refetchReviews,
    } = useQuery({
        queryKey: USER_REVIEWS_QUERY,
        queryFn: apiClient.user.getReviews,
        placeholderData: {},
    });

    return { reviews, isFetched, isLoading, refetchReviews };
};

export const useBonusBalance = () => {
    const apiClient = useApiClient();

    const { data, isLoading, isFetched, refetch } = useQuery({
        queryKey: ["bonus_balance"],
        queryFn: apiClient.user.getBonusBalance,
        placeholderData: 0,
    });

    return [data || 0, refetch] as const;
};
