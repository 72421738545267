import cns from "classnames";
import { useRouter } from "next/router";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useClickAway } from "react-use";

import pages from "@Settings/pages";
import { UserError } from "@Api/api-request";
import { openFormResultPopup } from "@Components/PopupFormResult";
import Button from "@Components/ui/Button";
import Input from "@Components/ui/Input";
import SvgIcon from "@Components/ui/SvgIcon";
import { useAuth } from "@Hooks/user";

import styles from "./index.module.scss";

export type AuthProps = {
    className?: string;
    onClose: () => void;
};

const ERROR_MESSAGES = {
    required: "Обязательное поле",
    phone: "Некорректный формат телефона",
    code: "Неверный код, попробуйте еще раз",
    formSend: "Ошибка отправки формы",
};

const CODE_LENGTH = 4;
const CODE_PREFIX = "code-";
const CODE_REGEXP = /[^0-9]/g;

const TIMER_SEC = 60;

const createCodeArray = () =>
    Array(CODE_LENGTH)
        .fill("")
        .map((_, index) => [`${CODE_PREFIX}${index}`, ""]);

const createCodeObject = () => Object.fromEntries(createCodeArray());

const UnderConstruction: React.FC = () => {
    return (
        <div className={styles.underConstruction}>
            <Button href="/c/dlya_nee">Перейти к покупкам</Button>
            <Button
                color="tDark"
                href="https://wa.me/+79000668888?text=%D0%9F%D1%80%D0%B8%D0%B2%D0%B5%D1%82!%20%F0%9F%91%8B%20%D0%AF%20%D0%BF%D0%BE%D0%BA%D1%83%D0%BF%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0%20Stylish2010"
                blank
                simple
            >
                Связаться в WhatsApp
            </Button>
        </div>
    );
};

const Auth: React.FC<AuthProps> = ({ className, onClose }) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const { login, sendLoginConfirmation, isLoading } = useAuth();

    const [inputPhoneValue, setInputPhoneValue] = useState<string>("");
    const [inputCodeValue, setInputCodeValue] = useState<{
        [key: string]: string;
    }>(createCodeObject());

    const [errorPhone, setErrorPhone] = useState<string | null>(null);
    const [errorCode, setErrorCode] = useState<string | null>(null);

    const [phone, setPhone] = useState<string | null>(null);
    const [code, setCode] = useState<string | null>(null);
    const [timer, setTimer] = useState<number>(0);
    const [isTooltip, setIsTooltip] = useState<boolean>(false);

    const refContainer = useRef<HTMLDivElement>(null);
    const refInputPhone = useRef<HTMLInputElement>(null);
    const refInputCode = useRef<{ [key: string]: HTMLInputElement | null }>(
        createCodeObject()
    );

    const codeArray = useMemo(createCodeArray, []);

    useEffect(() => {
        if (phone) {
            setTimer(TIMER_SEC);

            Object.values(refInputCode.current)[0]?.focus();
        }
    }, [phone]);

    useEffect(() => {
        setCode(Object.values(inputCodeValue).join(""));
    }, [inputCodeValue]);

    useEffect(() => {
        if (timer <= 0) {
            return;
        }

        const interval = setInterval(() => setTimer(prev => prev - 1), 1000);

        return () => clearInterval(interval);
    }, [timer]);

    useClickAway(refContainer, onClose);

    const handleChangeInputPhone = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setInputPhoneValue(e.target.value);
    };

    const handleChangeInputCode = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        id?: string
    ) => {
        setInputCodeValue(prev => ({
            ...prev,
            [e.target.name]: e.target.value.replace(CODE_REGEXP, "").charAt(0),
        }));

        if (
            e.target.value &&
            !CODE_REGEXP.test(e.target.value) &&
            id &&
            !refInputCode.current[id]?.value
        ) {
            refInputCode.current[id]?.focus();
        }
    };

    const handleKeydownInputCode = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
        id?: string
    ) => {
        if (e.key === "Backspace" && !e.currentTarget.value && id) {
            refInputCode.current[id]?.focus();
        }
    };

    const handlePasteInputCode = (
        e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const cbData = e.clipboardData.getData("text");

        if (!cbData || isNaN(+cbData)) {
            return;
        }

        const cbArray = cbData
            .replace(CODE_REGEXP, "")
            .split("")
            .splice(0, CODE_LENGTH);

        setInputCodeValue(prev =>
            Object.fromEntries(
                Object.keys(prev).map((key, index) => [
                    key,
                    cbArray[index] ?? "",
                ])
            )
        );

        refInputCode.current[`${CODE_PREFIX}${cbArray.length - 1}`]?.focus();
    };

    const handleClickButtonResend = async () => {
        if (!phone) {
            return;
        }

        setTimer(TIMER_SEC);

        // TODO: test auth flow
        try {
            await login(phone);
        } catch (err) {
            if (err instanceof Error) {
                // todo: handle server timer
                console.log(err.message);
            }
        }
    };

    const handleClickButtonNot = () => setIsTooltip(prev => !prev);
    const handleClickTooltipButtonClose = () => setIsTooltip(false);

    const handleSubmitCode = async () => {
        const phone = refInputPhone.current?.value;

        if (!phone?.length) {
            setErrorPhone(ERROR_MESSAGES.required);

            return;
        }

        if (
            refInputPhone.current?.dataset.isValid &&
            !JSON.parse(refInputPhone.current?.dataset.isValid)
        ) {
            setErrorPhone(ERROR_MESSAGES.phone);

            return;
        }

        // TODO: test auth flow
        try {
            await login(phone);
            setPhone(phone);
        } catch (err) {
            if (err instanceof UserError && err.code === "send_sms_error") {
                onClose();
                dispatch(
                    openFormResultPopup({
                        title: "Личный кабинет находится в разработке",
                        text: "Вы можете совершать покупки, не входя в личный кабинет, либо напишите нам на WhatsApp.",
                        actions: <UnderConstruction />,
                    })
                );
                return;
            }

            if (err instanceof Error) {
                setErrorPhone(err.message);
            }
        }
    };

    const handleSubmitLogIn = async () => {
        if (!code?.length) {
            setErrorCode(ERROR_MESSAGES.required);

            return;
        }

        if (code?.length !== CODE_LENGTH) {
            setErrorCode(ERROR_MESSAGES.code);

            return;
        }

        // TODO: test auth flow
        try {
            setErrorCode(null);
            await sendLoginConfirmation(code);
            onClose();
            void router.push(pages.profile.link);
        } catch (err) {
            if (err instanceof Error) {
                setErrorCode(err.message);
                setInputCodeValue(createCodeObject());

                setTimeout(() => {
                    Object.values(refInputCode.current)[0]?.focus();
                }, 0);
            }
        }
    };

    useEffect(() => {
        if (code?.length === CODE_LENGTH && !isLoading) {
            handleSubmitLogIn();
        }
    }, [code]);

    return (
        <div
            className={cns(className, styles.component)}
            onTouchStart={e => e.stopPropagation()}
        >
            <div ref={refContainer} className={styles.component__container}>
                <button
                    className={styles.component__buttonClose}
                    type="button"
                    aria-label="Закрыть окно авторизации"
                    onClick={onClose}
                >
                    <SvgIcon icon="close-thin" />
                </button>

                {!phone && (
                    <div className={styles.component__step}>
                        <h3
                            className={cns(
                                styles.component__title,
                                "text text_h5 text_uppercase"
                            )}
                        >
                            Авторизация
                        </h3>

                        <p
                            className={cns(
                                styles.component__text,
                                "text text_p4"
                            )}
                        >
                            Укажите пожалуйста ваш номер телефона для&nbsp;входа
                            или регистрации
                        </p>

                        <Input
                            ref={refInputPhone}
                            className={styles.component__input}
                            type="tel"
                            name="phone"
                            placeholder="Номер телефона*"
                            value={inputPhoneValue}
                            onChange={handleChangeInputPhone}
                            error={errorPhone}
                        />

                        <Button
                            className={styles.component__button}
                            size="md"
                            onClick={handleSubmitCode}
                            disabled={isLoading}
                        >
                            Отправить код
                        </Button>
                    </div>
                )}

                {phone && (
                    <div className={styles.component__step}>
                        <h3
                            className={cns(
                                styles.component__title,
                                "text text_h5 text_uppercase"
                            )}
                        >
                            Введите код из смс
                        </h3>

                        <p
                            className={cns(
                                styles.component__text,
                                "text text_p4"
                            )}
                        >
                            Код отправлен в СМС на номер: <br />
                            {phone}
                        </p>

                        <div
                            className={cns(
                                styles.component__caption,
                                "text text_uppercase"
                            )}
                        >
                            Код из смс
                        </div>

                        <div className={styles.component__inputCode}>
                            {codeArray.map(([codeId], index) => (
                                <Input
                                    key={codeId}
                                    ref={el =>
                                        (refInputCode.current[codeId] = el)
                                    }
                                    className={styles.component__input}
                                    name={codeId}
                                    maxLength={1}
                                    autoComplete="off"
                                    inputMode="numeric"
                                    value={inputCodeValue[codeId]}
                                    onChange={e =>
                                        handleChangeInputCode(
                                            e,
                                            codeArray[index + 1]
                                                ? codeArray[index + 1][0]
                                                : undefined
                                        )
                                    }
                                    onKeyDown={e =>
                                        handleKeydownInputCode(
                                            e,
                                            codeArray[index - 1]
                                                ? codeArray[index - 1][0]
                                                : undefined
                                        )
                                    }
                                    onPaste={handlePasteInputCode}
                                    disabled={isLoading}
                                />
                            ))}

                            {errorCode && (
                                <div
                                    className={styles.component__inputCodeError}
                                >
                                    {errorCode}
                                </div>
                            )}
                        </div>

                        <Button
                            className={styles.component__button}
                            size="md"
                            onClick={handleSubmitLogIn}
                            disabled={isLoading}
                        >
                            Войти
                        </Button>

                        {timer > 0 ? (
                            <p
                                className={cns(
                                    styles.component__text,
                                    styles.component__text_resend,
                                    "text text_p4 text_center"
                                )}
                            >
                                Отправим код повторно через {timer} секунд
                            </p>
                        ) : (
                            <button
                                className={cns(
                                    styles.component__buttonResend,
                                    "text text_center link"
                                )}
                                type="button"
                                onClick={handleClickButtonResend}
                            >
                                Отправить код повторно
                            </button>
                        )}

                        <div className={styles.component__not}>
                            <button
                                className={cns(
                                    styles.component__buttonNot,
                                    "link"
                                )}
                                type="button"
                                onClick={handleClickButtonNot}
                            >
                                Не приходит СМС
                            </button>

                            {isTooltip && (
                                <div
                                    className={cns(
                                        styles.component__tooltip,
                                        "text text_p4"
                                    )}
                                >
                                    <button
                                        className={
                                            styles.component__tooltipButtonClose
                                        }
                                        type="button"
                                        aria-label="Закрыть подсказку"
                                        onClick={handleClickTooltipButtonClose}
                                    >
                                        <SvgIcon icon="close-thin" />
                                    </button>
                                    Возможные причины: <br />
                                    <ol>
                                        <li>
                                            Вы ошиблись с номером, пожалуйста,
                                            перепроверьте его;
                                        </li>
                                        <li>
                                            Проблемы у сотового оператора.
                                            Обратитесь в службу поддержки.
                                        </li>
                                    </ol>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Auth;
