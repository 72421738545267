import cns from "classnames";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Popup from "@Components/Popup";
import { popupId as popupIdProduct } from "@Components/PopupProduct";
import useWindowSize from "@Hooks/window-size";
import { close, getIsOpen } from "@Redux/popup";

import Info from "./Info";
import Table from "./Table";
import styles from "./index.module.scss";

export type PopupSizesProps = {};

export const popupId = "sizes";

const PopupSizes: React.FC<PopupSizesProps> = () => {
    const isOpen = useSelector(getIsOpen(popupId));

    const [contentMinWidth, setContentMinWidth] = useState<number>(0);

    const refContent = useRef<HTMLDivElement>(null);

    const [isTable, setIsTable] = useState<boolean>(true);

    const windowSize = useWindowSize();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!refContent.current || !isTable) {
            return;
        }

        setContentMinWidth(refContent.current.clientWidth);
    }, [refContent.current, isOpen]);

    useEffect(() => {
        if (isOpen) {
            dispatch(close(popupIdProduct));
        }
    }, [isOpen]);

    return (
        <Popup
            className={styles.component}
            classNameContainer={styles.component__container}
            classNameButtonClose={styles.component__buttonClose}
            id={popupId}
        >
            <h2
                className={cns(
                    styles.component__title,
                    "text text_h4 text_uppercase"
                )}
            >
                Подобрать свой размер
            </h2>

            <div
                className={cns(
                    styles.component__buttons,
                    "text text_h5 text_center"
                )}
            >
                <button
                    className={cns(styles.component__button, {
                        isSelected: isTable,
                    })}
                    type="button"
                    onClick={() => setIsTable(true)}
                >
                    Таблица размеров
                </button>

                <button
                    className={cns(styles.component__button, {
                        isSelected: !isTable,
                    })}
                    type="button"
                    onClick={() => setIsTable(false)}
                >
                    Как снять замеры
                </button>
            </div>

            <div
                ref={refContent}
                className={styles.component__content}
                style={{
                    minWidth: windowSize.isDesktop
                        ? contentMinWidth
                        : undefined,
                }}
            >
                <Table className={cns({ isHidden: !isTable })} />
                <Info className={cns({ isHidden: isTable })} />
            </div>
        </Popup>
    );
};

export default PopupSizes;
