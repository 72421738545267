import { isClient } from "@Settings";
import AnimateFadeIn from "@Components/Animation/AnimateFadeIn";
import Container from "@Components/Container";
import Button from "@Components/ui/Button";
import useCookie from "@Hooks/cookie";

import styles from "./index.module.scss";

const MessageCookie: React.FC = () => {
    const [cookie, setCookie] = useCookie("consent", undefined, {
        expires: 365,
    });

    if (cookie || !isClient) {
        return null;
    }

    const handleButtonClick = () => {
        setCookie("true");
    };

    return (
        <AnimateFadeIn offset={0}>
            <div className={styles.component}>
                <Container className={styles.component__container}>
                    <p className={styles.component__text}>
                        Этот сайт использует cookie для хранения данных.
                        Продолжая использовать сайт, вы&nbsp;даете согласие на
                        работу с этими файлами
                    </p>

                    <Button
                        className={styles.component__button}
                        onClick={handleButtonClick}
                    >
                        Принять
                    </Button>
                </Container>
            </div>
        </AnimateFadeIn>
    );
};

export default MessageCookie;
