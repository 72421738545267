import cns from "classnames";

import pages from "@Settings/pages";
import CardCategory from "@Components/CardCategory";
import LinkWithDropdown from "@Components/Dropdown";
import Link from "@Components/ui/Link";
import { Category, ContentMenuItem } from "@Types/api";

import styles from "./index.module.scss";

type DropdownProps = {
    className?: string;
    title: string;
    links?: ContentMenuItem[] | null;
    colsCount?: number;
    category?: Category;
};

const getLinks = (links: ContentMenuItem[], level: number) => {
    const classNameLink = cns({
        [styles.component__link]: !level,
        [styles.component__sublink]: level,
    });

    return links.map(item => {
        if (item.sublinks?.length) {
            return (
                <LinkWithDropdown
                    key={item.code}
                    className={classNameLink}
                    classNameButtonIcon={styles.component__linkIcon}
                    classNameListContent={cns(
                        styles.component__sublinks,
                        "text text_p3"
                    )}
                    buttonText={item.text}
                    items={getLinks(item.sublinks, level + 1)}
                />
            );
        }

        return (
            <Link
                key={item.code}
                className={classNameLink}
                href={item.link ?? "#"}
            >
                {item.text}
            </Link>
        );
    });
};

const Dropdown: React.FC<DropdownProps> = ({
    className,
    title,
    links,
    colsCount = 2,
    category,
}) => {
    const linksInCol = links ? Math.ceil(links.length / colsCount) : 0;

    return (
        <div className={cns(className, styles.component)}>
            <div className={styles.component__content}>
                <div
                    className={cns(
                        styles.component__title,
                        "text text_h4 text_uppercase"
                    )}
                >
                    {title}
                </div>

                {!!links?.length && (
                    <div
                        className={cns(styles.component__links, "text text_h5")}
                    >
                        {Array(colsCount)
                            .fill("")
                            .map((col, colIndex) => (
                                <div
                                    key={colIndex}
                                    className={styles.component__linksCol}
                                >
                                    {getLinks(
                                        links.slice(
                                            linksInCol * colIndex,
                                            linksInCol * (colIndex + 1)
                                        ),
                                        0
                                    )}
                                </div>
                            ))}
                    </div>
                )}
            </div>

            {category && category.SUBSECTIONS && (
                <div className={styles.component__cards}>
                    {Object.values(category.SUBSECTIONS)
                        .sort((c1, c2) => Number(c1.SORT) - Number(c2.SORT))
                        .slice(0, 2)
                        .map(c => (
                            <CardCategory
                                key={c.CODE}
                                className={styles.component__card}
                                link={pages.category.link(c.PATH)}
                                text={c.NAME}
                                image={c.PICTURE_URL ?? undefined}
                            />
                        ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
