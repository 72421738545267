import cns from "classnames";

import Image from "@Components/ui/Image";
import { formatCurrency } from "@Utils/helpers";

import styles from "./index.module.scss";

export type ProductProps = {
    className?: string;
    image: string;
    name: string;
    color?: string;
    size?: string | number;
    count?: number;
    price?: number;
};

const Product: React.FC<ProductProps> = ({
    className,
    image,
    name,
    color,
    size,
    count,
    price,
}) => {
    return (
        <div className={cns(className, styles.component)}>
            <div className={styles.component__image}>
                <Image src={image} alt="" fill size="10vw" sizeTablet="30vw" />
            </div>

            <div className={styles.component__info}>
                <div className={cns(styles.component__name, "text text_h5")}>
                    {name}
                </div>

                <div className={styles.component__main}>
                    <div
                        className={cns(styles.component__list, "text text_p4")}
                    >
                        {color && (
                            <div className={styles.component__item}>
                                <div className={styles.component__key}>
                                    Цвет:
                                </div>
                                <div className={styles.component__value}>
                                    {color}
                                </div>
                            </div>
                        )}

                        {size && (
                            <div className={styles.component__item}>
                                <div className={styles.component__key}>
                                    Размер:
                                </div>
                                <div className={styles.component__value}>
                                    {size}
                                </div>
                            </div>
                        )}

                        {count && (
                            <div className={styles.component__item}>
                                <div className={styles.component__key}>
                                    Количество:
                                </div>
                                <div className={styles.component__value}>
                                    {count}
                                </div>
                            </div>
                        )}
                    </div>

                    {price && (
                        <div
                            className={cns(
                                styles.component__price,
                                "text text_p3"
                            )}
                        >
                            {formatCurrency(price)}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Product;
