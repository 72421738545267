import cns from "classnames";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import Dropdown from "@Components/Dropdown";
import { popupId } from "@Components/PopupSizes";
import { useProductContext } from "@Pages/product/context";
import { open } from "@Redux/popup";
import { ProductFullSize } from "@Types/api";
import * as utils from "@Utils";

import styles from "./index.module.scss";

export type SizeProps = {
    className?: string;
};

let timeoutSizeError: ReturnType<typeof setTimeout> | null = null;

const getCaption = (size?: ProductFullSize) => {
    if (!size) {
        return "Нет в наличии";
    }

    if (size.amount === 0) {
        return "Нет в наличии (Возможен предзаказ)";
    }

    if (size.amount > 5) {
        return "В наличии";
    }

    return "Мало";
};

const Size: React.FC<SizeProps> = ({ className }) => {
    const {
        product,
        sizeId,
        setSizeId,
        availableSizes,
        isSizeError,
        setIsSizeError,
    } = useProductContext();

    const dispatch = useDispatch();

    const refDropdown = useRef<HTMLButtonElement>(null);

    const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (isSizeError) {
            refDropdown.current?.focus();

            setDropdownIsOpen(true);

            if (timeoutSizeError !== null) {
                clearTimeout(timeoutSizeError);
            }

            timeoutSizeError = setTimeout(() => setIsSizeError(false), 5000);
        }
    }, [isSizeError]);

    useEffect(() => {
        setIsSizeError(false);
    }, [availableSizes]);

    if (!Object.keys(availableSizes).length) {
        return null;
    }

    const handleButtonSizesClick = () => {
        dispatch(
            open({ id: popupId, extra: { sizesTable: product.sizesTable } })
        );
    };

    const handleItemClick = (sizeIndex: number) => {
        setSizeId(sizeIndex);
        setIsSizeError(false);
    };

    return (
        <div className={cns(className, styles.component)}>
            <div className={cns(styles.component__header, "text text_p3")}>
                <button
                    className={cns(styles.component__sizeLabel, {
                        isError: isSizeError,
                    })}
                    type="button"
                    onClick={() => setDropdownIsOpen(true)}
                >
                    Выберите размер
                </button>

                {product.sizesTable.table && (
                    <button
                        className="link"
                        type="button"
                        onClick={handleButtonSizesClick}
                    >
                        Таблица размеров
                    </button>
                )}
            </div>

            <Dropdown
                ref={refDropdown}
                className={styles.component__dropdown}
                classNameButton={cns(
                    styles.component__dropdownButton,
                    "text text_p2 text_uppercase",
                    {
                        [styles.component__dropdownButton_notSelected]:
                            sizeId === null || !availableSizes[sizeId],
                    }
                )}
                classNameButtonIcon={styles.component__dropdownIcon}
                classNameList={styles.component__dropdownList}
                classNameListContent={cns(
                    styles.component__dropdownListContent,
                    "text text_p2 text_uppercase"
                )}
                buttonText={
                    sizeId === null || !availableSizes[sizeId]
                        ? "Не выбран"
                        : availableSizes[sizeId].name
                }
                items={_.sortBy(Object.entries(availableSizes), ([i, size]) =>
                    utils.convertSizeToNumber(size.name)
                ).map(([sizeIndex, size]) => {
                    const sizeInAvailable = availableSizes[+sizeIndex];

                    return (
                        <button
                            key={sizeIndex}
                            className={cns(styles.component__item, {
                                isCurrent: +sizeIndex === sizeId,
                                isDisabled: !sizeInAvailable,
                            })}
                            type="button"
                            onClick={() => handleItemClick(+sizeIndex)}
                        >
                            {size.name}

                            <span
                                className={cns(
                                    styles.component__itemCaption,
                                    "text text_p5 text_normalsize"
                                )}
                            >
                                {getCaption(sizeInAvailable)}
                            </span>
                        </button>
                    );
                })}
                open={dropdownIsOpen}
                setOpen={setDropdownIsOpen}
                controlState
                closeOnItemClick
                closeOnOutsideClick
            />
        </div>
    );
};

export default Size;
