const VIDEO_EXT = ["mp4", "webm", "avi"];

export const formatDate = (date: string | undefined | null) => {
    if (!date) {
        return null;
    }

    const d = new Date(date);

    let formated = d
        .toLocaleDateString("ru-RU", {
            day: "numeric",
            month: "long",
            year: "numeric",
        })
        .slice(0, -3);

    if (d.getDate() < 10) {
        formated = `0${formated}`;
    }

    return formated;
};

export const formatCurrency = (n: number): string =>
    new Intl.NumberFormat("ru-RU", {
        style: "currency",
        currency: "RUB",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(n || 0);

export const declOfNums = (n: number, word: string[]): string => {
    const cases = [2, 0, 1, 1, 1, 2];
    return word[
        n % 100 > 4 && n % 100 < 20 ? 2 : cases[n % 10 < 5 ? n % 10 : 5]
    ];
};

export const isVideoSource = (src?: string) =>
    VIDEO_EXT.includes(String(src?.split(".").pop()?.toLowerCase()));
