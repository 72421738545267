import cns from "classnames";
import { useField } from "formik";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import ReactSelect, {
    ActionMeta,
    components,
    DropdownIndicatorProps,
    GroupBase,
    MenuListProps,
    Props,
    useStateManager,
} from "react-select";
import { useAsync } from "react-select/async";

import styles from "@Components/ui/Select/index.module.scss";
import SvgIcon from "@Components/ui/SvgIcon";
import { useDataContext } from "@Hooks/api/client";
import { useAsyncDebounce } from "@Hooks/debounce";
import { DadataSuggestion } from "@Types/api";
import * as utils from "@Utils";

type CustomSelectProps<T> = Props<T, false, GroupBase<T>> & {
    id: string;
    className?: string;
    classNameOption?: string;
    error?: string;
};

function CustomSelectInner<T>(
    {
        id,
        className,
        onChange,
        classNameOption,
        noOptionsMessage = () => "Ничего не найдено",
        error,
        menuIsOpen,
        ...props
    }: CustomSelectProps<T>,
    ref: React.ForwardedRef<any>
) {
    const [isOpen, setIsOpen] = useState(false);
    const [isSelected, setIsSelected] = useState(false);

    const isMenuOpen = isOpen && (_.isBoolean(menuIsOpen) ? menuIsOpen : true);

    return (
        <div
            className={cns(className, styles.component, {
                [styles._hasInfo]: !!error,
            })}
        >
            <ReactSelect
                ref={ref}
                inputId={id}
                className={cns(className, styles.component, {
                    isOpen: isMenuOpen,
                })}
                classNames={{
                    control: ({ isFocused, isDisabled }) =>
                        cns(styles.component__control, {
                            isFocused,
                            isDisabled,
                            isSelected,
                        }),
                    dropdownIndicator: () =>
                        cns(styles.component__dropdownIndicator, {
                            isReversed: isMenuOpen,
                        }),
                    input: () => styles.component__input,
                    menu: () => styles.component__menu,
                    menuList: () => styles.component__menuList,
                    loadingMessage: () => "text text_p4",
                    noOptionsMessage: () =>
                        cns(styles.component__noOptionsMessage, "text text_p4"),
                    option: ({ isSelected }) =>
                        cns(classNameOption, styles.component__option, {
                            isSelected,
                        }),
                    placeholder: () => styles.component__placeholder,
                    singleValue: () => styles.component__singleValue,
                    valueContainer: () => styles.component__valueContainer,
                }}
                components={{
                    DropdownIndicator: null,
                    IndicatorSeparator: null,
                }}
                {...props}
                menuIsOpen={isMenuOpen}
                unstyled
                noOptionsMessage={noOptionsMessage}
                loadingMessage={() => "Загрузка..."}
                openMenuOnFocus
                blurInputOnSelect
                onMenuOpen={() => {
                    setIsOpen(true);
                    props.onMenuOpen?.();
                }}
                onMenuClose={() => {
                    setIsOpen(false);
                    props.onMenuClose?.();
                }}
                onChange={(option, meta) => {
                    setIsSelected(!!option);
                    onChange?.(option as T, meta as ActionMeta<T>);
                }}
                closeMenuOnSelect={false}
            />
            {!!error && (
                <label
                    className={cns(styles.component__info, {
                        [styles._error]: !!error,
                    })}
                    htmlFor={id}
                >
                    {String(error)}
                </label>
            )}
        </div>
    );
}

const CustomSelect = React.forwardRef(CustomSelectInner) as <T>(
    props: CustomSelectProps<T> & { ref?: React.ForwardedRef<any> }
) => ReturnType<typeof CustomSelectInner>;

export const FormikCitySelect: React.FC<{
    name: string;
    className?: string;
}> = ({ name, className }) => {
    const { client } = useDataContext();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const loadOptions = useAsyncDebounce(
        (query: string) => client.dadata.getCity(query),
        200,
        [client]
    );

    const asyncState = useAsync<
        DadataSuggestion,
        false,
        GroupBase<DadataSuggestion>,
        object
    >({
        loadOptions,
        inputValue,
        onInputChange: newValue => {
            setInputValue(newValue);
        },
    });
    const selectProps = useStateManager(asyncState);

    const [field, _meta, helpers] = useField<DadataSuggestion>(name);

    return (
        <CustomSelect
            id="select-city"
            className={className}
            // defaultOptions={[]}
            {...field}
            {...selectProps}
            name={name}
            placeholder="Населенный пункт"
            menuIsOpen={isMenuOpen && inputValue.length > 0}
            onMenuOpen={() => {
                setIsMenuOpen(true);
            }}
            onMenuClose={() => {
                setIsMenuOpen(false);
            }}
            getOptionLabel={o => o.value}
            getOptionValue={o => o.value}
            value={field.value}
            onChange={async selectedOption => {
                if (!selectedOption) {
                    return;
                }

                await helpers.setValue(selectedOption);
            }}
            error={_meta.error}
        />
    );
};

export const FormikAddressSelect: React.FC<{
    name: string;
    cityName: string;
    className?: string;
}> = ({ name, cityName, className }) => {
    const isHouseSelection = useRef(false);

    const selectRef = useRef<any>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { client } = useDataContext();
    const [cityField] = useField<DadataSuggestion | undefined>(cityName);

    const loadOptions = useAsyncDebounce(
        (query: string, bound: "house" | "street" = "street") => {
            console.log("select for:", query, bound);
            return client.dadata.getAddress(
                `${cityField.value?.value}, ${query}`,
                bound,
                cityField.value?.data.fias_id
            );
        },
        200,
        [client, cityField.value]
    );

    const [field, _meta, helpers] = useField<DadataSuggestion>(name);
    const [inputValue, setInputValue] = useState("");
    const [savedInputValue, setSavedInputValue] = useState("");
    const [options, setOptions] = useState<DadataSuggestion[]>([]);
    const asyncState = useAsync<
        DadataSuggestion,
        false,
        GroupBase<DadataSuggestion>,
        object
    >({
        // loadOptions,
        // onBlur: field.onBlur,
        options,
        inputValue,
        onMenuClose: () => {
            console.log("menu-close");
            setSavedInputValue(inputValue);
        },
        onFocus: () => {
            field.value &&
                setInputValue(utils.getAddressSuggestion(field.value));
            setSavedInputValue("");
            // todo: arrows to navigate
        },
        onInputChange: async newValue => {
            setInputValue(newValue);
            // setOptions(await loadOptions(newValue));

            void (async () => {
                if (_.isEmpty(newValue)) {
                    return;
                }

                setOptions(await loadOptions(newValue, "street"));
            })();
        },
    });
    const selectProps = useStateManager(asyncState);

    return (
        <CustomSelect
            ref={selectRef}
            id="select-address"
            className={className}
            // defaultOptions={[]}
            // {...field}

            {...selectProps}
            value={field.value}
            options={options}
            isDisabled={_.isEmpty(cityField.value)}
            placeholder="Адрес доставки" // savedInput
            getOptionLabel={utils.getAddressSuggestion}
            getOptionValue={o => o.value}
            closeMenuOnSelect={false}
            menuIsOpen={isMenuOpen && inputValue.length > 0}
            onMenuOpen={() => {
                isHouseSelection.current = false;
                setIsMenuOpen(true);
            }}
            onChange={async selectedOption => {
                if (!selectedOption) {
                    return;
                }

                await helpers.setValue(selectedOption);

                if (_.isEmpty(selectedOption.data.house)) {
                    const newInputValue =
                        utils.getAddressSuggestion(selectedOption) + " ";

                    isHouseSelection.current = true;
                    setInputValue(newInputValue);
                    selectRef.current?.focus();

                    setOptions(await loadOptions(newInputValue, "house"));
                } else {
                    isHouseSelection.current = false;
                    setIsMenuOpen(false);
                }
            }}
            error={_meta.error}
        />
    );
};
