import cns from "classnames";
import _ from "lodash";
import { useState } from "react";
import ReactSelect, {
    components,
    DropdownIndicatorProps,
    MenuListProps,
} from "react-select";

import SvgIcon from "@Components/ui/SvgIcon";

import styles from "./index.module.scss";

export type SelectOption<T = unknown> = {
    label: React.ReactNode;
    value: T;
};

export type SelectProps<T> = {
    className?: string;
    classNameOption?: string;
    options: SelectOption<T>[];
    id?: string;
    name?: string;
    value?: SelectOption<T> | null;
    placeholder?: string;
    required?: boolean;
    search?: boolean;
    messageNotFound?: string;
    error?: string;
    menuIsOpen?: boolean;
    filterOption?: (option: SelectOption<T>, inputValue: string) => boolean;
    formatMenu?: (children: React.ReactNode) => React.ReactNode;
    formatOption?: (label: string) => React.ReactNode;
    onOpen?: () => void;
    onClose?: () => void;
    onChange?: (selectedOption: SelectOption<T>) => void;
};

function Select<T>({
    className,
    classNameOption,
    options,
    id,
    name,
    value,
    placeholder,
    required,
    search = true,
    error,
    messageNotFound = "Ничего не найдено",
    formatMenu,
    formatOption,
    filterOption,
    onOpen,
    onClose,
    onChange,
    menuIsOpen,
}: SelectProps<T>) {
    const [isOpen, setIsOpen] = useState(false);
    const [isSelected, setIsSelected] = useState(false);

    const isMenuOpen = isOpen && (_.isBoolean(menuIsOpen) ? menuIsOpen : true);

    const handleOpen = () => {
        setIsOpen(true);

        if (onOpen) {
            onOpen();
        }
    };

    const handleClose = () => {
        setIsOpen(false);

        if (onClose) {
            onClose();
        }
    };

    const handleChange = (selectedOption: SelectOption<T>) => {
        setIsSelected(!!selectedOption);

        if (onChange) {
            onChange(selectedOption);
        }
    };

    return (
        <div
            className={cns(className, styles.component, {
                [styles._hasInfo]: !!error,
            })}
        >
            <ReactSelect
                className={cns({ isOpen: isMenuOpen })}
                classNames={{
                    control: ({ isFocused, isDisabled }) =>
                        cns(styles.component__control, {
                            isFocused,
                            isDisabled,
                            isSelected,
                        }),
                    dropdownIndicator: () =>
                        cns(styles.component__dropdownIndicator, {
                            isReversed: isMenuOpen,
                        }),
                    input: () => styles.component__input,
                    menu: () => styles.component__menu,
                    menuList: () => styles.component__menuList,
                    loadingMessage: () => "text text_p4",
                    noOptionsMessage: () =>
                        cns(styles.component__noOptionsMessage, "text text_p4"),
                    option: ({ isSelected }) =>
                        cns(classNameOption, styles.component__option, {
                            isSelected,
                        }),
                    placeholder: () => styles.component__placeholder,
                    singleValue: () => styles.component__singleValue,
                    valueContainer: () => styles.component__valueContainer,
                }}
                components={{
                    DropdownIndicator: (props: DropdownIndicatorProps) => (
                        <components.DropdownIndicator {...props}>
                            <SvgIcon icon="angle-down" />
                        </components.DropdownIndicator>
                    ),
                    IndicatorSeparator: null,
                    MenuList: (props: MenuListProps) => (
                        <components.MenuList {...props}>
                            {formatMenu
                                ? formatMenu(props.children)
                                : props.children}
                        </components.MenuList>
                    ),
                }}
                filterOption={
                    filterOption
                        ? // @ts-ignore
                          ({ value }, inputValue) =>
                              filterOption(value, inputValue)
                        : undefined
                }
                value={value}
                options={options}
                unstyled
                noOptionsMessage={() => messageNotFound}
                loadingMessage={() => "Загрузка..."}
                openMenuOnFocus
                blurInputOnSelect
                inputId={id}
                name={name}
                placeholder={placeholder}
                required={required}
                isSearchable={search}
                menuIsOpen={menuIsOpen}
                formatOptionLabel={
                    formatOption
                        ? (data: any) => formatOption(data.label)
                        : undefined
                }
                onMenuOpen={handleOpen}
                onMenuClose={handleClose}
                onChange={handleChange}
            />
            {!!error && (
                <label
                    className={cns(styles.component__info, {
                        [styles._error]: !!error,
                    })}
                    htmlFor={id}
                >
                    {String(error)}
                </label>
            )}
        </div>
    );
}

export default Select;
