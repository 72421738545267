type IconProps = {
    className?: string;
};

const Icon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.9095 10.8738L13.1254 7.08971M18.2221 5.78354C17.1784 4.73989 15.4753 4.73989 14.438 5.77714L6.14 14.0752C5.85187 14.3633 5.53814 14.9203 5.43569 15.3173L5.08994 16.7131C4.68657 18.3202 5.679 19.3126 7.2861 18.9093L8.68191 18.5635C9.07888 18.4611 9.63592 18.1473 9.92405 17.8592L18.2221 9.56119C19.2593 8.52394 19.2593 6.82079 18.2221 5.78354Z"
            fill="transparent"
            stroke="currentColor"
            strokeLinecap="round"
        />
    </svg>
);

export default Icon;
