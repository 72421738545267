import { ParsedUrlQuery } from "querystring";

import api from "@Settings/api";
import Client from "@Api/client";
import { ApiFiltersResponse } from "@Types/api";

export const getProductsWithParams = (
    client: Client,
    query?: ParsedUrlQuery,
    params?: {
        categoryId?: number;
        productsPerPage?: number;
        currentPage?: number;
        filters?: ApiFiltersResponse | null;
    }
) => {
    const queryShow = (query?.show as string)
        ?.toLowerCase()
        .split(api.queryParamsDivider);

    const getSortParam = () => {
        const sortValues: any = {
            priceAsc: "PRICE_ASC",
            priceDesc: "PRICE_DESC",
            popular: undefined,
            new: "NEW",
        };

        return sortValues[String(query?.sort)];
    };

    const getFiltersParam = () => {
        if (!params?.filters?.length) {
            return;
        }

        const filtersObject: { [key: string]: any } = {};
        let filtersSelected = false;

        params.filters.forEach(filter => {
            if (query?.[filter.UF_CODE.toLowerCase()]) {
                filtersObject[filter.UF_PROPERTY_CODE] = (
                    query[filter.UF_CODE.toLowerCase()] as string
                ).split(api.queryParamsDivider);

                if (!filtersSelected) {
                    filtersSelected = true;
                }
            }
        });

        return filtersSelected ? JSON.stringify(filtersObject) : undefined;
    };

    return client.catalog.getProducts({
        category: params?.categoryId,
        page_count: params?.productsPerPage ?? api.productsPerPage,
        page_current: params?.currentPage ?? (Number(query?.page) || 1),
        NEW: queryShow?.includes("new") ? "Y" : undefined,
        HIT: queryShow?.includes("hit") ? "Y" : undefined,
        is_sale: queryShow?.includes("sale") ? "1" : undefined,
        sort: getSortParam(),
        filters: getFiltersParam(),
    });
};

export const getTrustWithParams = (
    client: Client,
    query?: ParsedUrlQuery,
    params?: {
        itemsPerPage?: number;
        currentPage?: number;
    }
) => {
    return client.content.getTrust({
        page_count: params?.itemsPerPage ?? api.trustPerPage,
        page_current: params?.currentPage ?? (Number(query?.page) || 1),
    });
};

export const getLookbooksWithParams = (
    client: Client,
    query?: ParsedUrlQuery,
    params?: {
        itemsPerPage?: number;
        currentPage?: number;
    }
) => {
    return client.content.getLookbooks({
        page_count: params?.itemsPerPage ?? api.lookbookPerPage,
        page_current: params?.currentPage ?? (Number(query?.page) || 1),
    });
};
