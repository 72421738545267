import { useMutation } from "@tanstack/react-query";
import _ from "lodash";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import pages from "@Settings/pages";
import { openAuthPopup } from "@Components/PopupAuth";
import { events } from "@Events";
import { useApiClient } from "@Hooks/api/client";
import { useCart } from "@Hooks/cart";
import { useBonusBalance, useUser, useUserOrders } from "@Hooks/user";
import { AppThunkDispatch } from "@Redux/store";
import { BodyForCreateOrder, UserEditableFields } from "@Types/api";
import { openCloudPayemntsWidget } from "@Utils/payments";

export const useCheckoutSubmit = () => {
    const client = useApiClient();
    const router = useRouter();
    const dispatch = useDispatch<AppThunkDispatch>();
    const { user, updateUser } = useUser();
    const { refetchCart } = useCart();
    const { refetchOrders } = useUserOrders();
    const [, refetchBonusBalance] = useBonusBalance();
    const { mutateAsync: createOrder } = useMutation(
        client.checkout.createOrder
    );

    const checkout = useCallback(
        async (data: BodyForCreateOrder) => {
            const userFieldsToUpdate: Partial<UserEditableFields> = {
                name: data.name,
                email: data.email,
            };

            if (data.quick_order !== "Y") {
                userFieldsToUpdate.lastName = data.last_name;
                userFieldsToUpdate.patronymic = data.second_name;
            }

            const order = await createOrder(data);

            try {
                await updateUser(userFieldsToUpdate);
                void refetchBonusBalance();
            } catch (err) {}

            events.emit("order.created", { order });

            try {
                await openCloudPayemntsWidget({
                    invoiceId: order.id,
                    amount: order.price,
                });
            } catch (err) {
                //
            } finally {
                await refetchOrders();
                await router.push(pages.checkout.completedLink(order.id));
                void refetchCart();
                void refetchBonusBalance();
            }
        },
        [router, createOrder, refetchCart, refetchOrders, updateUser]
    );

    return useCallback(
        async (data: BodyForCreateOrder) => {
            if (!user?.isLoggedIn) {
                dispatch(
                    openAuthPopup({
                        onSave: () => checkout(data),
                        phone: data.phone,
                    })
                );
                return;
            }

            await checkout(data);
        },
        [dispatch, checkout, user?.isLoggedIn]
    );
};
