import { useMutation } from "@tanstack/react-query";
import cns from "classnames";
import {
    Formik,
    Form as FormikForm,
    FormikHelpers,
    useFormikContext,
} from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { UserError } from "@Api/api-request";
import { FormikInput } from "@Components/Formik";
import { openFormResultPopup } from "@Components/PopupFormResult";
import Button from "@Components/ui/Button";
import { useApiClient } from "@Hooks/api/client";
import { useUser } from "@Hooks/user";
import { PreorderData } from "@Types/api";
import { FIELDS } from "@Utils/schemas";

import { closePopupPreorder } from "..";
import styles from "./index.module.scss";

export type FormProps = {
    className?: string;
    offerId: number;
};

type FormContentProps = {
    className?: string;
    error?: string | null;
};

type FormFields = Omit<PreorderData, "offerId">;

const VALIDATION_SCHEME = Yup.object({
    email: FIELDS.email,
    phone: FIELDS.phone,
});

const usePreorderMutation = () => {
    const apiClient = useApiClient();
    const { mutateAsync } = useMutation(apiClient.catalog.preorder);

    return mutateAsync;
};

const FormContent: React.FC<FormContentProps> = ({ className, error }) => {
    const { isSubmitting } = useFormikContext();

    return (
        <FormikForm
            className={cns(className, styles.component, {
                isLoading: isSubmitting,
            })}
        >
            {isSubmitting && (
                <div className={styles.component__loader}>
                    <div className="loader"></div>
                </div>
            )}

            <div className={styles.component__content}>
                <FormikInput
                    id="preorder-email"
                    name="email"
                    placeholder="Email*"
                    className={styles.component__input}
                />

                <FormikInput
                    type="tel"
                    name="phone"
                    className={styles.component__input}
                />
            </div>
            <Button
                disabled={isSubmitting}
                className={styles.component__buttonSubmit}
                type="submit"
                size="md"
            >
                Оформить предзаказ
            </Button>

            {error && <div className={styles.component__error}>{error}</div>}
        </FormikForm>
    );
};

const Form: React.FC<FormProps> = ({ className, offerId }) => {
    const dispatch = useDispatch();
    const preorder = usePreorderMutation();
    const { user } = useUser();

    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async (
        data: FormFields,
        actions: FormikHelpers<FormFields>
    ) => {
        try {
            await preorder({ offerId, ...data });

            actions.resetForm();
            setError(null);

            dispatch(closePopupPreorder());

            dispatch(
                openFormResultPopup({
                    title: "Предзаказ оформлен",
                    text: "Спасибо за заявку! Наш менеджер свяжется с Вами, когда товар появится в наличии.",
                })
            );
        } catch (err: unknown) {
            const message =
                err instanceof UserError
                    ? "Предзаказ уже оформлен."
                    : "Не удалось оформить предзаказ.";

            toast(message, {
                position: "bottom-right",
            });
        }
    };

    return (
        <Formik
            initialValues={{
                email: user?.data?.email || "",
                phone: user?.data?.phone || "",
            }}
            validationSchema={VALIDATION_SCHEME}
            onSubmit={handleSubmit}
        >
            <FormContent className={className} error={error} />
        </Formik>
    );
};

export default Form;
