import cns from "classnames";

import IconAngleDown from "./images/angle-down";
import IconAngleDownSm from "./images/angle-down-sm";
import IconAngleLeft from "./images/angle-left";
import IconAngleLeftMd from "./images/angle-left-md";
import IconAngleLeftSm from "./images/angle-left-sm";
import IconAngleLeftThin from "./images/angle-left-thin";
import IconAngleRight from "./images/angle-right";
import IconAngleRightMd from "./images/angle-right-md";
import IconAngleRightSm from "./images/angle-right-sm";
import IconAngleRightThin from "./images/angle-right-thin";
import IconAngleUp from "./images/angle-up";
import IconBurger from "./images/burger";
import IconCalendar from "./images/calendar";
import IconCart from "./images/cart";
import IconCheck from "./images/check";
import IconClose from "./images/close";
import IconCloseBig from "./images/close-big";
import IconCloseThin from "./images/close-thin";
import IconEmail from "./images/email";
import IconFile from "./images/file";
import IconFilters from "./images/filters";
import IconGift from "./images/gift";
import IconHeart from "./images/heart";
import IconLock from "./images/lock";
import IconLogout from "./images/logout";
import IconMinus from "./images/minus";
import IconPencil from "./images/pencil";
import IconPhone from "./images/phone";
import IconPlus from "./images/plus";
import IconSearch from "./images/search";
import IconSort from "./images/sort";
import IconStar from "./images/star";
import IconTrash from "./images/trash";
import IconUser from "./images/user";
import IconVideoPlay from "./images/video-play";
import styles from "./index.module.scss";

export type IconsList =
    | "angle-down"
    | "angle-down-sm"
    | "angle-left"
    | "angle-left-md"
    | "angle-left-sm"
    | "angle-left-thin"
    | "angle-right"
    | "angle-right-md"
    | "angle-right-sm"
    | "angle-right-thin"
    | "angle-up"
    | "burger"
    | "calendar"
    | "cart"
    | "check"
    | "close-big"
    | "close-thin"
    | "close"
    | "email"
    | "file"
    | "filters"
    | "heart"
    | "logout"
    | "minus"
    | "pencil"
    | "phone"
    | "plus"
    | "search"
    | "sort"
    | "star"
    | "trash"
    | "user"
    | "video-play"
    | "lock"
    | "gift";

type SvgIconProps = {
    icon: IconsList;
    className?: string;
};

const SvgIcon: React.FC<SvgIconProps> = ({ icon, className }) => {
    const cn = cns(className, styles.component);

    const renderIcon = () => {
        switch (icon) {
            case "angle-down":
                return <IconAngleDown className={cn} />;

            case "angle-down-sm":
                return <IconAngleDownSm className={cn} />;

            case "angle-left":
                return <IconAngleLeft className={cn} />;

            case "angle-left-md":
                return <IconAngleLeftMd className={cn} />;

            case "angle-left-sm":
                return <IconAngleLeftSm className={cn} />;

            case "angle-left-thin":
                return <IconAngleLeftThin className={cn} />;

            case "angle-right":
                return <IconAngleRight className={cn} />;

            case "angle-right-md":
                return <IconAngleRightMd className={cn} />;

            case "angle-right-sm":
                return <IconAngleRightSm className={cn} />;

            case "angle-right-thin":
                return <IconAngleRightThin className={cn} />;

            case "angle-up":
                return <IconAngleUp className={cn} />;

            case "burger":
                return <IconBurger className={cn} />;

            case "calendar":
                return <IconCalendar className={cn} />;

            case "cart":
                return <IconCart className={cn} />;

            case "check":
                return <IconCheck className={cn} />;

            case "close-big":
                return <IconCloseBig className={cn} />;

            case "close-thin":
                return <IconCloseThin className={cn} />;

            case "close":
                return <IconClose className={cn} />;

            case "email":
                return <IconEmail className={cn} />;

            case "file":
                return <IconFile className={cn} />;

            case "filters":
                return <IconFilters className={cn} />;

            case "heart":
                return <IconHeart className={cn} />;

            case "logout":
                return <IconLogout className={cn} />;

            case "minus":
                return <IconMinus className={cn} />;

            case "pencil":
                return <IconPencil className={cn} />;

            case "phone":
                return <IconPhone className={cn} />;

            case "plus":
                return <IconPlus className={cn} />;

            case "search":
                return <IconSearch className={cn} />;

            case "sort":
                return <IconSort className={cn} />;

            case "star":
                return <IconStar className={cn} />;

            case "trash":
                return <IconTrash className={cn} />;

            case "user":
                return <IconUser className={cn} />;

            case "video-play":
                return <IconVideoPlay className={cn} />;

            case "lock":
                return <IconLock className={cn} />;

            case "gift":
                return <IconGift className={cn} />;
        }
    };

    return <>{renderIcon()}</>;
};

export default SvgIcon;
