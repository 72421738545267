import { useWindowSize } from "@react-hook/window-size";
import { useEffect, useState } from "react";

const setVhVar = () => {
    document.documentElement.style.setProperty(
        "--vh",
        window.innerHeight / 100 + "px"
    );
};

const useVUnits = () => {
    const [lastWidth, setLastWidth] = useState(0);
    const windowSize = useWindowSize();

    useEffect(() => {
        if (windowSize[0] <= 1024 && windowSize[0] === lastWidth) {
            return;
        }

        setVhVar();
        setLastWidth(windowSize[0]);
    }, [windowSize]);
};

export default useVUnits;
