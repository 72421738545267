import cns from "classnames";
import { isSafari } from "react-device-detect";
import {
    Autoplay,
    EffectFade,
    FreeMode,
    Keyboard,
    Mousewheel,
    Navigation,
    Pagination,
    Thumbs,
} from "swiper/modules";
import { Swiper, SwiperProps, SwiperRef, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/mousewheel";
import "swiper/css/keyboard";
import "swiper/css/free-mode";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";
import "swiper/css/scrollbar";

import { useEffect, useRef } from "react";

import styles from "./index.module.scss";

type SliderProps = SwiperProps & {
    className?: string;
    classNamesSlide?: string;
    slides: React.ReactNode[];
};

const Slider: React.FC<SliderProps> = ({
    className,
    classNamesSlide,
    slides,
    ...props
}) => {
    const refComponent = useRef<SwiperRef>(null);

    useEffect(() => {
        if (
            refComponent.current?.swiper &&
            refComponent.current.swiper.mousewheel?.enabled &&
            isSafari
        ) {
            refComponent.current.swiper.mousewheel?.disable();
        }
    }, []);

    return (
        <Swiper
            ref={refComponent}
            className={cns(className, styles.component)}
            modules={[
                Autoplay,
                Navigation,
                Pagination,
                Mousewheel,
                Keyboard,
                FreeMode,
                EffectFade,
                Thumbs,
            ]}
            grabCursor
            keyboard
            mousewheel={{
                forceToAxis: true,
            }}
            {...props}
        >
            {slides.map((slide, slideIndex) => (
                <SwiperSlide
                    key={slideIndex}
                    className={cns(classNamesSlide, styles.component__slide)}
                >
                    {slide}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default Slider;
