import cns from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";

import pages from "@Settings/pages";
import Container from "@Components/Container";
import Link from "@Components/ui/Link";
import LinkContacts from "@Components/ui/LinkContacts";
import SvgIcon from "@Components/ui/SvgIcon";
import {
    useGetCommonDataMenuBottom,
    useGetCommonDataSocials,
} from "@Hooks/api/common";
import useWindowSize from "@Hooks/window-size";
import { ContentMenuItem } from "@Types/api";

import SubscriptionForm from "./SubscriptionForm";
import styles from "./index.module.scss";

type tColWithDropdown = {
    title: string;
    links?: ContentMenuItem[] | null;
    linksInContainer?: ContentMenuItem[][] | null;
};

const ColWithDropdown: React.FC<tColWithDropdown> = ({
    title,
    links,
    linksInContainer,
}) => {
    const [isDropdown, setIsDropdown] = useState<boolean>(false);

    const windowSize = useWindowSize();

    const handleClickTitle = (e: React.MouseEvent) => {
        if (!windowSize.isMobile) {
            return;
        }

        e.preventDefault();

        setIsDropdown(!isDropdown);
    };

    return (
        <div className={styles.component__col}>
            <h5
                className={cns(styles.component__title, "text text_h5", {
                    isDropdown: windowSize.isMobile && isDropdown,
                })}
                onClick={handleClickTitle}
            >
                {title}
                <SvgIcon className="forMobile" icon="angle-down-sm" />
            </h5>

            {links && !linksInContainer && (
                <div
                    className={cns(styles.component__links, {
                        isHidden: windowSize.isMobile && !isDropdown,
                    })}
                >
                    {links.map((link, linkIndex) => (
                        <Link
                            key={linkIndex}
                            href={link.link ?? undefined}
                            dangerouslySetInnerHTML={{ __html: link.text }}
                        ></Link>
                    ))}
                </div>
            )}

            {!links && linksInContainer && (
                <div className={styles.component__linksContainer}>
                    {linksInContainer.map((linksArr, linksArrIndex) => (
                        <div
                            key={linksArrIndex}
                            className={cns(styles.component__links, {
                                isHidden: windowSize.isMobile && !isDropdown,
                            })}
                        >
                            {linksArr.map((link, linkIndex) => (
                                <Link
                                    key={linkIndex}
                                    href={link.link ?? undefined}
                                    dangerouslySetInnerHTML={{
                                        __html: link.text,
                                    }}
                                ></Link>
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const Footer: React.FC = () => {
    const dataMenuBottom = useGetCommonDataMenuBottom();
    const dataSocials = useGetCommonDataSocials();

    const { ref, inView } = useInView();

    const socials = useMemo(
        () =>
            dataSocials
                .filter(item => item.showInFooter)
                .map((social, i) => <LinkContacts key={i} contact={social} />),
        [dataSocials]
    );

    useEffect(() => {
        document.documentElement.classList.toggle("footerInView", inView);
    }, [inView]);

    return (
        <footer ref={ref} className={cns(styles.component, "text text_p4")}>
            <Container>
                <div className={styles.component__section}>
                    <div className={styles.component__col}>
                        <Link
                            className={styles.component__logo}
                            href={pages.main.link}
                            aria-label="Логотип Stylish"
                        ></Link>

                        <p className={styles.component__text}>
                            Подписывайся на&nbsp;наши новости и&nbsp;узнавай
                            о&nbsp;новинках первым!
                        </p>

                        <SubscriptionForm className={styles.component__form} />

                        <p
                            className={cns(
                                styles.component__text,
                                styles.component__text_agreement
                            )}
                        >
                            Подписываясь на&nbsp;рассылку, вы&nbsp;соглашаетесь
                            с&nbsp;условиями{" "}
                            {
                                <Link href={pages.policy.link} blank simple>
                                    Политики&nbsp;конфиденциальности
                                </Link>
                            }
                        </p>

                        {!!socials.length && (
                            <div
                                className={cns(
                                    styles.component__socials,
                                    "forTablet"
                                )}
                            >
                                {socials}
                            </div>
                        )}
                    </div>

                    {dataMenuBottom.map((link, linkIndex) => {
                        let links;
                        let linksInContainer;

                        if (linkIndex) {
                            links = link.sublinks;
                        } else {
                            const sublinksHalfLength =
                                (link.sublinks?.length ?? 0) / 2 + 1;

                            linksInContainer = [
                                link.sublinks?.slice(0, sublinksHalfLength),
                                link.sublinks?.slice(sublinksHalfLength),
                            ] as ContentMenuItem[][];
                        }

                        return (
                            <ColWithDropdown
                                key={linkIndex}
                                title={link.text}
                                links={links}
                                linksInContainer={linksInContainer}
                            />
                        );
                    })}

                    {!!socials.length && (
                        <div
                            className={cns(
                                styles.component__col,
                                "notForTablet"
                            )}
                        >
                            <h5
                                className={cns(
                                    styles.component__title,
                                    "text text_h5 forDesktop"
                                )}
                            >
                                Социальные сети
                            </h5>

                            <div
                                className={cns(
                                    styles.component__links,
                                    styles.component__links_socials
                                )}
                            >
                                {socials}
                            </div>
                        </div>
                    )}
                </div>

                <div
                    className={cns(
                        styles.component__section,
                        styles.component__section_bottom,
                        "text text_p5"
                    )}
                >
                    <div className={styles.component__team}>
                        <Link href={pages.createdBy.link} blank simple>
                            Сайт разработан ByteTown
                        </Link>
                    </div>

                    <div
                        className={cns(
                            styles.component__links,
                            styles.component__links_bottom
                        )}
                    >
                        <Link href={pages.policy.link} blank simple>
                            Политика конфиденциальности
                        </Link>
                    </div>

                    <div className={styles.component__copy}>
                        &copy;&nbsp;{new Date().getFullYear()}&nbsp;STYLISH
                    </div>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
