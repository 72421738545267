import type {
    ApiCartResponse,
    BodyForAddCartItem,
    BodyForUpdateCartItem,
    CartResponse,
    DeliveryType,
    Result,
} from "@Types/api";

import * as mappers from "@Api/mappers";
import { Repository } from "@Utils/api/repository";

type CartPayload = {
    delivery_id?: DeliveryType;
};

class CartApi extends Repository {
    getCart = async (data: CartPayload): Promise<CartResponse> => {
        const result: Result<ApiCartResponse | null> =
            await this.client.request.get("/basket", data);

        return mappers.mapCartResponse(
            result.data || {
                eligible: {
                    max: 0,
                    ph_add_bonus_percent: 0.1,
                    total: 0,
                },
                items: [],
                totals: {
                    coupon: null,
                    couponDiscount: 0,
                    discount: 0,
                    subtotal: 0,
                    total: 0,
                    price_delivery: 0,
                    price_full: 0,
                },
            }
        );
    };

    updateCartItem = async ({
        itemId,
        quantity,
        action,
    }: BodyForUpdateCartItem) => {
        await this.client.request.put("/basket", {
            basket_item_id: itemId,
            quantity,
            action: action || "update",
        });

        return true;
    };

    addCartItem = async ({ offerId, quantity }: BodyForAddCartItem) => {
        await this.client.request.post("/basket", {
            offer_id: offerId,
            quantity,
        });

        return true;
    };

    applyPromocode = async (code: string) => {
        await this.client.request.post("/basket/coupon", {
            coupon: code,
        });

        return true;
    };

    removePromocode = async () => {
        await this.client.request.delete("/basket/coupon");
        return true;
    };
}

export default CartApi;
