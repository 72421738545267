import cns from "classnames";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useClickAway, useUpdateEffect } from "react-use";

import pages from "@Settings/pages";
import Scrollbar from "@Components/Scrollbar";
import Button from "@Components/ui/Button";
import Link from "@Components/ui/Link";
import SvgIcon from "@Components/ui/SvgIcon";
import TextIcon from "@Components/ui/TextIcon";
import { useCart } from "@Hooks/cart";
import { formatCurrency } from "@Utils/helpers";

import Product from "./Product";
import styles from "./index.module.scss";

type CartProps = {
    isOpen: boolean;
    onClose: () => void;
};

const Cart: React.FC<CartProps> = ({ isOpen, onClose }) => {
    const router = useRouter();
    const refContainer = useRef<HTMLDivElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const { data } = useCart();

    const startCloseTimeout = () => {
        if (isMobile) {
            return;
        }

        if (timeoutRef.current && !isOpen) {
            return;
        }

        //todo: check
        timeoutRef.current = setTimeout(onClose, 5000);
    };

    const stopCloseTimeout = () => {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
    };

    useClickAway(refContainer, onClose);

    useUpdateEffect(() => {
        onClose();
    }, [router.pathname]);

    useEffect(() => {
        if (!isOpen) {
            stopCloseTimeout();
            return;
        }

        startCloseTimeout();

        return () => {
            stopCloseTimeout();
        };
    }, [isOpen]);

    return (
        <div
            className={cns(styles.component, {
                isHidden: !isOpen,
            })}
        >
            <div
                ref={refContainer}
                className={styles.component__container}
                onMouseEnter={stopCloseTimeout}
                onMouseLeave={startCloseTimeout}
            >
                <button
                    className={styles.component__buttonClose}
                    type="button"
                    aria-label="Закрыть окно корзины"
                    onClick={onClose}
                >
                    <SvgIcon icon="close-thin" />
                </button>

                <h3
                    className={cns(
                        styles.component__title,
                        "text text_h5 text_uppercase"
                    )}
                >
                    Корзина
                </h3>

                <Scrollbar className={styles.component__products}>
                    {data?.items.map((item, i) => (
                        <Product
                            key={i}
                            className={styles.component__product}
                            image={item.imageUrl}
                            name={item.offer.name}
                            color={item.offer.color}
                            size={item.offer.size}
                            count={item.count}
                            price={item.price}
                        />
                    ))}
                </Scrollbar>

                <div className={cns(styles.component__info, "text text_p4")}>
                    <div className={styles.component__infoItem}>
                        <span>Сумма заказа</span>
                        <span>
                            {formatCurrency(data?.totals.subtotal || 0)}
                        </span>
                    </div>

                    <div className={styles.component__infoItem}>
                        <span>Сумма скидки</span>
                        <span>
                            {formatCurrency(data?.totals.totalDiscount || 0)}
                        </span>
                    </div>

                    <div
                        className={cns(
                            styles.component__infoItem,
                            "text text_h5"
                        )}
                    >
                        <span>Итого</span>
                        <span>{formatCurrency(data?.totals.total || 0)}</span>
                    </div>
                </div>

                <Button
                    className={styles.component__buttonCheckout}
                    href={pages.checkout.link}
                    size="md"
                >
                    Оформить заказ
                </Button>

                <Link
                    className={styles.component__linkCart}
                    href={pages.cart.link}
                >
                    <TextIcon
                        textClassName="link"
                        iconClassName={styles.component__linkCartIcon}
                        icon="angle-right-sm"
                        isRight
                    >
                        Перейти в корзину
                    </TextIcon>
                </Link>
            </div>
        </div>
    );
};

export default Cart;
