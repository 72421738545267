import cns from "classnames";

import { useCart } from "@Hooks/cart";
import { formatCurrency } from "@Utils/helpers";

import styles from "./index.module.scss";

export type SumProps = {
    className?: string;
};

const Sum: React.FC<SumProps> = ({ className }) => {
    const { data } = useCart();

    return (
        <div className={cns(className, styles.component, "text text_p4")}>
            <div className={styles.component__item}>
                <div className={styles.component__key}>Сумма заказа:</div>
                <div className={styles.component__value}>
                    {formatCurrency(data?.totals.subtotal || 0)}
                </div>
            </div>

            <div className={styles.component__item}>
                <div className={styles.component__key}>Сумма скидки:</div>
                <div className={styles.component__value}>
                    {formatCurrency(data?.totals.totalDiscount || 0)}
                </div>
            </div>

            <div className={cns(styles.component__item, "text text_h5")}>
                <div className={styles.component__key}>Итого:</div>
                <div className={styles.component__value}>
                    {formatCurrency(data?.totals.total || 0)}
                </div>
            </div>
        </div>
    );
};

export default Sum;
