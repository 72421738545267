import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "productPopupGallery",
    initialState: {
        isOpen: false,
        initialSlide: null,
    },
    reducers: {
        open(state, action) {
            state.isOpen = true;

            if (typeof action.payload !== "undefined") {
                state.initialSlide = action.payload;
            }
        },
        close(state) {
            state.isOpen = false;
            state.initialSlide = null;
        },
    },
});

export const getIsOpen = (state: any) => state.productPopupGallery.isOpen;
export const getInitialSlide = (state: any) =>
    state.productPopupGallery.initialSlide;

const { actions, reducer } = slice;

export const { open, close } = actions;

export default reducer;
