import type {
    AddReviewData,
    ApiFiltersResponse,
    ApiFullCategory,
    ApiProductFull,
    ApiProductRecommendationsResponse,
    ApiProductReviewsResponse,
    ApiProductsResponse,
    ApiSearchResponse,
    ApiStore,
    CategoriesList,
    CategoriesQueryParams,
    FiltersQueryParams,
    PreorderData,
    ProductFullQueryParams,
    ProductRecommendationsQueryParams,
    ProductReviewsParams,
    ProductsQueryParams,
    Result,
    SearchParams,
} from "@Types/api";

import * as mappers from "@Api/mappers";
import { Repository } from "@Utils/api/repository";

class CatalogApi extends Repository {
    getCategories = async (params?: CategoriesQueryParams) => {
        const result: Result<CategoriesList> = await this.client.request.get(
            "/catalog/categories",
            {
                recursion: "Y",
                ...params,
            }
        );

        return result?.data || [];
    };

    getCategory = async (slug: string) => {
        const result: Result<ApiFullCategory> = await this.client.request.get(
            `/catalog/categories/${slug}`
        );

        return result?.data ? mappers.mapFullCategory(result.data) : null;
    };

    getProducts = async (params?: ProductsQueryParams) => {
        const result: Result<ApiProductsResponse> =
            await this.client.request.get("/catalog/products", params);

        return mappers.mapProductsResponse(result?.data ?? { items: [] });
    };

    getProduct = async (slug: string, params?: ProductFullQueryParams) => {
        const result: Result<ApiProductFull> = await this.client.request.get(
            `/catalog/products/${slug}`,
            params
        );

        return mappers.mapProductFullResponse(result?.data ?? null);
    };

    getProductRecommendations = async (
        slug: string,
        params?: ProductRecommendationsQueryParams
    ) => {
        const result: Result<ApiProductRecommendationsResponse> =
            await this.client.request.get(
                `/catalog/products/${slug}/recommendations`,
                params
            );

        return mappers.mapProductRecommendationsResponse(
            result?.data ?? { recommendations: [] }
        );
    };

    getProductReviews = async (slug: string, params?: ProductReviewsParams) => {
        const result: Result<ApiProductReviewsResponse> =
            await this.client.request.get(
                `/catalog/products/${slug}/reviews`,
                params
            );

        return mappers.mapProductReviewsResponse(
            result?.data || this.getEmptyNavResponse()
        );
    };

    getFilters = async (params?: FiltersQueryParams) => {
        const result: Result<ApiFiltersResponse> =
            await this.client.request.get("/catalog/filters", params);

        return Array.isArray(result?.data) ? result?.data : [];
    };

    getStores = async () => {
        const result: Result<ApiStore[]> =
            await this.client.request.get("/catalog/stores");

        return result?.data?.map(mappers.mapStore) || [];
    };

    addReview = async (data: AddReviewData) => {
        await this.client.request.post("/catalog/products/reviews", data);
    };

    preorder = async ({ offerId, ...data }: PreorderData) => {
        const phoneOnlyNumbers = data.phone.replace(/\D/g, "");
        await this.client.request.post(
            `/catalog/offers/${offerId}/subscription`,
            { ...data, phone: phoneOnlyNumbers }
        );
    };

    search = async (params?: SearchParams) => {
        const result: Result<ApiSearchResponse> = await this.client.request.get(
            "/catalog/search",
            params
        );

        return mappers.mapSearchResponse(
            result?.data || { result: [], count: 0 }
        );
    };
}

export default CatalogApi;
