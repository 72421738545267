import cns from "classnames";
import { useEffect, useRef } from "react";

import SvgIcon from "@Components/ui/SvgIcon";

import styles from "./index.module.scss";

export type FieldProps = {
    className?: string;
    value: string;
    setValue: (value: string) => void;
};

const Field: React.FC<FieldProps> = ({ className, value, setValue }) => {
    const refInput = useRef<HTMLInputElement>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const handleButtonCloseClick = () => {
        setValue("");

        refInput.current?.focus();
    };

    useEffect(() => {
        refInput.current?.focus();
    }, []);

    return (
        <div className={cns(className, styles.component)}>
            <input
                ref={refInput}
                className={styles.component__input}
                type="search"
                placeholder="Поиск по товарам"
                value={value}
                onChange={handleInputChange}
            />

            {!!value.length && (
                <button
                    className={styles.component__buttonClose}
                    type="button"
                    aria-label="Очистить поиск"
                    onClick={handleButtonCloseClick}
                >
                    <SvgIcon icon="close-thin" />
                </button>
            )}

            <SvgIcon className={styles.component__iconSearch} icon="search" />
        </div>
    );
};

export default Field;
