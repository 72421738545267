import cns from "classnames";
import { useSelector } from "react-redux";

import Image from "@Components/ui/Image";
import { getExtra } from "@Redux/popup";

import { popupId } from "..";
import styles from "./index.module.scss";

export type InfoProps = {
    className?: string;
};

const Info: React.FC<InfoProps> = ({ className }) => {
    const sizesTable = useSelector(getExtra(popupId))?.sizesTable;

    if (!sizesTable) {
        return null;
    }

    return (
        <div className={cns(className, styles.component)}>
            <div
                className={cns(styles.component__text, "text text_p5")}
                dangerouslySetInnerHTML={{ __html: sizesTable.description }}
            />

            <Image
                className={styles.component__image}
                src={sizesTable.image}
                alt=""
                width={161}
                height={0}
                style={{ height: "auto" }}
            />
        </div>
    );
};

export default Info;
