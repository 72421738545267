import { useDataContext } from "./client";

export const useGetStore = () => {
    const value = useDataContext();

    return value.store;
};

export const useGetStoreCategories = () => {
    const value = useDataContext();

    return value.store.categories;
};
