import cns from "classnames";
import React from "react";

import styles from "./index.module.scss";

type ContainerProps = React.PropsWithChildren & {
    className?: string;
    grid?: boolean;
};

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
    ({ className, grid, children }, ref) => {
        return (
            <div
                ref={ref}
                className={cns(className, styles.component, {
                    [styles.component_grid]: grid,
                })}
            >
                {children}
            </div>
        );
    }
);

export default Container;
