import { dehydrate, QueryClient } from "@tanstack/react-query";
import { GetServerSideProps } from "next";

interface PageProps {
    dehydratedState: any;
}

export const getDefaultServerSideProps: GetServerSideProps<
    PageProps
> = async ctx => {
    const queryClient = new QueryClient();

    // await prefetchCategories(queryClient, ctx.req);

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
        },
    };
};
