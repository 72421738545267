export enum Locale {
    RU = "ru",
    EN = "en",
    ALL = "all",
}

export type Result<T> = {
    status: number;
    data: T;
};

export type ApiPagination = {
    total_count: number;
    page_count: number;
    current_page: number;
    page_size: number;
};

export type Pagination = {
    countCurrent: number;
    countTotal: number;
    pageCurrent: number;
    pagesCount: number;
};

export type BooleanSymbol = "Y" | "N";

export type BooleanNumber = "1" | "0";

export type BitrixProperty = {
    CODE: string;
    VALUE: string;
};

export type BitrixBooleanProperty = {
    CODE: string;

    VALUE_XML_ID: null | BooleanSymbol;
};

export type BitrixEnumProperty = {
    CODE: string;
    VALUE: string;
    VALUE_ENUM: string;
};

export type BitrixArrayProperty = {
    CODE: string;
    VALUES: string[];
    DESCRIPTION: string[];
};

export type ApiFileProperty = {
    SRC: string;
    CONTENT_TYPE: string;
};

export type ApiImageProperty = ApiFileProperty & {
    DESCRIPTION: string;
    WIDTH: number;
    HEIGHT: number;
};

export type ApiSeo = {
    META_TITLE: string;
    PAGE_TITLE: string;
    META_KEYWORDS?: string;
    META_DESCRIPTION?: string;
};

export type Seo = {
    title: string;
    description: string;
    keywords: string;
    // metaSocial?: MetaSocial;
    // metaRobots?: string;
    // metaViewport?: string;
    canonicalURL?: string;
};

export type ApiSettings = {
    HEADER_TEXT: string;
};
